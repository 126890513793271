import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import Timelines from "./Timelines";
import { GetDashboard } from "../../../helper/dashboard/dashboard";
import { TextShimmer } from "../../../components/shimmer/shimmer";
import { DatePicker } from "antd";
import moment from "moment";
import useWindowDimensions from "../../../utils/routes/useWindowDimensions";

const Dashboard = () => {
  const { width } = useWindowDimensions();
  const initialvalues = {
    total_worksites: "00",
    total_companies: "00",
    isShimmer: true,
  };

  const [dashboard, setDashboard] = useState(initialvalues);
  const [date, setDate] = useState("");
  const [worksite, setWorkSite] = useState([]);

  const companyData = [
    {
      name: "Worksites",
      value: dashboard?.total_worksites,
      details: "Active worksites",
      linkto: "/admin/worksite",
    },
    {
      name: "Companies ",
      value: dashboard?.total_companies,
      details: "Total companies",
      linkto: "/admin/settings/induction/companies",
    },
  ];

  const getChartValue = (num) => parseFloat(num).toFixed(1) || 0;

  useEffect(() => {
    (async () => {
      let data = await GetDashboard(date);
      if (data?.status) {
        setDashboard({ ...data?.data, isShimmer: false });
        const worksite = data.data?.worksites || [];

        const restructured =
          worksite?.map((item) => ({
            id: item?._id,
            chart: [
              {
                remaining: getChartValue(item?.remaining_reserve),
                worked: getChartValue(item?.worked),
                reserved: getChartValue(item?.remaining_estimated),
                deficiet: getChartValue(item?.deficit),
                lost: getChartValue(item?.lost),
              },
            ],
            jobDetails: [
              {
                img: item?.picture,
                jobNumber: item?.job_no,
                jobName: item.name,
                siteManager:
                  item.site_managers?.reduce(
                    (acc, next) => `${acc} ${next.name}`,
                    ""
                  ) || "",
                areas: item.areaCount,
                workers: item.workerCount,
                projectCost: item?.project_cost || 0,
                totalSpent: parseFloat(item?.amount).toFixed(2) || 0,
              },
            ],
            productivity: [
              {
                active: item?.active,
                approved: item?.approved,
                total: item?.total,
                remaining: item?.remaining,
              },
            ],
          })) || [];

        setWorkSite(restructured);
      }
    })();
  }, [date]);

  return (
    <div className="py-14 px-20">
      <div className="top-row mb-6">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
          {companyData?.map((item, index) => (
            <div
              className="bg-[#E5E5E5] px-8 py-12 rounded-lg hover:bg-[#D5E9FE]"
              key={index}
            >
              {dashboard?.isShimmer ? (
                <TextShimmer line={10} />
              ) : (
                <div className="flex justify-between items-center">
                  <div className="flex flex-col w-full">
                    <h1 className="text-xl font-semibold">{item.name}</h1>
                    <h2 className="text-6xl text-blue my-4 font-bold">
                      {item.value}
                    </h2>
                    <div className="flex w-full justify-between items-center">
                      <p className="text-gray-500">{item.details}</p>
                      <Link
                        to={item.linkto}
                        className="text-blue rounded-md btn bg-white px-4 py-2"
                      >
                        <FaAngleRight />
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <div
          className="flex justify-between items-center my-5"
          style={{
            overflowY: width <= 767 ? "scroll" : "visible",
            maxHeight: width <= 767 ? "65vh" : "auto",
          }}
        >
          <p>Active Worksite Timelines</p>
          <DatePicker
            format={"YYYY-MM-DD"}
            onChange={(e) => {
              setDate(e ? moment(e.$d).format("YYYY-MM-DD") : "");
            }}
          />
        </div>

        <Timelines data={worksite || []} />
      </div>
    </div>
  );
};

export default Dashboard;
