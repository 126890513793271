import React, { useState } from "react";
import { TableDefaultSvg } from "../../assets/svg/tableDefaul";

const NoTableOption = ({ data }) => {
  const initialvalues = {
    svg: data?.svg,
    disclaimer: data?.disclaimer,
    description: data?.description,
  };

  const [tableOption, setTableOption] = useState(initialvalues);

  return (
    <>
      <div className="h-full-60 flex justify-center items-center flex-col bg-white">
        <div className="py-2">{tableOption?.svg}</div>
        <div className="">{tableOption?.disclaimer}</div>
        <div className="text-gray-300 text-base py-2">
          {tableOption?.description}
        </div>
      </div>
    </>
  );
};

export { NoTableOption };
