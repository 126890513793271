class PaginationHelper {
  defaultState = {
    perPage: 10,
    page: 1,
    cursor: "",
  };

  lastPage = {
    route: "",
    data: {
      ...this.defaultState,
    },
  };

  rememberPageState(data, route) {
    if (data?.page > 1) {
      this.lastPage = { ...this.lastPage, route, data };
    }
  }

  getPaginationMemory(route) {
    if (this.lastPage.route === route) {
      return this.lastPage.data;
    } else {
      return this.defaultState;
    }
  }
}

export default PaginationHelper;
