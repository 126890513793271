import React, { useContext, useEffect, useState } from "react";
import { FaArrowLeft, FaCaretDown } from "react-icons/fa";
import LogOutDetails from "../../../../../layouts/LogOutDetails";
import { Authuser } from "../../../../../auth/appAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../../../../layouts/Main";
import HeaderSearchWorksite from "../../../../../layouts/HeaderSearchWorksite";

const TabHeader = ({
  worksite,
  setWorksite,
  setWorkerType,
  workerType,
  selectedKey,
  setSelectedKey,
  areas,
  setAreas,
  getInspection,
  setGetInspection,
  inspection,
  setInspection,
}) => {
  const initialvalue = {
    id: "",
    name: "",
    email: "",
    employee_id: "",
    mobile: "",
    role: "",
    verified: "",
    last_login: "",
    status: "",
  };
  const [user, setUser] = useState(initialvalue);
  const userData = localStorage.getItem("dataStore");
  const [dataStore, setDataStore] = useContext(AppContext);
  const navigate = useNavigate();
  let path = useLocation();
  useEffect(() => {
    let data = Authuser();
    setDataStore(JSON.parse(userData));
    setUser({
      ...data,
    });
  }, []);
  useEffect(() => {
    if (path?.pathname?.includes("/site-manager/worksite/details/timesheet")) {
      setSelectedKey("1");
    } else if (
      path?.pathname?.includes("/site-manager/worksite/details/areas")
    ) {
      setSelectedKey("2");
    } else if (
      path?.pathname?.includes(
        "/site-manager/worksite/details/productivity-orders"
      )
    ) {
      setSelectedKey("3");
    } else if (
      path?.pathname?.includes("/site-manager/worksite/details/shift-details")
    ) {
      setSelectedKey("4");
    }
  }, [window.location.pathname]);
  return (
    <div className="flex justify-between items-center mb-[20px] header-flex">
      <div className="flex ">
        <button onClick={() => navigate(-1)}>
          <FaArrowLeft className="text-[#000] mr-3" />
        </button>
        <div className="flex justify-start items-center gap-3">
          <p className="text-[24px] w-full flex justify-start items-center">
            Worksite <span className="ml-2">&gt;</span>
          </p>
          <div className="w-full">
            <p className="flex w-full justify-start items-center">
              {dataStore?.name ? dataStore?.name : dataStore?.jobName}{" "}
              <FaCaretDown className="inline-block ml-2" />
            </p>

            <p className="text-xs">
              <span className="text-gray-400">Job NUmber:</span>{" "}
              {dataStore?.job_no ? dataStore?.job_no : dataStore?.jobNumber}
            </p>
          </div>
        </div>
      </div>
      <div className="flex  mt-2">
        {selectedKey == "1" && (
          <HeaderSearchWorksite
            placeHolder={"Search by Workers Name"}
            worksite={worksite}
            setWorksite={setWorksite}
            setWorkerType={setWorkerType}
            workerType={workerType}
          />
        )}
        {selectedKey == "1" &&
          path?.pathname?.includes(
            "site-manager/worksite/details/productivity-orders"
          ) && (
            <HeaderSearchWorksite
              placeHolder={"Search by Workers Name"}
              worksite={worksite}
              setWorksite={setWorksite}
              setWorkerType={setWorkerType}
              workerType={workerType}
            />
          )}
        {path?.pathname?.includes(
          "site-manager/worksite/details/overviews"
        ) && (
          <HeaderSearchWorksite
            placeHolder={"Search by Workers Name"}
            worksite={worksite}
            setWorksite={setWorksite}
            setWorkerType={setWorkerType}
            workerType={workerType}
          />
        )}
        {selectedKey == "2" &&
          path?.pathname?.includes("site-manager/worksite/details/areas") && (
            <HeaderSearchWorksite
              placeHolder={"Search by Area Name"}
              worksite={areas}
              setWorksite={setAreas}
              setWorkerType={setWorkerType}
              workerType={workerType}
              areas={areas}
              setAreas={setAreas}
            />
          )}
        {(path?.pathname?.includes(
          "site-manager/worksite/details/productivitys"
        ) ||
          path?.pathname?.includes(
            "site-manager/worksite/details/shift-details"
          )) && (
          <HeaderSearchWorksite
            placeHolder={"Search by Area Name"}
            worksite={areas}
            setWorksite={setAreas}
            setWorkerType={setWorkerType}
            workerType={workerType}
            areas={areas}
            setAreas={setAreas}
          />
        )}
        {path?.pathname?.includes("site-manager/worksite/details/task") && (
          <HeaderSearchWorksite
            placeHolder={"Search by Tasks Name"}
            worksite={getInspection}
            setWorksite={setGetInspection}
            setWorkerType={setWorkerType}
            workerType={workerType}
            getInspection={getInspection}
            setGetInspection={setGetInspection}
          />
        )}
        {path?.pathname?.includes("site-manager/worksite/details/issues") && (
          <HeaderSearchWorksite
            placeHolder={"Search by Tasks Name"}
            worksite={getInspection}
            setWorksite={setGetInspection}
            setWorkerType={setWorkerType}
            workerType={workerType}
            getInspection={getInspection}
            setGetInspection={setGetInspection}
          />
        )}
        {path?.pathname?.includes(
          "site-manager/worksite/details/inspection"
        ) && (
          <HeaderSearchWorksite
            placeHolder={"Search by Inspection Name"}
            worksite={inspection}
            setWorksite={setInspection}
            setWorkerType={setWorkerType}
            workerType={workerType}
            inspection={inspection}
            setInspection={setInspection}
          />
        )}
        <LogOutDetails user={user} />
      </div>
    </div>
  );
};
export default TabHeader;
