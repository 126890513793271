import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
export default useWindowDimensions;

export const isValidWeekRange = (fromDate, toDate) => {
  // Parse the from and to dates
  const from = new Date(fromDate);
  const to = new Date(toDate);

  // Calculate the difference in days
  const timeDiff = to.getTime() - from.getTime();
  const dayDiff = timeDiff / (1000 * 3600 * 24);

  // Return true if the difference is exactly 7 days, otherwise false
  return dayDiff === 6;
};
