import React, { Fragment } from "react";
import { FaArrowLeft, FaEdit, FaPlus } from "react-icons/fa";
import { Button } from "antd";
import EditProfileModal from "../../../../shared/Modal/EditProfileModal";
import moment from "moment";
import { ButtonSpinner } from "../../../../components/spinners";
import { Fileurl } from "../../../../config/url";
import { useNavigate, useParams } from "react-router-dom";
import DefaultCree8Img from "../../../../assets/images/Default/defaulCree8.png";
import EditWorkersDetails from "./EditWorkerDetails";
import useWindowDimensions from "../../../../utils/routes/useWindowDimensions";

const SingleWorkersProfile = ({ worker, setWorker, handle }) => {
  const { width } = useWindowDimensions();
  console.log("the worker", worker);

  const { logID } = useParams();
  let navigate = useNavigate();
  const [modalVisible, setModalVisible] = React.useState(false);
  const EditModalVisible = () => {
    setModalVisible(true);
  };

  const img = {
    display: "block",
    width: 120,
    height: 120,
    borderRadius: "10px",
  };
  const img2 = {
    display: "block",
    width: 50,
    height: 50,
    borderRadius: "10px",
  };

  return (
    <div>
      {modalVisible && (
        <EditProfileModal
          worker={worker}
          setWorker={setWorker}
          setModalVisible={setModalVisible}
        />
      )}
      <div className="profile pb-4 border-b-2 border-[#E9E9E9]">
        <div>
          <div
            className="cursor-pointer  pb-4"
            onClick={() =>
              window.location.toString().includes("site-manager")
                ? navigate(`/site-manager/workers/1`)
                : navigate(-1, {
                    replace: true,
                  })
            }
          >
            <div className="flex">
              <div>
                <div>
                  <div className="pt-1">
                    <FaArrowLeft />
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className="px-2">Return to Workers </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            width > 767
              ? "flex justify-start items-center"
              : "flex overflow-x-scroll  w-100  "
          }
        >
          <div className="dp">
            <img
              src={
                worker?.users_profile_details?.data
                  ? Fileurl + worker?.users_profile_details?.data
                  : DefaultCree8Img
              }
              className={"mr-14 mt-10 "}
              style={width > 767 ? img : img2}
              alt=""
            />
          </div>
          <div className="contents p-5 pl-10">
            <div className="flex w-full justify-between items-center">
              <div className="profile-details w-full">
                <div className="flex justify-start items-center">
                  <p className={width > 767 ? "fs-22 mr-5" : "fs-15 mr-5"}>
                    {worker?.name}
                  </p>
                  {worker?.status == 1 ? (
                    <span className="fs-10 ml-3 text-[#41C700] border rounded-md px-2 py-1 border-[#41C700] bg-[#42c70033]">
                      Active
                    </span>
                  ) : worker?.status == 3 ? (
                    <span className="fs-10 ml-3 text-[#FF0000] border rounded-md px-2 py-1 border-[#FF0000] bg-[#FF000033]">
                      Terminated
                    </span>
                  ) : (
                    ""
                  )}
                  <button className="px-3" onClick={EditModalVisible}>
                    <FaEdit />
                  </button>
                </div>

                <div className="flex justify-between w-full items-center mt-3">
                  <div className="lg:w-1/3 md:w-1/2 w-full text-left">
                    <p className="fs-14 text-[#A1A1A1]">Email</p>
                    <p className={width > 767 ? "fs-14" : "fs-12 mr-3"}>
                      {worker?.email ? worker?.email : "N/A"}
                    </p>
                  </div>
                  {worker?.status == 3 ? (
                    <div className="lg:w-1/4 md:w-1/2 w-full text-left">
                      <p
                        className={
                          width > 767
                            ? "fs-14 text-[#A1A1A1]"
                            : "fs-14 text-[#A1A1A1] mr-3"
                        }
                      >
                        Card ID
                      </p>
                      <p className={width > 767 ? "fs-14" : "fs-12 mr-3"}>
                        {worker?.employee_id ? worker?.employee_id : "N/A"}
                      </p>
                    </div>
                  ) : (
                    <div className="lg:w-1/4 md:w-1/2 w-full text-left">
                      <p
                        className={
                          width > 767
                            ? "fs-14 text-[#A1A1A1]"
                            : "fs-14 text-[#A1A1A1] mr-3"
                        }
                      >
                        Employee Number
                      </p>
                      <p className={width > 767 ? "fs-14" : "fs-12 mr-10"}>
                        {worker?.employee_id ? worker?.employee_id : "N/A"}
                      </p>
                    </div>
                  )}
                  {worker?.status == 1 || logID ? (
                    <div className="lg:w-1/3 md:w-1/2 w-full text-left">
                      <p
                        className={
                          width > 767
                            ? "fs-14 text-[#A1A1A1]"
                            : "fs-14 text-[#A1A1A1] mr-20"
                        }
                      >
                        Joined Date
                      </p>
                      {logID ? (
                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.dob}
                          fieldName={"created_at"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={(date) => console.log(date)}
                          inputField={"dob"}
                        />
                      ) : (
                        <p className={width > 767 ? "fs-14" : "fs-12 mr-3 "}>
                          {worker?.created_at
                            ? moment(worker?.created_at)?.format("MMMM D, YYYY")
                            : "N/A"}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="lg:w-1/3 md:w-1/2 w-full text-left"></div>
                  )}
                  {logID && (
                    <div className="lg:w-1/3 md:w-1/2 w-full text-left">
                      <p
                        className={
                          width > 767
                            ? "fs-14 text-[#A1A1A1]"
                            : "fs-14 text-[#A1A1A1] mr-3"
                        }
                      >
                        Termination date
                      </p>

                      <EditWorkersDetails
                        value={worker?.worker_details[0]?.dob}
                        fieldName={"terminated_date"}
                        workerId={worker?.worker_details[0]?.id}
                        collectionKey={"worker_details"}
                        handleWorker={(date) => console.log(date)}
                        inputField={"dob"}
                      />
                    </div>
                  )}
                  {worker?.status == 1 || logID ? (
                    <div className="lg:w-1/4 md:w-1/2 w-full text-left">
                      <p
                        className={
                          width > 767
                            ? "fs-14 text-[#A1A1A1]"
                            : "fs-14 text-[#A1A1A1] mr-3"
                        }
                      >
                        Employed{" "}
                      </p>
                      <p className="fs-14">
                        {worker?.worker_details[0]?.employment_status?.name
                          ? worker?.worker_details[0]?.employment_status?.name
                          : "N/A"}
                      </p>
                    </div>
                  ) : (
                    <div className="lg:w-1/4 md:w-1/2 w-full text-left"></div>
                  )}
                </div>
              </div>

              {!logID && (
                <Button
                  onClick={() =>
                    handle?.terminateRevoke(
                      worker?.status == 1 ? 3 : worker?.status == 3 ? 1 : ""
                    )
                  }
                  disabled={worker?.isLoading}
                  className={
                    width > 767
                      ? "flex justify-center items-center py-3 border border-[#111111] h-[42px] w-1/5"
                      : "flex justify-center items-center py-3  h-[32px]  ml-[135px]"
                  }
                >
                  {worker?.isLoading ? (
                    <ButtonSpinner />
                  ) : !worker?.isLoading && worker?.status == 1 ? (
                    <>Terminate</>
                  ) : !worker?.isLoading && worker?.status == 3 ? (
                    <>Revoke</>
                  ) : (
                    ""
                  )}
                  {!worker?.isLoading && (
                    <div
                      className={
                        width > 767
                          ? "bg-orange-500 p-2 rounded-md text-white ml-3"
                          : "bg-orange-500 p-1 rounded-md text-white ml-1"
                      }
                    >
                      <FaPlus />
                    </div>
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleWorkersProfile;
