import React, { useState, useEffect, useContext } from "react";
import Timelines from "./Timeline";
import HeaderSearch from "../../../layouts/HeaderSearch";
import LogOutDetails from "../../../layouts/LogOutDetails";
import { ApiAuthorizaion, Authuser } from "../../../auth/appAuth";
import { DatePicker } from "antd";
import { GetDashboard } from "../../../helper/dashboard/dashboard";
import { UserService } from "../../../config/axiosUrl";
import useWindowDimensions from "../../../utils/routes/useWindowDimensions";

const Timeline = () => {
  const { width } = useWindowDimensions();
  const initialvalue = {
    id: "",
    name: "",
    email: "",
    employee_id: "",
    mobile: "",
    role: "",
    verified: "",
    last_login: "",
    status: "",
  };
  const [selectedDate, setSelectedDate] = useState("");
  const [worksite, setWorkSite] = useState([]);
  const getChartValue = (num) => parseFloat(num).toFixed(1) || 0;
  const [user, setUser] = useState(initialvalue);
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    let data = Authuser();
    setUser({
      ...data,
    });
    UserService.get("/api/v1/auth/get-user-profile", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    }).then((res) => {
      setUser(res?.data[0]);
    });
  }, []);
  const onSelectDate = async (date, dateString) => {
    setSelectedDate(dateString);
  };
  const handleSearch = async (e) => {
    setSearchValue(e?.target?.value);
    const params = {
      search: e?.target?.value,
    };
  };
  useEffect(() => {
    localStorage.removeItem("dataStore");
    (async () => {
      let data = await GetDashboard(selectedDate, searchValue);
      if (data?.status) {
        const worksite = data.data?.worksites || [];

        const restructured =
          worksite?.map((item) => ({
            id: item?._id,
            chart: [
              {
                remaining: getChartValue(item?.remaining_reserve),
                worked: getChartValue(item?.worked),
                reserved: getChartValue(item?.remaining_estimated),
                deficiet: getChartValue(item?.deficit),
                lost: getChartValue(item?.lost),
              },
            ],
            jobDetails: [
              {
                id: item?._id,
                img: item?.picture,
                absentCount: item?.absentCount,
                startDate: item?.worksite_timelines[0]?.start_date,
                finishDate: item?.worksite_timelines[0]?.finish_date,
                jobNumber: item?.job_no,
                jobName: item.name,
                amount: item?.amount,
                siteManager:
                  item.site_managers?.reduce(
                    (acc, next) => `${acc} ${next.name}`,
                    ""
                  ) || "",
                areas: item.areaCount,
                workers: item.workerCount,
                projectCost: item?.project_cost || 0,
                totalSpent: 0,
              },
            ],
            productivity: [
              {
                active: item?.active,
                approved: item?.approved,
                total: item?.total,
                remaining: item?.remaining,
              },
            ],
          })) || [];

        setWorkSite(restructured);
      }
    })();
  }, [selectedDate, searchValue]);

  return (
    <div>
      <div
        className={
          width >> 767
            ? "flex justify-between items-center mb-[20px] header-flex"
            : "flex justify-between items-center mb-[10px] header-flex p-3"
        }
      >
        <p
          className={width > 767 ? "text-[24px] w-full" : "text-[18px] w-full"}
        >
          Active Worksite Timelines
        </p>
        <div className="flex justify-end items-center w-full">
          <HeaderSearch
            placeHolder={"Search by Worksite Name"}
            handle={handleSearch}
            value={searchValue}
          />
          <LogOutDetails user={user} />
        </div>
      </div>
      <div
        className={
          width > 767
            ? "flex justify-between items-center my-5"
            : "flex justify-between items-center my-3"
        }
      >
        <div className="flex justify-start items-center gap-2">
          {/* <div className="border bg-white py-2 px-3 gap-2 flex rounded justify-between items-center">
              <span className="text-xs text-[#A1A1A1]">Show: </span>
              <select className="border-none outline-none bg-transparent p-0 ml-3 font-medium">
                <option value="All worksites">All Worksites</option>
              </select>
            </div> */}
        </div>
        <div className="border bg-white h-[31px] gap-2 flex rounded justify-center items-center mr-10 ">
          <DatePicker
            type="date"
            onChange={onSelectDate}
            allowClear={true}
            format={"YYYY-MM-DD"}
            size="sm"
          />
        </div>
      </div>

      <Timelines data={worksite || []} />
    </div>
  );
};

export default Timeline;
