import { Button } from "antd";
import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { ButtonSpinner } from "../../components/spinners";
import { DeleteHourlyRate } from "../../helper/setup/task/hourlyrate";
import { GetHourlyRate } from "../../helper/setup/task/hourlyrate";

const DeleteModal = ({ setDelete, data, action, close }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="text-right">
          <button className="close-modal" onClick={close}>
            <FaTimes />
          </button>
        </div>

        <div className="modal-body mt-3">
          <h2 className="text-xl font-bold">{data?.modalTitle}</h2>

          <form className="mt-4">
            <div className="item mt-8">
              <table>
                <tbody>
                  {data?.modal_selected_data?.name ? (
                    <tr>
                      <td className="font-bold">Name</td>
                      <td className="pl-5">
                        {data?.modal_selected_data?.name}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
            <div className="item mt-8 text-center">
              <Button
                className="btn btn-blue bg-blue-disable w-1/5"
                disabled={data?.isModalLoading}
                onClick={action}
              >
                {data?.isModalLoading ? <ButtonSpinner /> : "Delete"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
