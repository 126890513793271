import moment from "moment";
import React, { Fragment, useMemo } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const GetWeekDayReport = ({ date, parent }) => {
  const currWeek = days[moment(date).weekday()];
  const data = parent.timesheetData[currWeek];
  const navigate = useNavigate();

  const timeSheetId = useMemo(() => {
    const timesheet = parent?.worksite_timesheet_data.find((item) => item?._id);
    return timesheet?.worksite_timesheet?._id || "";
  }, []);

  return !data ? (
    <td colSpan={3} key={parent?._id + currWeek}>
      <div
        className="flex justify-center items-center cursor-pointer"
        onClick={() =>
          navigate(
            `/admin/worksite/add-timesheet-detailss/${parent?.work_site_id}/${date}`
          )
        }
      >
        <AiOutlinePlusCircle />
      </div>
    </td>
  ) : (
    <Fragment>
      <td>
        {/* N */}
        <p className="pl-2">{data?.normal_time || "-"}</p>
      </td>
      {/* OT */}
      <td>{data?.over_time || "-"}</td>
      <td>
        {/* DT */}

        <p className="pr-2">{data?.double_time || "-"}</p>
      </td>
    </Fragment>
  );
};

export default GetWeekDayReport;
