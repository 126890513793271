import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const StackedWorksite = ({ data }) => {
  return (
    <ResponsiveContainer className={"barC "} width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data?.data?.graph}
        margin={{
          top: 30,
        }}
      >
        <XAxis
          height={160}
          dataKey="name"
          textAnchor="end"
          claeToFit="true"
          verticalAnchor="start"
          interval={0}
          angle="-90"
          stroke="#A1A1A1"
          label={{ value: "Tasks", offset: 130, position: "insideBottom" }}
        />
        <YAxis label={{ value: "Hours", angle: -90, position: "insideLeft" }} />
        {data &&
          data?.data &&
          data?.data?.graph &&
          data?.data?.graph?.length > 0 && <Tooltip />}
        <Legend verticalAlign="top" />
        <Bar dataKey="worked" name="Worked" stackId="name" fill="#FF9900" />
        <Bar
          dataKey="allocated"
          name="Allocated"
          stackId="name"
          fill="#109618"
        />
        <Bar dataKey="exceeded" name="Exceeded" stackId="name" fill="#DC3912" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackedWorksite;
