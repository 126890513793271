import React, { useEffect } from "react";
import TickSvg from "../../../assets/svg/ticksvg";

const TabHeader = ({ handleTabChange, data, setData, handle }) => {
  useEffect(() => {
    const tabBtns = document.querySelectorAll(".tab-header-btn");
    tabBtns.forEach((btn) => {
      btn.addEventListener("click", () => {
        tabBtns.forEach((btn) => btn.classList.remove("active"));
        btn.classList.add("active");
      });
    });
  }, []);

  return (
    <div className="bg-white p-5">
      <div className="tab-header lg:w-[85%] mx-auto flex items-center gap-10">
        <button
          className={
            data?.agree_and_continue
              ? "tab-header-btn"
              : "tab-header-btn active"
          }
          onClick={() => handleTabChange(1)}
          disabled={data?.agree_and_continue}
        >
          <div className="flex">
            <div>
              <span className="tab-header-btn-text text-lg">
                1 Read Disclaimer
              </span>
            </div>
            <div>
              <div>
                {data?.agree_and_continue && (
                  <div className="pl-2">
                    <TickSvg />
                  </div>
                )}
              </div>
            </div>
          </div>
        </button>
        <button
          className={
            data?.agree_and_continue
              ? "tab-header-btn active"
              : "tab-header-btn"
          }
          onClick={() => handleTabChange(2)}
          disabled={true}
        >
          <span className="tab-header-btn-text text-lg">
            2 Submit Workers Details
          </span>
        </button>
      </div>
    </div>
  );
};

export default TabHeader;
