import { Tabs } from "antd";
// import Overviews from "./Tabpans/Overviews";
// import Areas from "./Tabpans/Areas";
// import Tasks from "./Tabpans/Tasks";
// import Timesheet from "./Tabpans/Timesheet";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const WorksiteTabs = () => {
  let navigate = useNavigate();
  let path = useLocation();
  let { id = "" } = useParams();

  const initialvalues = {
    defaultKey: "1",
  };

  const [tabs, setTabs] = useState(initialvalues);

  const onChange = (key) => {
    if (key === "1") {
      navigate(`/admin/worksite/details/overviews/${id}`);
    } else if (key === "2") {
      navigate(`/admin/worksite/details/areas/${id}`);
    } else if (key === "3") {
      navigate(`/admin/worksite/details/tasks/${id}`);
    } else if (key === "4") {
      navigate(`/admin/worksite/details/timesheet/${id}`);
    } else if (key === "5") {
      navigate(`/admin/worksite/details/productivity-orders/${id}`);
    }
  };

  const items = [
    {
      key: "1",
      label: `Overviews`,
      children: "",
    },
    {
      key: "2",
      label: `Areas`,
      children: "",
    },
    {
      key: "3",
      label: `Tasks`,
      children: "",
    },
    {
      key: "4",
      label: `Timesheets`,
      children: "",
    },
    {
      key: "5",
      label: `Productivity Orders`,
      children: "",
    },
  ];

  useEffect(() => {
    setTabs({
      ...tabs,
      defaultKey: path?.pathname?.includes("/admin/worksite/details/overviews")
        ? "1"
        : path?.pathname?.includes("/admin/worksite/details/areas")
        ? "2"
        : path?.pathname?.includes("/admin/worksite/details/tasks")
        ? "3"
        : path?.pathname?.includes("/admin/worksite/details/timesheet")
        ? "4"
        : path?.pathname?.includes(
            "/admin/worksite/details/productivity-orders"
          )
        ? "5"
        : "1",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path?.pathname]);

  return (
    <Tabs activeKey={tabs?.defaultKey} items={items} onChange={onChange} />
  );
};

export default WorksiteTabs;
