import React, { Fragment, useEffect, useState, useCallback } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "antd";
import { UserService } from "../../config/axiosUrl";
import Link from "antd/es/typography/Link";

const SiteManagerHistoryTable = ({ selectedDate, searchInput }) => {
  const { site_manager_id } = useParams();
  console.log("the selected date isisis", selectedDate);
  const paths = {
    1: "active",
    3: "terminated",
  };

  const [expanded, setExpanded] = useState(-1);
  const [sitemanager, setSiteManager] = useState([]);

  const getSiteManagerHistory = useCallback(() => {
    UserService.get(
      `/api/v1/personal/site-manager/history?site_manager_id=${site_manager_id}&date=${selectedDate}&search=${searchInput}`
    )
      .then((res) => {
        setSiteManager(res?.data?.data?.data || []);
      })
      .catch((error) => {
        console.error("Error fetching site manager history:", error);
      });
  }, [site_manager_id, selectedDate, searchInput]);

  useEffect(() => {
    getSiteManagerHistory();
  }, [selectedDate, searchInput, site_manager_id]);

  const toggleExpand = (index) => {
    setExpanded((prev) => (prev === index ? -1 : index));
  };

  return (
    <>
      <table className="w-full  ">
        <thead className="bg-[#E8F3FF] text-center">
          <tr className="text-left">
            <th className="px-4 py-3">
              <div className="flex items-center justify-center">Date</div>
            </th>
            <th className="px-4 py-3">
              <div className="flex items-center justify-center">Worksite</div>
            </th>
            <th className="px-4 py-3">
              <div className="flex items-center justify-center">Worker</div>
            </th>
            <th className="px-4 py-3">
              <div className="flex items-center justify-center"></div>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {Array.isArray(sitemanager) && sitemanager.length > 0 ? (
            sitemanager.map((item, index) => (
              <Fragment key={index}>
                <tr className="border-b border-[#F0F0F0]">
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center">
                      {new Date(
                        item.worksite_timesheet?.created_at
                      ).toLocaleDateString()}
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center">
                      {item.worksites?.map((worksite, worksiteIndex) => (
                        <div key={worksiteIndex}>{worksite.name}</div>
                      ))}
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center">
                      {
                        item.worksite_timesheet.worksite_timesheet_data.users
                          .length
                      }
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center">
                      <Button
                        className="px-2 bg-[#FE650C]"
                        onClick={() => toggleExpand(index)}
                      >
                        {expanded === index ? (
                          <FaAngleUp className="scale-125 fill-white" />
                        ) : (
                          <FaAngleDown className="scale-125 fill-white" />
                        )}
                      </Button>
                    </div>
                  </td>
                </tr>
                {expanded === index && (
                  <>
                    {item.worksite_timesheet.worksite_timesheet_data.users.map(
                      (user, userIndex) => (
                        <tr key={userIndex}>
                          <td colSpan={3}>
                            <div className="flex justify-center py-4 px-2 ">
                              <div className="flex justify-between w-[80%] px-2  border-b-2 pb-2 border-b-[#F0F0F0] ">
                                <Link
                                  to={`/admin/personnel/workers/details/${
                                    paths[user.name]
                                  }`}
                                  style={{
                                    marginRight: "10px",
                                    color: "black",
                                    fontSize: "15px",
                                    fontFamily: "lexend",
                                  }}
                                >
                                  {user.name}
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </>
                )}
              </Fragment>
            ))
          ) : (
            <tr>
              <td colSpan={4}>
                <div className="flex justify-center py-4 px-2">
                  No data available.
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default SiteManagerHistoryTable;
