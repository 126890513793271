import { Button, Checkbox, Input, Modal, Select, Space } from "antd";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { BiSearch } from "react-icons/bi";
import { useParams } from "react-router-dom";
import QualityAssuranceSvg from "../../../assets/svg/qualityAssurance";
import { WorkService } from "../../../config/axiosUrl";
import { throttler } from "../../../components/PaginationAPI/Pagination";
import { ButtonSpinner } from "../../../components/spinners";
import { TextShimmer } from "../../../components/shimmer/shimmer";

const AssignQA = () => {
  const { area = "", id = "" } = useParams();

  // deletedQAD_ids;

  const formInit = useMemo(
    () => ({
      task_id: "",
      quality_assurance_id: "",
      quality_assurance_details: [],
    }),
    []
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    templates: [],
    area: [],
    currentSelect: [],
    backupSelect: [],
    form: { ...formInit },
    loading: true,
    quality_assurance_details: [],
  });
  const [isSubmitting, setSubmitting] = useState(false);

  const { handleSubmit, control, watch, setValue, reset } = useForm({
    defaultValues: {
      ...formInit,
    },
    values: formData.form,
  });

  const toggleModal = useCallback(function () {
    setModalOpen((prev) => !prev);
  }, []);
  const onCheck = useCallback(
    (id) => {
      const selected = watch("quality_assurance_details");

      if (selected.includes(id)) {
        selected.splice(selected.indexOf(id), 1);
      } else {
        selected.push(id);
      }

      setValue("quality_assurance_details", selected);
    },
    [watch, setValue]
  );
  const findTemplateChild = useCallback(
    (id) => {
      let data =
        formData.templates.find((item) => item._id === id)
          ?.quality_assurance_details || [];

      setFormData((prev) => ({
        ...prev,
        backupSelect: data,
        currentSelect: data,
      }));
    },
    [formData.templates]
  );
  const onSearch = useCallback((search) => {
    setFormData((prev) => ({
      ...prev,
      currentSelect: prev.backupSelect.filter((item) => {
        return item.name.includes(search);
      }),
    }));
  }, []);

  const getQATemplates = useCallback((search = "") => {
    WorkService.get(
      `/api/v1/worksite/quality-assurance/get-quality-assurance?search=${search}`
    ).then((res) => {
      setFormData((prev) => ({
        ...prev,
        templates: res?.data?.data?.data || [],
      }));
    });
  }, []);
  const getAreaData = useCallback(() => {
    WorkService.post(`/api/v1/worksite/get-area-data-by-id`, { id: area }).then(
      (res) => {
        const data = res?.data?.data?.data;
        let makeOptions = [
          ...data?.hourly_rate_tasks_estimations,
          ...data?.productivity_orders,
        ];

        setFormData((prev) => ({
          ...prev,
          area: makeOptions,
          loading: false,
        }));
      }
    );
  }, [area]);

  useEffect(() => {
    if (formData.form?.quality_assurance_id && formData.templates.length) {
      findTemplateChild(formData?.form?.quality_assurance_id);
    }
  }, [formData.templates, formData.form, findTemplateChild]);

  const onSubmit = useCallback(
    async (data) => {
      await new Promise((res) => {
        setSubmitting(true);
        res(true);
      });

      const taskItem = formData.area.find((item) => item.id === data?.task_id);

      WorkService.post("/api/v1/worksite/quality-assurance/assign-task", {
        ...data,
        type: taskItem
          ? "productivity_orders_data" in taskItem
            ? "productivity"
            : "hourly"
          : "",
        area_id: area,
        worksite_id: id,
        deletedQAD_ids: [],
      })
        .then((res) => {
          if (res?.data?.status) {
            setModalOpen(false);
            setFormData((prev) => ({
              ...prev,
              currentSelect: [],
            }));
            reset();
          }
          setSubmitting(false);
        })
        .catch((err) => {
          setSubmitting(false);
        });
    },
    [formData.area, area, id, formData.quality_assurance_details]
  );

  useEffect(() => {
    if (modalOpen) {
      if (!formData.templates.length) {
        getQATemplates();
      }
      if (!formData.area.length) {
        getAreaData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  return (
    <Fragment>
      <Button
        className="flex justify-center mr-4 items-center py-3 border border-[#111111] h-[42px]"
        onClick={toggleModal}
      >
        Assign Quality Assurance
      </Button>
      <Modal open={modalOpen} onCancel={toggleModal} footer={[]}>
        {formData.loading ? (
          <TextShimmer
            data={{
              line: 20,
              gap: 10,
            }}
          />
        ) : (
          <div className="flex flex-col gap-5">
            <h3 className="text-lg font-semibold">Assign Quality Assurance</h3>

            <span className="flex flex-col">
              <label>Select the Task</label>
              <Controller
                control={control}
                name="task_id"
                render={({ field: { onChange, value } }) => (
                  <Select onChange={onChange} value={value}>
                    {formData.area.map((item) => (
                      <Select.Option value={item.id} key={item.id}>
                        {item.name || item?.hourly_rate_task?.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              />
            </span>
            <span className="flex flex-col">
              <label>Select the Quality Assurance template</label>
              <Controller
                control={control}
                name="quality_assurance_id"
                render={({ field: { onChange, value } }) => (
                  <Select
                    onChange={(e) => {
                      onChange(e);
                      findTemplateChild(e);
                      setValue("quality_assurance_details", []);
                    }}
                    value={value}
                    showSearch
                    onSearch={throttler(getQATemplates, 300)}
                    filterOption={false}
                  >
                    {formData.templates.map((item) => (
                      <Select.Option value={item._id} key={item._id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              />
            </span>

            {!!formData.backupSelect.length && (
              <span>
                <h3 className="text-lg font-semibold">
                  Select the required quality Assurance
                </h3>
                <Input
                  placeholder="Search for Quality Assurance"
                  prefix={<BiSearch />}
                  onChange={({ target: { value } }) => {
                    onSearch(value);
                  }}
                />
              </span>
            )}
            {formData.currentSelect.map((item) => (
              <div>
                <span
                  className="flex justify-between px-2"
                  onClick={() => onCheck(item._id)}
                >
                  <p className="m-0">{item.name}</p>
                  <Checkbox
                    checked={watch("quality_assurance_details").includes(
                      item._id
                    )}
                  />
                </span>
                <hr className="border-b" />
              </div>
            ))}

            <Button
              onClick={handleSubmit(onSubmit)}
              className="bg-[#0F4C7D] mx-auto text-white"
              disabled={!formData.currentSelect.length}
            >
              {isSubmitting ? <ButtonSpinner /> : "Save Change"}
            </Button>
          </div>
        )}
      </Modal>
    </Fragment>
  );
};

export default AssignQA;
