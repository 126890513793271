import React from "react";
import {
  ShimmerTable,
  ShimmerText,
  ShimmerCircularImage,
} from "react-shimmer-effects";

const TableShimmer = ({ data }) => {
  return (
    <>
      <ShimmerTable
        row={data?.row ? data?.row : 10}
        col={data?.col ? data?.col : 5}
      />
    </>
  );
};

const TextShimmer = ({ data }) => {
  return (
    <>
      <ShimmerText
        line={data?.line ? data?.line : 5}
        gap={data?.gap ? data?.gap : 10}
        className={data?.className ? data?.className : ""}
      />
    </>
  );
};

const CircleShimmer = ({ data }) => {
  let size = data?.size ? data?.size : 150;

  return (
    <>
      <ShimmerCircularImage size={size} />
    </>
  );
};

export { TableShimmer, TextShimmer, CircleShimmer };
