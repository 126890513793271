import React from "react";
import { DatePicker, Input, Modal, Select } from "antd";
import { Button } from "antd";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { Fragment } from "react";
import InputError from "../../../../components/InputError";
import { useState } from "react";
import { PayRollService } from "../../../../config/axiosUrl";
import { isValidWeekRange } from "../../../../utils/routes/useWindowDimensions";

const WorkCoverModal = ({ visible, onCancel, isEditing, editData, onSave }) => {
  const [error, setError] = useState();
  const modalTitle = isEditing ? "Editing WorkCover" : "Creating WorkCover";
  const { control, handleSubmit, reset } = useForm({
    defaultValues: isEditing ? editData : {}, // Use an empty object for new entries
  });
  const [payrollCat, setPayrollCat] = useState([]);
  const [specificAllowanceData, setSpecificAllowanceData] = useState([]);

  const getPayrollCategories = () => {
    PayRollService.get(
      `/api/v1/payroll/setting/get-payroll-category-data`
    ).then((res) => {
      setPayrollCat(res?.data?.data?.data);
    });
  };
  const getSpecificAllowance = () => {
    PayRollService.get("/api/v1/setting/payroll/get-specific-allowance").then(
      (res) => {
        setSpecificAllowanceData(res?.data?.data?.data);
      }
    );
  };
  useEffect(() => {
    getPayrollCategories();
    getSpecificAllowance();
  }, []);
  useEffect(() => {
    // Reset the form with new initial values when visible and editing
    if (visible && isEditing) {
      reset(editData);
    } else {
      reset(); // Reset the form without preloading data when creating new
    }
  }, [visible, isEditing, reset, editData]);

  const onSubmit = (data) => {
    if (!isValidWeekRange(data.from, data.to)) {
      setError("The date range must be exactly one week (7 days).");
      return; // Exit the function if validation fails
    }
    reset();
    setError("");
    onSave(data); // Pass the form data to the onSave function in Misc
  };

  return (
    <Modal
      title={modalTitle}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="item mt-5">
          <label htmlFor="name">Work Cover name</label>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input {...field} type="text" className="mt-3" id="name" />
            )}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="summary_column">Summary Column</label>
          <Controller
            name="summary_column"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                className="mt-3"
                id="summary_column"
              />
            )}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="rate">Rate</label>
          <Controller
            name="rate"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                className="mt-3"
                id="rate"
                min="0"
              />
            )}
          />
        </div>
        <Controller
          name="payroll_category_id"
          control={control}
          render={({ field }) => (
            <div className="item mt-5">
              <label htmlFor="payroll_category_id">
                Select Payroll Category
              </label>
              <Select className="mt-3 w-full" {...field}>
                {payrollCat.map((category) => (
                  <Select.Option key={category.id} value={category._id}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}
        />

        <Controller
          name="specific_allowance_id"
          control={control}
          render={({ field }) => (
            <div className="item mt-5">
              <label htmlFor="specific_allowance_id">
                Select Specific Allowance
              </label>
              <Select className="mt-3 w-full" {...field}>
                {specificAllowanceData?.map((category) => (
                  <Select.Option key={category.id} value={category._id}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}
        />
        <div className="item mt-5">
          <label htmlFor="frequency">Frequency</label>
          <Controller
            name="frequency"
            control={control}
            render={({ field }) => (
              <Select {...field} className="mt-3 w-full ">
                <Select.Option value="1">Per Week</Select.Option>
              </Select>
            )}
          />
        </div>
        <div className="mt-5 flex justify-between items-center">
          <div>
            <label htmlFor="from">From</label>
            <Controller
              name="from"
              control={control}
              render={({ field }) => (
                <Input {...field} type="date" className="mt-3" id="from" />
              )}
            />
          </div>
          <div>
            <label htmlFor="to">To</label>
            <Controller
              name="to"
              control={control}
              render={({ field }) => (
                <Input {...field} type="date" className="mt-3" id="to" />
              )}
            />
          </div>
        </div>
        {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
        <div className="item mt-5 justify-between flex">
          <div className=" mt-3">
            <Controller
              name="export_to_summary"
              control={control}
              render={({ field }) => (
                <input type="checkbox" id="export_to_summary" {...field} />
              )}
            />
            <label htmlFor="export_to_summary" className="ml-2">
              Export to Summary
            </label>
          </div>
          <Button htmlType="submit" className="btn btn-blue">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default WorkCoverModal;
