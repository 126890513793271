import React, { useEffect, useRef, useState, useContext } from "react";
import { FaTimes } from "react-icons/fa";
import PayrollCategoryForm from "../../pages/Admin/Settings/Payroll/Categories/PayrollCategoryForm";
import { SearchData } from "../../helper/global/global";
import { PayRollService } from "../../config/axiosUrl";
import { TextShimmer } from "../../components/shimmer/shimmer";
import { GetEmployeeStatus } from "../../helper/setup/payroll/employeentstatus";
import { GetAgreement } from "../../helper/setup/payroll/agreement";
import { CreateEditPayroll } from "../../helper/setup/payroll/payrollcatagory";
import { toast } from "react-toastify";
import { CreateAdminContext } from "../../layouts/SettingsLayout";

const CreatePayrollCategoryModal = ({
  setCreatePayrollCategory,
  selectedData,
}) => {
  const [createAdmincontext, setcreateAdminContext] =
    useContext(CreateAdminContext);
  console.log(selectedData);
  const initialvalues = {
    isSave: selectedData?.id ? 0 : 1,
    id: selectedData?.id ? selectedData?.id : "",
    name: selectedData?.name ? selectedData?.name : "",
    employement_status_id: "",
    hourly_rate_name: selectedData?.hourly_rate_name
      ? selectedData?.hourly_rate_name
      : "",
    hourly_rate: selectedData?.hourly_rate ? selectedData?.hourly_rate : "",
    overtime_rate_name: selectedData?.overtime_rate_name
      ? selectedData?.overtime_rate_name
      : "",
    overtime_rate: selectedData?.overtime_rate
      ? selectedData?.overtime_rate
      : "",
    double_time_rate_name: selectedData?.double_time_rate_name
      ? selectedData?.double_time_rate_name
      : "",
    double_time_rate: selectedData?.double_time_rate
      ? selectedData?.double_time_rate
      : "",
    agreement_id: "",
    errors: {
      name: "",
      employement_status_id:
        selectedData?.employment_status?.status == 2
          ? "Employement status has been deleted"
          : "",
      hourly_rate_name: "",
      hourly_rate: "",
      overtime_rate_name: "",
      overtime_rate: "",
      double_time_rate_name: "",
      double_time_rate: "",
      agreement_id:
        selectedData?.agreements?.status == 2
          ? "Agreement has been deleted"
          : "",
    },
    is_cost_work_as_all_inclusive: selectedData?.is_cost_work_as_all_inclusive
      ? selectedData?.is_cost_work_as_all_inclusive
      : 0,
    employee_status_options: [],
    agreement_options: [],
    isLoading: false,
    isShimmer: true,
  };

  const [payrollCategory, setPayrollCategory] = useState(initialvalues);

  let errorField = [
    "name",
    "employement_status_id",
    "hourly_rate_name",
    "hourly_rate",
    "overtime_rate_name",
    "overtime_rate",
    "double_time_rate_name",
    "double_time_rate",
    "agreement_id",
  ];

  let errors = useRef([]);
  errorField?.map((value, index) => {
    errors.current[value] = React?.createRef();
  });

  const handlePayroll = {
    get: (e) => {
      setPayrollCategory({
        ...payrollCategory,
        [e?.target?.name]: e?.target?.value,
      });
    },
    getCategory: (e, name) => {
      setPayrollCategory({
        ...payrollCategory,
        [name]: e,
      });
    },
    check: (e) => {
      setPayrollCategory({
        ...payrollCategory,
        [e?.target?.name]: Number(e?.target?.checked),
      });
    },
    getEmployeeStatus: async (e) => {
      const params = {
        value: e,
        api: "/api/v1/setting/payroll/get-employment-status-data",
        service: PayRollService,
      };
      let data = await SearchData(params);

      let allEmploymentStatus = [];

      if (data?.status) {
        data?.data?.data?.map((status, index) => {
          allEmploymentStatus?.push({ value: status?.id, label: status?.name });
        });
        setPayrollCategory({
          ...payrollCategory,
          employee_status_options: allEmploymentStatus,
        });
      }
    },
    getAgreement: async (e) => {
      const params = {
        value: e,
        api: "/api/v1/payroll/setting/get-agreement-data",
        service: PayRollService,
      };
      let data = await SearchData(params);
      let allAgreement = [];
      if (data?.status) {
        data?.data?.data?.map((status, index) => {
          allAgreement?.push({ value: status?.id, label: status?.name });
        });
        setPayrollCategory({
          ...payrollCategory,
          agreement_options: allAgreement,
        });
      }
    },
    submit: () => {
      let keys = Reflect.ownKeys(payrollCategory?.errors);
      let num = 0;

      keys?.map((value, index) => {
        if (num < keys?.length) {
          if (num == index) {
            if (!payrollCategory?.[value]) {
              setPayrollCategory({
                ...payrollCategory,
                errors: {
                  ...initialvalues?.errors,
                  [value]: `Enter ${value?.replace(/_/g, " ")}`,
                },
              });
              errors?.current?.[value]?.current?.focus();
            } else {
              setPayrollCategory({
                ...payrollCategory,
                errors: { ...initialvalues?.errors },
              });

              num++;
              console.log(num, keys?.length);

              if (num == keys?.length) {
                handlePayroll?.create();
              }
            }
          }
        }
      });
    },
    create: async () => {
      setPayrollCategory({
        ...payrollCategory,
        isLoading: true,
      });

      let data = await CreateEditPayroll(payrollCategory);
      if (data?.status) {
        setcreateAdminContext(true);
        toast.success(data?.message);
        setCreatePayrollCategory(false);
        setPayrollCategory(initialvalues);
      } else {
        setPayrollCategory({
          ...payrollCategory,
          isLoading: false,
        });
        toast.error(data?.message);
      }
    },
  };

  useEffect(() => {
    (async () => {
      let allEmploymentStatus = [];
      let allAgreement = [];

      let selectedEmployStatus = selectedData?.employment_status;
      let selectedAgreement = selectedData?.agreements;

      console.log(selectedAgreement, selectedEmployStatus, "selectedData");

      let data = await GetEmployeeStatus();
      if (data?.status) {
        data?.data?.data?.map((status, index) => {
          if (status?.id != selectedEmployStatus?.id) {
            allEmploymentStatus?.push({
              value: status?.id,
              label: status?.name,
            });
          } else {
            allEmploymentStatus?.push({
              value: selectedEmployStatus?.id,
              label: selectedEmployStatus?.name,
            });
          }
        });

        let getData = await GetAgreement();
        if (getData?.status) {
          getData?.data?.data?.map((status, index) => {
            if (status?.id != selectedAgreement?.id) {
              allAgreement?.push({ value: status?.id, label: status?.name });
            } else {
              allAgreement?.push({
                value: selectedAgreement?.id,
                label: selectedAgreement?.name,
              });
            }
          });

          setPayrollCategory({
            ...payrollCategory,
            agreement_options: allAgreement,
            employement_status_id: selectedEmployStatus?.id,
            agreement_id: selectedAgreement?.id,
            employee_status_options: allEmploymentStatus,
            isShimmer: false,
          });
          setTimeout(() => {
            errors?.current?.["name"]?.current?.focus();
          }, 100);
        }
      }
    })();
  }, []);

  const closeModal = () => {
    setCreatePayrollCategory(false);
  };
  return (
    <div className="modal">
      <div className="modal-content">
        {payrollCategory?.isShimmer ? (
          <TextShimmer data={{ gap: 15, line: 20 }} />
        ) : (
          <>
            <div className="text-right">
              <button className="close-modal" onClick={closeModal}>
                <FaTimes />
              </button>
            </div>

            <div className="modal-body mt-3">
              <h2 className="text-xl font-bold">
                {selectedData?.id ? "Edit" : "Creating "} Payroll Category
              </h2>

              <PayrollCategoryForm
                data={payrollCategory}
                setData={setPayrollCategory}
                handle={handlePayroll}
                errors={errors}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreatePayrollCategoryModal;
