import React, { useCallback, useState } from "react";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import DeleteAbsenceModal from "../Modal/DeleteAbsenceModal";

import dropdownIcon from "../../assets/images/settings/drop-icon.png";
import { Button } from "antd";
import AddNewAbsenceModal from "../Modal/AddNewAbsenceModal";
import CreatePayrollCategoryModal from "../Modal/CreatePayrollCategory";
import { GetAbsences } from "../../helper/setup/payroll/absences";
import { Archive } from "../../assets/svg";
import { PayRollService } from "../../config/axiosUrl";
import Confirmation from "../../components/Confirmation";

const PayrollCategoriesTable = ({
  data,
  setData,
  handle,
  CreatePayrollCategory,
  setCreatePayrollCategory,
  visibleDelete,
  setVisibleDelete,
  addNewAbsence,
  setAddNewAbsence,
  onArchiveClick,
}) => {
  const [openRow, setOpenRow] = useState(null);

  const rowToOpen = (index) => {
    if (index == openRow) {
      setOpenRow(null);
    } else {
      setOpenRow(index);
    }
  };

  const visibleDeleteModal = (item, index, type) => {
    console.log(item);
    setVisibleDelete(true);
    if (type == "payroll") {
      setData({
        ...data,
        selectedData: { ...item, index: index, type: type ? type : "" },
      });
    } else {
      setData({
        ...data,
        selectedData: {
          ...item,
          name: item?.absences?.name,
        },
      });
    }
  };

  const visibleEditPayrollModal = (item, index) => {
    setCreatePayrollCategory(true);
    setData({
      ...data,
      selectedData: { ...item, index: index },
    });
  };

  const addAbsence = async (item, index) => {
    let allabsences = [];
    item?.payroll_category_available_absences?.map((absences, index) =>
      allabsences?.push(absences?.absences?.id)
    );

    setAddNewAbsence(true);
    setData({
      ...data,
      isAbsencesShimmer: true,
    });
    let getdata = await GetAbsences();
    if (getdata?.status) {
      setData({
        ...data,
        absences: [...getdata?.data?.data],
        selectedData: {
          ...item,
          available_absences: [],
          index: index,
          isSave: 1,
          payroll_category_available_absences: allabsences,
        },
        isAbsencesShimmer: false,
      });
    }
  };

  return (
    <>
      {visibleDelete && (
        <DeleteAbsenceModal
          setVisibleDelete={setVisibleDelete}
          data={data}
          handle={handle}
        />
      )}

      {addNewAbsence && (
        <AddNewAbsenceModal
          setAddNewAbsence={setAddNewAbsence}
          data={data}
          handle={handle}
        />
      )}

      {CreatePayrollCategory && (
        <CreatePayrollCategoryModal
          setCreatePayrollCategory={setCreatePayrollCategory}
          selectedData={data?.selectedData}
        />
      )}

      <table className="w-full">
        <thead className="bg-[#E8F3FF] text-center">
          <tr className="text-left">
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-start">Employement</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">
                Payroll Category Name
              </div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Agreement</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Rate</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Overtime</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">
                Double Time
              </div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Action</div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {data?.data?.map((item, index) => (
            <>
              <tr
                key={index}
                className="border-b border-[#F0F0F0] mainData cursor-pointer"
              >
                <td className="px-4 py-3">
                  <div className="flex items-center justify-start">
                    {item?.employment_status?.name}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    {item?.name}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    {item?.agreements?.name}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex flex-col items-center justify-center">
                    {/* <p>Base Hourly</p> */}
                    <p className="text-md">
                      {item?.hourly_rate_name}({item?.hourly_rate})
                    </p>
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex flex-col items-center justify-center">
                    {/* <p>Base Hourly</p> */}
                    <p className="text-md">
                      {" "}
                      {item?.overtime_rate_name}({item?.overtime_rate})
                    </p>
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex flex-col items-center justify-center">
                    {/* <p>Base Hourly</p> */}
                    <p className="text-md">
                      {item?.double_time_rate_name}({item?.double_time_rate})
                    </p>
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    <button
                      onClick={() => visibleEditPayrollModal(item, index)}
                      className="mx-2"
                    >
                      <FaEdit title="Edit" />
                    </button>
                    <button
                      onClick={() => onArchiveClick(item)}
                      className="mx-2"
                    >
                      <Archive title="Archive" />
                    </button>
                    <button
                      className="mx-2"
                      onClick={() => visibleDeleteModal(item, index, "payroll")}
                    >
                      <FaTrashAlt title="Delete" />
                    </button>
                  </div>
                </td>
                <td className="text-right">
                  <img
                    src={dropdownIcon}
                    alt="dropdown"
                    className={
                      openRow == index
                        ? "active-dropdownIcon ml-auto mr-3"
                        : "dropdownIcon ml-auto mr-3"
                    }
                    onClick={() => rowToOpen(index)}
                  />
                </td>
              </tr>

              {openRow == index && (
                <>
                  {item?.payroll_category_available_absences?.length > 0 ? (
                    item?.payroll_category_available_absences?.map(
                      (absences, ind) => {
                        return (
                          <>
                            <tr
                              className={
                                absences?.absences?.status == 2
                                  ? "text-red-600"
                                  : ""
                              }
                            >
                              <td
                                colSpan={2}
                                className="px-4 py-5 text-center border-b rose-600"
                              >
                                {absences?.absences?.name}
                              </td>
                              <td className="px-4 py-5 text-center border-b">
                                {absences?.absences?.absence_accrued_name}
                              </td>
                              <td
                                colSpan={3}
                                className="px-4 py-5 text-center border-b"
                              >
                                {absences?.absences?.absence_taken_name}
                              </td>
                              <td className="px-4 py-5 text-center border-b">
                                <button
                                  className="mx-2"
                                  onClick={() => {
                                    visibleDeleteModal(absences, ind);
                                  }}
                                >
                                  <FaTrashAlt />
                                </button>
                              </td>
                              <td className="border-b text-right">
                                {ind == 0 && (
                                  <>
                                    <Button
                                      onClick={() => addAbsence(item, index)}
                                      className="btn-light ml-auto mr-3 text-black h-[40px] px-4 flex justify-center items-center"
                                    >
                                      Search by Absences Name{" "}
                                      <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                                        <FaPlus />
                                      </div>
                                    </Button>
                                  </>
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      }
                    )
                  ) : (
                    <>
                      <tr className="">
                        <td
                          colSpan={7}
                          className="px-4 py-5 text-center border-b"
                        ></td>

                        <td className="border-b text-right">
                          <>
                            <div>
                              <div className="py-3">
                                <Button
                                  onClick={() => addAbsence(item, index)}
                                  className="btn-light ml-auto mr-3 text-black h-[40px] px-4 flex justify-center items-center"
                                >
                                  Add{" "}
                                  <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                                    <FaPlus />
                                  </div>
                                </Button>
                              </div>
                            </div>
                          </>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              )}
            </>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default PayrollCategoriesTable;
