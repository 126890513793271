import React from "react";

const InputError = ({ message }) => {
  return (
    <p
      className={
        message ? "pt-1 text-red-600 text-xs" : "user-select-none text-white "
      }
    >
      {message}
    </p>
  );
};

export default InputError;
