import { PayRollService } from "../../../config/axiosUrl";
import { ApiAuthorizaion } from "../../../auth/appAuth";

const CreateEditAgreement = async (agreement) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: agreement?.id,
      isSave: agreement?.isSave,
      name: agreement?.name,
      agreement_breaks_id: agreement?.agreement_breaks_id,
      hours: agreement?.hours,
      unpaid_break_value: agreement?.unpaid_break_value,
      unpaid_break_duration: agreement?.unpaid_break_duration,
      first_break_value: agreement?.first_break_value,
      first_break_duration: agreement?.first_break_duration,
      first_break_paid_value: agreement?.first_break_paid_value,
      first_break_paid_duration: agreement?.first_break_paid_duration,
      second_break_value: agreement?.second_break_value,
      second_break_duration: agreement?.second_break_duration,
      second_break_paid_value: agreement?.second_break_paid_value,
      second_break_paid_duration: agreement?.second_break_paid_duration,
      third_break_value: agreement?.third_break_value,
      third_break_duration: agreement?.third_break_duration,
      third_break_paid_value: agreement?.third_break_paid_value,
      third_break_paid_duration: agreement?.third_break_paid_duration,
    };

    let data = await PayRollService?.post(
      "/api/v1/payroll/setting/create-update-agreement-data?dev=5",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetAgreement = async (agreement, cursorvalue) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    let data = await PayRollService?.get(
      `/api/v1/payroll/setting/get-agreement-data?cursor`,
      {
        headers: authorization?.headers,
      }
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetAgreementById = async (agreement) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: agreement?.id,
    };

    let data = await PayRollService?.post(
      "/api/v1/payroll/setting/get-agreement-data-id",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const DeleteAgreement = async (agreement) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: agreement?.id,
    };

    let data = await PayRollService?.post(
      "/api/v1/payroll/setting/delete-agreement-data",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

export { CreateEditAgreement, GetAgreement, GetAgreementById, DeleteAgreement };
