import { Input } from "antd";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { UserService } from "../../../config/axiosUrl";
import { ButtonSpinner } from "../../../components/spinners";
import InputError from "../../../components/InputError";
import { toast } from "react-toastify";
import { ArrowLeft } from "../../../assets/svg";

const CreateDesignation = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { designation } = location.state || {}; // Get designation data from state

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      id: designation?.id || null,
      name: designation?.name || "",
    },
  });

  useEffect(() => {
    if (designation) {
      setValue("id", designation.id);
      setValue("name", designation.name);
    }
  }, [designation, setValue]);

  const onSubmit = useCallback(
    async (data) => {
      console.log("the data is", data);
      setSubmitting(true);
      await new Promise((res) => res(true));

      // Remove `id` from `data` if it's not supposed to be there
      if (!data.id) {
        delete data.id;
      }

      UserService.post(
        "/api/v1/personal/workers/worker-designation/create-edit-designation",
        { ...data, isSave: data.id ? 0 : 1 }
      )
        .then((res) => {
          if (res?.data?.status) {
            setSubmitting(false);
            toast.success(res?.data?.message);
            navigate(-1);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.error("Error creating template");
          setSubmitting(false);
        });
    },
    [navigate]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex">
        <div className="mx-auto my-8 w-70 flex flex-col gap-8">
          <div className="bg-white p-8">
            <span className="flex items-center mb-5 gap-3">
              <ArrowLeft
                onClick={() => navigate(-1)}
                className="cursor-pointer"
              />
              <h1 className="text-xl font-semibold">
                {designation?.id
                  ? "Edit Employee Designation"
                  : "Create Employee Designation"}
              </h1>
            </span>
            <label>Designation name</label>
            <Controller
              name="name"
              rules={{
                required: "Please enter designation name",
              }}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Fragment>
                  <Input className="mt-5" onChange={onChange} value={value} />
                  <InputError message={error?.message} />
                </Fragment>
              )}
            />
            <div className="flex gap-5 justify-center mt-10">
              <button
                className="text-white rounded-lg px-5 py-3 bg-[#0F4C7D]"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <ButtonSpinner />
                ) : designation?.id ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateDesignation;
