import { Button, Checkbox, List } from "antd";
import React from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import { TextShimmer } from "../../components/shimmer/shimmer";
import { ButtonSpinner } from "../../components/spinners";

const AddNewAbsenceModal = ({ setAddNewAbsence, data, handle }) => {
  const closeModal = () => {
    setAddNewAbsence(false);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="text-right">
          <button className="close-modal" onClick={closeModal}>
            <FaTimes />
          </button>
        </div>

        <div className="modal-body mt-2">
          {data?.isAbsencesShimmer ? (
            <>
              <TextShimmer data={{ gap: 15, line: 20 }} />
            </>
          ) : (
            <>
              <h2 className="text-xl font-bold">Available Absences</h2>

              <div className="mt-5">
                <div className="mb-5">
                  <div className="border border-[#111111] flex items-center p-1 rounded-md w-full  my-2 bg-white">
                    <FaSearch className="ml-2" />{" "}
                    <input
                      placeholder="Search by Absence Name "
                      className="h-[31px] bg-transparent text-sm w-full px-2"
                      onChange={handle?.getAbsences}
                    />
                  </div>
                </div>
                <div className="border divide-y rounded-md">
                  {data?.absences?.map((absences, index) => {
                    return (
                      <>
                        {!data?.selectedData?.payroll_category_available_absences?.includes(
                          absences?.id
                        ) && (
                          <>
                            <div
                              className="px-6 py-2 hover:bg-slate-100 cursor-pointer flex justify-between"
                              key={index}
                            >
                              <div>
                                <label
                                  className="text-sm"
                                  key={index}
                                  htmlFor={index}
                                >
                                  {absences?.name}
                                </label>
                              </div>
                              <div>
                                <Checkbox
                                  id={index}
                                  key={index}
                                  onChange={(e) => handle?.check(e, absences)}
                                  checked={data?.selectedData?.available_absences?.includes(
                                    absences?.id
                                  )}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    );
                  })}
                </div>
                <div className="">
                  <div className="pt-4">
                    <Button
                      className="float-right btn btn-blue w-1/5 bg-blue-disable"
                      onClick={handle?.addAbsence}
                      disabled={data?.isLoading}
                    >
                      {data?.isLoading ? <ButtonSpinner /> : "Add"}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewAbsenceModal;
