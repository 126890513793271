import React from "react";
import { TableDefaultSvg } from "../../assets/svg/tableDefaul";
import { TableDefaultPage } from "../../components/default/defaultPage";

import { FaArrowLeft } from "react-icons/fa";

const CodeTableLogs = () => {
  const tableStyle = {
    background: "white",
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column", // Add this line to set the container as a column
  };

  let defaultPage = {
    disclaimer: "No Data Found",
    description: "There are no data available",
    buttonText: "",
    svg: <TableDefaultSvg />,
    redirect: "",
  };

  return (
    <div style={tableStyle}>
      <TableDefaultPage data={defaultPage} />
    </div>
  );
};

export default CodeTableLogs;
