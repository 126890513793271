import React, { useState } from "react";
import { Input, Modal, Select } from "antd";
import { Button } from "antd";
import { Controller } from "react-hook-form";
import { Fragment } from "react";
import InputError from "../../../../components/InputError";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaTimes } from "react-icons/fa";
import { PayRollService } from "../../../../config/axiosUrl";

const MiscModal = ({ visible, onCancel, isEditing, editData, onSave }) => {
  const modalTitle = isEditing
    ? "Editing Miscellaneous"
    : "Creating Miscellaneous";

  const { control, handleSubmit, reset } = useForm({
    defaultValues: isEditing ? editData : {}, // Use an empty object for new entries
  });

  const [payrollCat, setPayrollCat] = useState([]);

  const getPayrollCategories = () => {
    PayRollService.get(
      `/api/v1/payroll/setting/get-payroll-category-data`
    ).then((res) => {
      setPayrollCat(res?.data?.data?.data);
    });
  };
  useEffect(() => {
    getPayrollCategories();
  }, []);
  useEffect(() => {
    // Reset the form with new initial values when visible and editing
    if (visible && isEditing) {
      reset(editData);
      console.log("Resetting form with editData:", editData);
    } else {
      reset(); // Reset the form without preloading data when creating new
      console.log("Resetting form for new entry");
    }
  }, [visible, isEditing, reset, editData]);

  const onSubmit = (data) => {
    reset();
    onSave(data); // Pass the form data to the onSave function in Misc
  };

  return (
    <Modal
      title={modalTitle}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="item mt-5">
          <label htmlFor="name">Miscellaneous name</label>
          {/* Wrap the input field with Controller and specify name and control */}
          <Controller
            name="name"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="rate">Rate</label>
          {/* Wrap the input field with Controller and specify name and control */}
          <Controller
            name="rate"
            control={control}
            render={({ field }) => <Input type="text" {...field} />}
          />
        </div>
        <Controller
          name="payroll_category_id"
          control={control}
          render={({ field }) => (
            <div className="item mt-5">
              <label htmlFor="payroll_category_id">
                Select Payroll Category
              </label>
              <Select className="mt-3 w-full" {...field}>
                {payrollCat.map((category) => (
                  <Select.Option key={category.id} value={category._id}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}
        />
        <div className="item mt-3">
          <div className="flex justify-start items-center mt-3">
            <Controller
              name="export_to_summary"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <input type="checkbox" id="export_to_summary" {...field} />
              )}
            />
            <label htmlFor="export_to_summary" className="ml-2">
              Export to Summary
            </label>
          </div>
        </div>
        <div className="item mt-5 text-center">
          <Button className="btn btn-blue" htmlType="submit">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default MiscModal;
