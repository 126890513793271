import React from "react"

const CloseIcon = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
  <g id="Group_74668" data-name="Group 74668" transform="translate(-0.127)">
    <path id="Path_1534" data-name="Path 1534" d="M176.1,176.716a.5.5,0,0,1,.789-.14c.552.547,1.1,1.1,1.651,1.648q.819-.816,1.636-1.635a.5.5,0,0,1,.721.694c-.547.552-1.1,1.1-1.647,1.651.549.552,1.1,1.1,1.649,1.652a.5.5,0,0,1-.693.721c-.557-.552-1.11-1.11-1.666-1.663-.551.549-1.1,1.1-1.651,1.649a.5.5,0,0,1-.855-.352.51.51,0,0,1,.163-.372q.816-.817,1.633-1.634-.816-.82-1.634-1.636A.5.5,0,0,1,176.1,176.716Z" transform="translate(-172.024 -172.412)" fill="#f91c1c"/>
    <g id="Ellipse_180" data-name="Ellipse 180" transform="translate(0.127)" fill="none" stroke="#ffadad" strokeWidth="1">
      <circle cx="6.5" cy="6.5" r="6.5" stroke="none"/>
      <circle cx="6.5" cy="6.5" r="6" fill="none"/>
    </g>
  </g>
</svg>
)
}
export { CloseIcon };