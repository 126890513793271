import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import {
  FaAngleDown,
  FaAngleUp,
  FaPlus,
  FaMinus,
  FaEdit,
} from "react-icons/fa";
import { TfiTrash } from "react-icons/tfi";
import { Input } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useWindowDimensions from "../../utils/routes/useWindowDimensions";

const WorksiteProductivityOrders = ({
  selected,
  onToggle,
  data,
  setAllData,
  allData,
  handle,
  isarea,
  isTab,
  activeTab,
}) => {
  const { width } = useWindowDimensions();
  let { id } = useParams();
  let navigate = useNavigate();

  const [expanded, setExpanded] = useState(-1);
  function toggleExpand(id) {
    setExpanded((prev) => {
      if (prev === id) {
        return -1;
      } else {
        return id;
      }
    });
  }

  const childToggleExpand = (id, type) => {
    // if (!allData?.toggleOpen) {
    if (type == "visible") {
      setAllData({
        ...allData,
        toggleIds: [id],
        toggleOpen: false,
      });
    } else if (type == "hidden") {
      setAllData({
        ...allData,
        toggleIds: [],
        toggleOpen: false,
      });
    }
    // }
  };

  // const getArea = (area) => {
  //   console.log(id, area, "id,area");
  //   navigate(`/admin/worksite/details/areas/cards/${id}/${area}`);
  // };

  const getArea = (area) => {
    isTab
      ? navigate(`/site-manager/areas/cards/${id}/${area}`)
      : navigate(`/admin/worksite/details/areas/cards/${id}/${area}`);
  };

  useEffect(() => {
    if (isarea) {
      setAllData({
        ...allData,
        // toggleIds: [allData?.id],
        toggleIds: [],
        toggleOpen: false,
      });
    }
  }, []);

  return (
    <div
      className={
        width > 767
          ? "flex flex-col gap-4 py-3 "
          : "flex flex-col gap-4 py-3 fs-10 "
      }
    >
      <div className="w-full flex items-center justify-between ">
        <div className="flex justify-between w-[20%] px-2 ">
          <p className="font-semibold">{data?.abbreviation}</p>
        </div>
        <div className="flex justify-between w-[20%] px-2">
          <p className="font-semibold">{data?.name}</p>
        </div>
        <div className="flex justify-between w-[20%] px-2">
          {console.log(activeTab)}
          <p className="font-semibold gap-80 ">
            {activeTab &&
            ["total", "approved", "active"].includes(activeTab.toLowerCase())
              ? ""
              : `${activeTab || ""}  ${
                  activeTab?.toLowerCase() === "remaining"
                    ? -data?.remaining || "- 0"
                    : ""
                } ${
                  activeTab?.toLowerCase() === "lost"
                    ? -data?.lost || "- 0"
                    : ""
                }`}
          </p>
        </div>

        <div className="flex items-center gap-4">
          <p className="font-semibold">
            Total Productivity Orders:&nbsp;
            {data?.productivity_orders?.length}
          </p>
          <Button
            key={data?.id}
            className={
              width > 767 ? "bg-[#FE650C] py-2" : "bg-[#FE650C] py-2 px-2"
            }
            onClick={
              allData?.toggleIds?.includes(data?.id)
                ? () => childToggleExpand(data?.id, "hidden")
                : () => childToggleExpand(data?.id, "visible")
            }
          >
            {allData?.toggleIds?.includes(data?.id) ? (
              <FaAngleUp className="scale-125 fill-white" />
            ) : (
              <FaAngleDown className="scale-125 fill-white" />
            )}
          </Button>
        </div>
      </div>

      {allData?.toggleIds?.includes(data?.id) && (
        <table className={`w-full`}>
          <thead className="bg-[#E8F3FF] text-center">
            <tr className="text-left">
              <th className=" px-4 py-3">
                <div className="flex items-center justify-center">Code</div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-center">Name</div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-center">Tasks</div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-center pr-4">
                  Time Spent
                </div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-center">
                  Remaining
                </div>
              </th>

              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-center">...</div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white ">
            {data?.productivity_orders?.map((item, index) => (
              <Fragment key={index}>
                <tr
                  key={index}
                  className={`border my-2 border-[#E8E8E8] ${
                    index === expanded ? "border-b-4" : ""
                  }`}
                >
                  <td className="px-4 py-3">
                    <div
                      className={
                        width > 767
                          ? "flex items-center  justify-center text-sm"
                          : "flex items-center  justify-center fs-10"
                      }
                    >
                      {item?.code}
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div
                      className={
                        width > 767
                          ? "flex items-center  justify-center text-sm"
                          : "flex items-center  justify-center fs-10"
                      }
                    >
                      {item?.name}
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center ">
                      {item?.productivity_orders_data?.length > 0 ? (
                        <Fragment>
                          {item?.productivity_orders_data?.map(
                            (task, taskIndex) => (
                              <div
                                className={
                                  width > 767
                                    ? "flex items-center  justify-center fs-14"
                                    : "flex items-center  justify-center fs-10"
                                }
                              >
                                &nbsp;{taskIndex != 0 && ","}&nbsp;
                                {
                                  task?.hourly_rate_tasks_estimations
                                    ?.hourly_rate_task?.name
                                }
                              </div>
                            )
                          )}
                        </Fragment>
                      ) : (
                        "N/A"
                      )}
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center gap-3">
                      {/* <p className="min-w-[5rem]">0</p>{" "} */}
                      <p>{item?.time_spent}</p>
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center">
                      {item?.remaining && item?.time_spent && (
                        <>{item.remaining - item.time_spent}&nbsp;hrs</>
                      )}
                    </div>
                  </td>

                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center">
                      <Button
                        className="bg-[#FE650C]"
                        onClick={() => toggleExpand(index)}
                      >
                        {expanded === index ? (
                          <FaMinus className="fill-white" />
                        ) : (
                          <FaPlus className="fill-white" />
                        )}
                      </Button>
                    </div>
                  </td>
                </tr>
                {expanded === index && (
                  <Fragment>
                    {item?.productivity_orders_data?.map(
                      (child, childIndex) => (
                        <tr key={childIndex} className="border my-2 -[#F0F0F0]">
                          <td className="px-4 py-3">
                            <div className="flex items-center  justify-center text-sm">
                              {
                                child?.hourly_rate_tasks_estimations
                                  ?.hourly_rate_task?.abbreviation
                              }
                            </div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex items-center  justify-center text-sm">
                              {
                                child?.hourly_rate_tasks_estimations
                                  ?.hourly_rate_task?.name
                              }
                            </div>
                          </td>

                          <td>
                            <div className="flex items-center  justify-center text-sm">
                              {child?.remaining}
                            </div>
                          </td>

                          <td className="px-4 py-3">
                            <div className="flex items-center justify-center gap-4">
                              <p> {item?.TimeSpend}</p>
                              {/*  <Input className="max-w-[5rem]" /> */}
                            </div>
                          </td>

                          <td></td>
                          <td>
                            <div className="flex items-center justify-center">
                              {/* <TfiTrash className="cursor-pointer" /> */}
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </Fragment>
                )}
              </Fragment>
            ))}
            {!isarea && (
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {/* {!isTab && ( */}
                <td>
                  <div className="flex items-center justify-center py-3">
                    {data?.productivity_orders?.[0]?.productivity_orders_data
                      ?.length > 0 ? (
                      <Button
                        className="flex justify-center items-center py-3 border border-[#111111] h-[42px]"
                        onClick={() => getArea(data?.id)}
                      >
                        <div className="flex">
                          <div className="pt-1 pr-2">Edit</div>
                          <div className="bg-orange-500 px-3 py-2 rounded-md text-white">
                            <FaEdit />
                          </div>
                        </div>
                      </Button>
                    ) : (
                      <Button
                        onClick={() => getArea(data?.id)}
                        className="flex justify-center items-center py-3 border border-[#111111] h-[42px]"
                      >
                        Add New{" "}
                        <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                          <FaPlus />
                        </div>
                      </Button>
                    )}
                  </div>
                </td>
                {/* )} */}
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default WorksiteProductivityOrders;
