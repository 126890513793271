import React, { useEffect, useState } from "react";
import { EditIcon } from "../../../../assets/svg/editsvgWorker";
import SuccessIcon from "../../../../assets/svg/successIcon";
import { CloseIcon } from "../../../../assets/svg/closeIcon";
import { UserService } from "../../../../config/axiosUrl";
import { ButtonSpinner } from "../../../../components/spinners";
import { toast } from "react-toastify";
import { ApiAuthorizaion } from "../../../../auth/appAuth";
import moment from "moment";
import { Fileurl } from "../../../../config/url";

const EditWorkersDetails = ({
  value,
  fieldName,
  workerId,
  collectionKey,
  handleWorker,
  type,
  inputField,
}) => {
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [fieldValue, setEditFieldValue] = useState();
  const [showInput, setShowInput] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEditFieldValue(value);
  }, []);

  const editOnHover = () => {
    setShowEditIcon(true);
  };

  const removeHover = () => {
    setShowEditIcon(false);
    setShowInput(false);
  };

  const handleChange = (e) => {
    if (inputField === "dob") {
      setEditFieldValue(new Date(e?.target?.value));
    } else {
      if (
        fieldName === "is_subcontractor" ||
        fieldName === "has_ever_made_a_worker_compensation_claim" ||
        fieldName === "is_have_working_visa"
      ) {
        setEditFieldValue(parseInt(e?.target?.value));
      } else {
        setEditFieldValue(e?.target?.value);
      }
    }
  };

  const handleChangeFiles = (e) => {
    setEditFieldValue(e?.target?.files[0]);
  };

  const cancelInput = () => {
    setShowInput(false);
    setEditFieldValue(value);
  };

  const saveEditInput = async (fieldName) => {
    setLoading(true);
    if (
      fieldName === "license_file" ||
      fieldName === "blue_card_file" ||
      fieldName === "trade_certificate"
    ) {
      let formData = new FormData();
      formData?.append("file", fieldValue);
      formData?.append("id", workerId);
      formData?.append("collection", collectionKey);
      formData?.append("field", fieldName);
      let authorization = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: ApiAuthorizaion(),
        },
      };
      let response = await UserService?.post(
        "/api/v1/personal/workers/update-by-field-files?dev=1",
        formData,
        authorization
      );
      if (response?.data?.status) {
        setLoading(false);
        setShowInput(false);
        toast.success("updated successfully");
        window.location.reload();
      } else {
        setLoading(false);
        toast.error(response?.data?.message);
        setEditFieldValue(value);
      }
    } else {
      let bodyParam = {
        id: workerId,
        collection: collectionKey,
        field: fieldName,
        value: fieldValue,
      };
      let authorization = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: ApiAuthorizaion(),
        },
      };
      let response = await UserService?.post(
        "/api/v1/personal/workers/update-by-field?dev=1",
        bodyParam,
        authorization
      );
      if (response?.data?.status) {
        setLoading(false);
        setShowInput(false);
        toast.success("updated successfully");
        handleWorker();
      } else {
        setLoading(false);
        toast.error(response?.data?.message);
        setEditFieldValue(value);
      }
    }
  };

  return (
    <>
      {showInput ? (
        <>
          <div className="flex justify-start items-center flex-wrap">
            {type === "genderRadio" ? (
              <select
                id={fieldName}
                className={
                  fieldValue === ""
                    ? "mr-2 h-[30px] rounded p-1 w-1/2 border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "mr-2 h-[30px] border rounded p-1 w-1/2"
                }
                name={fieldName}
                value={fieldValue}
                onChange={handleChange}
              >
                <option value={""}>Select</option>
                <option value={"male"}>Male</option>
                <option value={"female"}>Female</option>
                <option value={"other"}>Other</option>
              </select>
            ) : type === "bloodType" ? (
              <select
                id={fieldName}
                className={
                  fieldValue === ""
                    ? "mr-2 h-[30px] rounded p-1 w-1/2 border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "mr-2 h-[30px] border rounded p-1 w-1/2"
                }
                name={fieldName}
                value={fieldValue}
                onChange={handleChange}
              >
                <option value="">Select</option>
                <option value={"A+"}>A+</option>
                <option value={"A-"}>A-</option>
                <option value={"B+"}>B+</option>
                <option value={"B-"}>B-</option>
                <option value={"O+"}>O+</option>
                <option value={"O-"}>O-</option>
                <option value={"AB+"}>AB+</option>
                <option value={"AB-"}>AB-</option>
              </select>
            ) : type === "yesNo" ? (
              <select
                id={fieldName}
                className="mr-2 h-[30px] border rounded p-1 w-1/2"
                name={fieldName}
                value={fieldValue}
                onChange={handleChange}
                autoComplete={"off"}
              >
                <option value={0}>No</option>
                <option value={1}>Yes</option>
              </select>
            ) : type === "inputFile" ? (
              <>
                <input
                  type="file"
                  id={fieldName}
                  className="mr-2 h-9 border rounded p-1 w-full hidden"
                  name={fieldName}
                  onChange={handleChangeFiles}
                />
                <label
                  htmlFor={fieldName}
                  className="mr-2 h-9 border  rounded w-full text-center block px-1 py-1 mb-2"
                >
                  Choose File
                </label>
                <div className="text-xs mt-1 mr-1">
                  {fieldValue ? fieldValue?.name : "*No file chosen"}
                </div>
              </>
            ) : (
              <input
                type={inputField === "dob" ? "date" : "text"}
                id={fieldName}
                className={
                  fieldValue === ""
                    ? "mr-2 h-[30px]  rounded p-1 w-1/2 border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "mr-2 h-[30px] border rounded p-1 w-1/2"
                }
                name={fieldName}
                value={fieldValue?.name}
                onChange={handleChange}
              />
            )}
            {fieldValue !== "" && (
              <>
                {!loading ? (
                  <div
                    className="mr-2 cursor-pointer"
                    onClick={() => saveEditInput(fieldName)}
                  >
                    <SuccessIcon />
                  </div>
                ) : (
                  <ButtonSpinner />
                )}
                <div className="mr-2 cursor-pointer" onClick={cancelInput}>
                  <CloseIcon />
                </div>
              </>
            )}
          </div>
          {fieldValue === "" && (
            <p className="pt-2 text-red-600 text-sm">
              {`Please Enter ${fieldName}`}
            </p>
          )}
        </>
      ) : (
        <div
          className="flex justify-start items-center"
          onMouseOver={() => editOnHover()}
          onMouseLeave={() => removeHover()}
        >
          {/* hover-effect */}
          {fieldName === "license_file" ||
          fieldName === "blue_card_file" ||
          fieldName === "trade_certificate" ? (
            <p className="fs-14">
              {fieldValue ? (
                <a
                  className="text-decoration-underline"
                  href={Fileurl + fieldValue}
                  rel="noreferrer"
                  target="_blank"
                >
                  View
                </a>
              ) : (
                "N/A"
              )}
            </p>
          ) : (
            <p className="fs-14 ">
              {fieldValue == "0"
                ? "No"
                : fieldValue == "1"
                ? "Yes"
                : !fieldValue
                ? "-"
                : inputField === "dob"
                ? moment(fieldValue).format("MMMM D, YYYY")
                : fieldValue}
            </p>
          )}
          <div onClick={() => setShowInput(true)}>
            {showEditIcon && <EditIcon />}
          </div>
        </div>
      )}
    </>
  );
};

export default EditWorkersDetails;
