import React from "react";
import { Route, Routes } from "react-router-dom";
import PayrollCategory from "./ArchiveItems/PayrollCategory";
import Absence from "./ArchiveItems/Absence";
import Allowances from "./ArchiveItems/Allowances";
import Agreements from "./ArchiveItems/Agreements";

const ArchiveRoutes = () => {
  return (
    <div className="bg-white">
      <Routes>
        <Route path="payroll-category" element={<PayrollCategory />} />
        <Route path="absence" element={<Absence />} />
        <Route path="allowance" element={<Allowances />} />
        <Route path="agreements" element={<Agreements />} />
      </Routes>
    </div>
  );
};

export default ArchiveRoutes;
