import { Button } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { SaveNotes } from '../../helper/worksite/worksite'
import { toast } from 'react-toastify'
import { ButtonSpinner } from '../../components/spinners'

const NotesModal = ({ closeNotesModal,item }) => {
    const [notesValue, setNotesValue] = useState('')
    const [loading, setIsLoading] = useState(false)
    const { area, id, taskid } = useParams()
    const handleChange = (e) => {
        setNotesValue(e?.target?.value)
    }
    const saveNotes = async () => {
        setIsLoading(true)
        const param = {
            // 'area_id': area,
            // 'hourly_task_id': taskid,
            // 'productivity_task_id': '',
            // 'worksite_id': id,
            // 'notes': notesValue,
            // 'isSave': 1
            id:item?.id,
            note:notesValue
        }
        let data = await SaveNotes(param)
        if (data?.status) {
            setIsLoading(false)
            closeNotesModal()
            toast.success('Notes added successfully')
        }
    }
    return (
        <div className="modal">
            <div className="modal-content">
                <div className="text-right">
                    <button className="close-modal" onClick={closeNotesModal}>
                        <FaTimes />
                    </button>
                </div>

                <div className="modal-body mt-3">
                    <h2 className="text-xl font-bold">
                        Creating Notes
                    </h2>

                    <div className="item mt-5 text-center">
                        <TextArea className='mb-4' rows={4} onChange={handleChange} value={notesValue} />
                        <Button
                            className="btn btn-blue bg-blue-disable"
                            onClick={saveNotes}
                            disabled={notesValue == ''}
                        >
                            {loading ? <ButtonSpinner /> : 'Save'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NotesModal