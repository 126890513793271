import React from 'react';
import headerLogo from '../../../assets/images/Admin/logo.png'

const MobileHeader = () => {
    return (
        <div className='bg-[#0F4C7D] px-3 py-4'>
            <img src={headerLogo}/>
        </div>
    )
}
export default MobileHeader