import React from "react";
import ExpensesHeader from "./ExpensesHeader";
import ExpensesRoutes from "./ExpensesRoutes";

const EmployerExpenses = () => {
  return (
    <div className="p-6">
      <ExpensesHeader />
      <ExpensesRoutes />
    </div>
  );
};

export default EmployerExpenses;
