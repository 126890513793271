import { Button } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { FaTimes } from "react-icons/fa";
import { ButtonSpinner } from "../../components/spinners";

const LogCauseModal = ({ data, setData, handle, setVisibleModal }) => {
  const closeModal = () => {
    setVisibleModal(false);
  };
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="text-right">
          <button className="close-modal" onClick={closeModal}>
            <FaTimes />
          </button>
        </div>

        <div className="modal-body mt-3">
          <h2 className="text-xl font-bold">Log Cause of Warning</h2>

          <form className="mt-4">
            <div className="item mt-5">
              <label htmlFor="log">Enter The Cause</label>
              <TextArea
                rows={4}
                name="log"
                id="log"
                value={data?.log}
                onChange={handle?.getLogCause}
                className={
                  data?.errors?.log
                    ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "mt-3"
                }
              />
              {data?.errors?.log && (
                <p className="pt-2 text-red-600 text-sm">{data?.errors?.log}</p>
              )}
            </div>
            <div className="item mt-5 text-center">
              <Button
                className="btn btn-blue w-1/5 bg-blue-disable"
                onClick={() => handle?.saveLogCause()}
                disabled={data?.isLoading}
              >
                {data?.isLoading ? <ButtonSpinner /> : "Save"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LogCauseModal;
