import { Button, Checkbox, Input } from "antd";
import React from "react";
import { ButtonSpinner } from "../../components/spinners";

const EmployeStatusModalForm = ({ data, setData, handle, name }) => {
  return (
    <form className="mt-4">
      <div className="item mt-5">
        <label htmlFor="name">Name</label>
        <Input
          type="text"
          className={
            data?.errors?.name
              ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
              : "mt-3"
          }
          name="name"
          id="name"
          ref={name}
          value={data?.name}
          onChange={handle?.get}
        />
        {data?.errors?.name && (
          <p className="pt-2 text-red-600 text-sm">{data?.errors?.name}</p>
        )}
      </div>
      <div className="item mt-5">
        <label htmlFor="name" className="text-lg">
          Ignore Worker Allowances{" "}
        </label>
        <div className="flex mt-3 justify-start items-center">
          <Checkbox
            id="c1"
            name="is_ignore_worker_allowances"
            checked={Boolean(data?.is_ignore_worker_allowances)}
            onChange={handle?.check}
          />{" "}
          <label htmlFor="c1" className="ml-3">
            Ignore this category when determining worker allowances.
          </label>
        </div>
      </div>
      <div className="item mt-5">
        <label htmlFor="name" className="text-lg">
          Pay as Salary
        </label>
        <div className="flex mt-3 justify-start items-center">
          <Checkbox
            id="c2"
            name="is_pay_as_salary"
            checked={Boolean(data?.is_pay_as_salary)}
            onChange={handle?.check}
          />{" "}
          <label htmlFor="c2" className="ml-3">
            Calcuate totals to 40 hour work week regardless of clocked hours.
          </label>
        </div>
      </div>
      <div className="item mt-5">
        <label htmlFor="name" className="text-lg">
          Ignore Allowances
        </label>
        <div className="flex mt-3 justify-start items-center">
          <Checkbox
            id="c3"
            name="is_ignore_allowances"
            checked={Boolean(data?.is_ignore_allowances)}
            onChange={handle?.check}
          />{" "}
          <label htmlFor="c3" className="ml-3">
            {" "}
            Ignore Allowances for all shifts worked on this Employment Status.
          </label>
        </div>
      </div>
      <div className="item mt-5 text-center">
        <Button
          className="btn btn-blue w-1/5 bg-blue-disable"
          onClick={handle?.submit}
          disabled={data?.isLoading}
        >
          {data?.isLoading ? <ButtonSpinner /> : "Create"}
        </Button>
      </div>
    </form>
  );
};

export default EmployeStatusModalForm;
