import { Button, Modal } from "antd";
import React from "react";
import { ButtonSpinner } from "../../../../components/spinners";

const ExpenseDeleteWorker = ({
  visible,
  onCancel,
  title,
  onConfirm,
  itemName, // Added itemName prop

  details,
  buttonText = "Delete",
  isSubmitting,
}) => {
  return (
    <Modal footer={null} visible={visible} onCancel={onCancel} centered>
      {visible && (
        <div className="mt-3">
          <h2 className="text-xl font-bold">{title}</h2>

          <form className="mt-4">
            <div className="item mt-8">
              <table>
                <tbody>
                  <tr>Are you willing to delete this worker?</tr>
                  <tr>
                    <td className="font-bold">Item Name: {itemName}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="item mt-8 text-center">
              <Button
                className="btn btn-blue bg-blue-disable w-1/5"
                disabled={isSubmitting}
                onClick={onConfirm}
              >
                {isSubmitting ? <ButtonSpinner /> : buttonText}
              </Button>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
};

export default ExpenseDeleteWorker;
