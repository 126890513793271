import React from "react";

const QualityAssuranceSvg = ({ data }) => {
  let height = data?.height ? data?.height : 18;
  let width = data?.width ? data?.width : 18;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13.634 10.614"
    >
      <g data-name="Group 74298">
        <path
          data-name="Path 1375"
          d="M13.218 3.846a7.521 7.521 0 0 0-6.4-3.841A7.521 7.521 0 0 0 .417 3.846a2.787 2.787 0 0 0 0 2.931 7.521 7.521 0 0 0 6.4 3.841 7.521 7.521 0 0 0 6.4-3.841 2.787 2.787 0 0 0 .001-2.931Zm-.968 2.337a6.425 6.425 0 0 1-5.432 3.3 6.425 6.425 0 0 1-5.432-3.3 1.657 1.657 0 0 1 0-1.742 6.425 6.425 0 0 1 5.432-3.3 6.42 6.42 0 0 1 5.432 3.3 1.657 1.657 0 0 1 0 1.738Z"
        />
        <path
          data-name="Path 1376"
          d="M6.817 2.469a2.839 2.839 0 1 0 2.839 2.839 2.839 2.839 0 0 0-2.839-2.839Zm0 4.543a1.7 1.7 0 1 1 1.7-1.7 1.7 1.7 0 0 1-1.7 1.7Z"
        />
      </g>
    </svg>
  );
};

export default QualityAssuranceSvg;
