import React from 'react'

const CompletedWorkerDefaultImg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="71.731" height="94.146" viewBox="0 0 71.731 94.146">
      <g id="Group_74730" data-name="Group 74730" transform="translate(-745 -526.816)">
        <g id="Group_74724" data-name="Group 74724" transform="translate(-9)">
          <path id="Subtraction_2" data-name="Subtraction 2" d="M3050.731,8805.146H2979v-8.966a22.416,22.416,0,0,1,22.416-22.416h26.9a22.416,22.416,0,0,1,22.416,22.416Zm-35.865-40.349a26.9,26.9,0,1,1,26.9-26.9A26.9,26.9,0,0,1,3014.865,8764.8Z" transform="translate(-2225 -8184.184)" fill="#efefef" />
        </g>
        <g id="_2e2e2eff" data-name="#2e2e2eff" transform="translate(704.437 437.76)">
          <path id="Path_1426" data-name="Path 1426" d="M88.276,107.125a1.492,1.492,0,1,1,2.107,2.112q-8.2,8.194-16.392,16.389a1.5,1.5,0,0,1-2.089,0q-3.724-3.717-7.443-7.44a1.492,1.492,0,1,1,2.107-2.111l6.38,6.379Q80.611,114.791,88.276,107.125Z" transform="translate(0)" fill="#fff" stroke="#fff" strokeWidth="2" />
        </g>
      </g>
    </svg>
  )
}
export default CompletedWorkerDefaultImg
