import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import Slider from "react-slick";
import { Fileurl } from "../../config/url";
import { useParams } from "react-router-dom";

const ImageSliderModal = ({ onClose, selectedItem, deleteImage, setConfirmModal, confirmModal, confirmDeleteModal, setSelectedImg,handle }) => {
  const { taskid, type } = useParams();
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const settings = {
    className: "center",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
  };
  const settings1 = {
    className: "center",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
  };
  if(selectedItem?.file?.length === 0){
    onClose()
    const params = {
      cursor: "",
      skip: "",
      search: "",
      type: type,
      productivity_orders_id: type == "po" && taskid,
      hourly_rate_tasks_estimations_id: type == "hourly" && taskid,
    };
    handle?.get(params);

  }
  return (
    <div className="modal shift-details">
      <div className="modal-content">
        <div className="text-right">
          <button className="close-modal" onClick={onClose}>
            <FaTimes />
          </button>
        </div>

        <div className="modal-body mt-2">
          <h2 className="text-xl font-bold mb-5">Inspection Image</h2>

          <Slider
            asNavFor={nav2}
            ref={(slider1) => setNav1(slider1)}
            {...settings}
          >
            {selectedItem?.file?.length > 0 &&
              selectedItem?.file?.map((img, index) => {
                return (
                  <div key={index}>
                    <img
                      src={Fileurl + img}
                      className="cursor-pointer"
                      width={200}
                      height={200}
                      style={{ margin: "auto" }}
                    />
                  </div>
                );
              })}
          </Slider>
          {selectedItem?.file?.length > 0 && (
            <div className="asnav-slider">
              <Slider
                {...settings1}
                asNavFor={nav1}
                ref={(slider2) => setNav2(slider2)}
                slidesToShow={selectedItem?.file?.length == 1 ? 1 : selectedItem?.file?.length == 2 ? 2: 3}
                swipeToSlide={true}
                focusOnSelect={true}
              >
                {selectedItem?.file?.length > 0 &&
                  selectedItem?.file?.map((img, index) => {
                    return (
                      <div key={index} className="relative">
                        <div className="absolute right-0">
                          <button
                            className="close-modal"
                            onClick={() => { confirmDeleteModal(); setSelectedImg(img) }}
                          >
                            <FaTimes />
                          </button>
                        </div>
                        <img
                          src={Fileurl + img}
                          className="cursor-pointer"
                          width={100}
                          height={100}
                          style={{ margin: "auto" }}
                        />
                      </div>
                    );
                  })}
              </Slider>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ImageSliderModal;
