import { Collapse } from "antd";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { AiOutlineForm } from "react-icons/ai";
import { Note } from "../../../../../assets/svg";
const { Panel } = Collapse;

function WorksiteDetails({ timesheet, navigate }) {
  return (
    <div className="flex flex-col gap-6">
      <div className="bg-white">
        {timesheet?.worksites?.map((timesheetItem, index) => (
          <div key={index}>
            {timesheetItem?.days?.map((worksite, siteIndex) => (
              <div key={siteIndex}>
                {worksite?.worksite_timesheet_data?.map((data, items) => (
                  <div key={items}>
                    {data?.worker_details?.map((user, userIndex) => (
                      <div key={userIndex}>
                        {user?.users?.map((worker, workerIndex) => (
                          <div key={workerIndex}>
                            {data?.worksite_timesheet_workdone?.map(
                              (hourly, hourlyIndex) => (
                                <div key={hourlyIndex}>
                                  {hourly?.hourly_rate_tasks_estimations?.map(
                                    (rate, rateIndex) => (
                                      <div key={rateIndex}>
                                        {rate?.hourly_rate_task?.map(
                                          (hour, hourIndex) => (
                                            <Collapse
                                              className="border-3px p-0"
                                              ghost
                                              accordion
                                              expandIcon={({ isActive }) =>
                                                isActive ? (
                                                  <FaAngleUp />
                                                ) : (
                                                  <FaAngleDown />
                                                )
                                              }
                                              // key={hourIndex}
                                            >
                                              <Panel
                                                ghost
                                                header={
                                                  <div className="inline-flex gap-8">
                                                    <p className="font-semibold text-lg">
                                                      #2312
                                                    </p>
                                                    <p className="font-semibold text-lg">
                                                      {timesheet.name}
                                                    </p>
                                                    <div className="font-semibold text-lg">
                                                      {worksite.day
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        worksite.day.slice(1)}
                                                    </div>
                                                    <div className="font-semibold text-lg g-30">
                                                      {worksite.date}
                                                    </div>
                                                  </div>
                                                }
                                                style={{
                                                  margin: "15px",
                                                }}
                                                className="border-none p-0 m-[0px]"
                                              >
                                                <th>
                                                  <div
                                                    className="font-semibold"
                                                    style={{
                                                      color: "#FE650C",
                                                      fontSize: "20px",
                                                      marginBottom: "20px",
                                                    }}
                                                  >
                                                    {timesheetItem.name}
                                                  </div>
                                                </th>
                                                <table className="text-center font-sm w-full">
                                                  <thead className="timesheet-detailed-table-head-container">
                                                    <tr className="timesheet-detailed-table-main-head">
                                                      <th>
                                                        <div className="font-semibold">
                                                          Name
                                                        </div>
                                                      </th>
                                                      <th>
                                                        <div className="font-semibold text-center">
                                                          Shift Hours
                                                        </div>
                                                      </th>
                                                      <th>
                                                        <div className="font-semibold">
                                                          Work
                                                        </div>
                                                      </th>
                                                      <th>$</th>
                                                      <th>Break</th>
                                                      <th>Status</th>
                                                      <th>Actions</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className="timesheet-detailed-table-body">
                                                    <tr className="border-b border-b-[#E9E9E9]">
                                                      <tr
                                                        style={{
                                                          position: "relative",
                                                          bottom: "1px",
                                                          left: "38px",
                                                        }}
                                                      >
                                                        {data.note && (
                                                          <Note
                                                            style={{
                                                              position:
                                                                "relative",
                                                              top: "15px",
                                                            }}
                                                          />
                                                        )}
                                                        <td>{worker.name}</td>
                                                      </tr>
                                                      <td>
                                                        {data.started} -{" "}
                                                        {data.finished}
                                                      </td>
                                                      {/* <td>{hour.name}</td> */}
                                                      <td>
                                                        {data.dollar.cost}
                                                      </td>
                                                      <td key={items}>
                                                        {data.break || "-"}
                                                      </td>
                                                      <td>
                                                        {data.timesheet_status}
                                                      </td>
                                                      <td
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "center",
                                                        }}
                                                      >
                                                        <button
                                                          className="flex items-center gap-1 border border-[#001C33]"
                                                          onClick={() =>
                                                            navigate(
                                                              "/admin/personnel/site-managers/edit-history"
                                                            )
                                                          }
                                                        >
                                                          <AiOutlineForm
                                                            style={{
                                                              position:
                                                                "relative",
                                                              top: "0px",
                                                            }}
                                                          />
                                                        </button>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </Panel>
                                            </Collapse>
                                          )
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default WorksiteDetails;
