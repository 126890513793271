import React from "react";
import { Input, Modal } from "antd";
import { Controller } from "react-hook-form";
import InputError from "../../../../../components/InputError";
import { ButtonSpinner } from "../../../../../components/spinners";

const CreateSpecificAllowance = ({
  open,
  toggleModal,
  onSubmit,
  isSubmitting,
  handleSubmit,
  control,
  isEdit,
}) => {
  return (
    <Modal onCancel={toggleModal} open={open} footer={null} centered>
      <h2 className="text-xl font-bold">
        {isEdit ? "Edit Specific Allowance" : "Create New Specific Allowance"}
      </h2>

      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          rules={{ required: "Please enter name" }}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className="mt-5">
              <label htmlFor="name">Specific Allowance Name</label>
              <Input
                type="text"
                id="name"
                onChange={onChange}
                value={value}
                className={`mt-3 ${
                  error ? "border-rose-600 hover:border-rose-600" : ""
                }`}
              />
              <InputError message={error?.message} />
            </div>
          )}
        />
        <div className="mt-5 text-center">
          <button
            className="btn btn-blue px-6 py-2 rounded-lg"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <ButtonSpinner /> : "Save Changes"}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateSpecificAllowance;
