import React from "react";
import ExpensesHeader2 from "./ExpensesHeader2";
import ExpensesRoutes2 from "./ExpensesRoutes2";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
const EmployeeExpenses = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // You can access query parameters like this
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("name");
  const id = queryParams.get("id");
  return (
    <div className="p-6">
      <button
        className="text-lg text-black bg-white border-none p-4 w-full flex items-center"
        onClick={() => {
          navigate(`/admin/settings/employee-expenses/ExpensesFront`);
        }}
      >
        <div className="ml-2">
          <FaArrowLeft className="text-sm" />
        </div>
        <div className="ml-2 display-6">Back To Worker</div>
      </button>

      <ExpensesHeader2 />
      <ExpensesRoutes2 />
    </div>
  );
};

export default EmployeeExpenses;
