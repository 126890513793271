import React from "react";
import { PayRollService } from "../../../config/axiosUrl";
import { ApiAuthorizaion } from "../../../auth/appAuth";

const CreateUpdateEmployeementStatus = async (employee) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      name: employee?.name,
      is_ignore_worker_allowances: employee?.is_ignore_worker_allowances,
      is_pay_as_salary: employee?.is_pay_as_salary,
      is_ignore_allowances: employee?.is_ignore_allowances,
      isSave: employee?.isSave,
      id: employee?.id,
    };

    let data = await PayRollService?.post(
      "/api/v1/setting/payroll/edit-create-employment-status",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetEmployeeStatus = async (employee) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {};

    let data = await PayRollService?.get(
      "/api/v1/setting/payroll/get-employment-status-data",
      {
        headers: authorization?.headers,
        params: params,
      }
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const DeleteEmployeeStatus = async (employee) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: employee?.id,
    };

    let data = await PayRollService?.post(
      "/api/v1/setting/payroll/delete-employment-status-data?dev=4",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

export {
  CreateUpdateEmployeementStatus,
  GetEmployeeStatus,
  DeleteEmployeeStatus,
};
