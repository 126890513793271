import { Button, Input, Select } from "antd";
import React, { useEffect, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FaPlus, FaTimes } from "react-icons/fa";
import { PayRollService } from "../../../../../config/axiosUrl";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment } from "react";
import InputError from "../../../../../components/InputError";
import { useState } from "react";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import { toast } from "react-toastify";
import { ButtonSpinner } from "../../../../../components/spinners";

const defaultCat = {
  id: "",
  hourly_payroll_category: "",
  hourly_rate: "",
  hourly_summery: "",
  overtime_payroll_category: "",
  overtime_rate: "",
  overtime_summery: "",
  dt_payroll_category: "",
  dt_rate: "",
  dt_summery: "",
  workers_needed: "",
};

const initVal = {
  display_summary: "Rate",
  name: "",
  export_as: "Rate",
  payroll_worker_allowance_extra_datas: [{ ...defaultCat }],
};

const EditWorkerAllowance = () => {
  const { id = "" } = useParams();
  const navigate = useNavigate();

  const options = useMemo(() => ["Rate", "Hour", "Days"], []);

  const headers = useMemo(
    () => ["Hourly Rate", "Overtime", "Double Time", "Workers Needed"],
    []
  );

  const [workerAllowance, setWorkerAllowance] = useState({
    ...initVal,
  });
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);

  const inputMapper = useMemo(
    () => [
      [
        { label: "Payroll Category", name: "hourly_payroll_category" },
        {
          label: "Rate",
          name: "hourly_rate",
        },
        {
          label: "Summary Column",
          name: "hourly_summery",
        },
      ],
      [
        {
          label: "Payroll Category",
          name: "overtime_payroll_category",
        },
        {
          label: "Rate",
          name: "overtime_rate",
        },
        {
          label: "Summary Column",
          name: "overtime_summery",
        },
      ],
      [
        { label: "Payroll Category", name: "dt_payroll_category" },
        {
          label: "Rate",
          name: "dt_rate",
        },
        {
          label: "Summary Column",
          name: "dt_summery",
        },
      ],
      [
        {
          label: "Effective Worker Reach",
          name: "workers_needed",
        },
      ],
    ],
    []
  );

  const apiFunctions = {
    get: useMemo(
      () => (res) => {
        PayRollService.post(
          "api/v1/setting/payroll/get-worksite-allowance-extra-data-by-id",
          { payroll_worker_allowance_id: id }
        ).then((res) => {
          let { id: payroll_worker_allowance_id = id, ...data } =
            res?.data?.data?.data;

          if (!data?.payroll_worker_allowance_extra_datas?.length) {
            data.payroll_worker_allowance_extra_datas = [{ ...defaultCat }];
          }

          setWorkerAllowance((prev) => ({
            ...prev,
            ...data,
            payroll_worker_allowance_id,
          }));
          setLoading(false);
        });
      },
      []
    ),
    submit: useMemo(
      () => async (data) => {
        await new Promise((resolve) => {
          setSubmitting(true);
          resolve(true);
        });

        PayRollService.post(
          "api/v1/setting/payroll/create-edit-worker-allowance-extra-data?dev=3",
          data
        )
          .then((res) => {
            if (res?.data?.status) {
              navigate(-1);
              toast.success(res?.data?.message);
            } else {
              toast.error(res?.data?.message);
            }
            setSubmitting(false);
          })
          .catch((err) => {
            setSubmitting(false);
            toast.error("Failed to update leading hand allowance");
          });
      },
      []
    ),
  };
  const { handleSubmit, control, watch } = useForm({
    defaultValues: initVal,
    values: workerAllowance,
  });

  const {
    fields: workerField,
    append: workerAppend,
    remove: workerRemove,
  } = useFieldArray({
    control,
    name: "payroll_worker_allowance_extra_datas",
    keyName: "fieldID",
  });

  useEffect(() => {
    apiFunctions.get();
  }, []);

  return (
    <form onSubmit={handleSubmit(apiFunctions.submit)}>
      <div className="bg-[#f5f5f5] flex justify-center">
        {isLoading ? (
          <TextShimmer
            data={{
              line: 25,
              gap: 15,
              className: "bg-white",
            }}
          />
        ) : (
          <div className="w-[75%] my-8 flex flex-col">
            <div className="bg-white p-6">
              <h1 className="font-semibold text-xl">
                Editing Leading Hand Allowance
              </h1>
              <div className="flex flex-col">
                <span className="w-[70%]">
                  <label>Name</label>
                  <Controller
                    control={control}
                    name="name"
                    render={({ field: { value, onChange } }) => (
                      <Input
                        className="mt-3"
                        value={value}
                        onChange={({ target: { value } }) => onChange(value)}
                      />
                    )}
                  />
                </span>
                <div className="flex mt-4 w-[70%] gap-8">
                  <div className="flex flex-1 flex-col">
                    <label>Display In Summary As</label>
                    <Controller
                      name="display_in_summery_as"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          className="mt-3"
                          value={value}
                          onChange={onChange}
                        >
                          {options.map((item) => (
                            <Select.Option value={item} key={item}>
                              {item}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    />
                  </div>
                  <div className="flex flex-1 flex-col">
                    <label>Export As</label>
                    <Controller
                      name="export_as"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          className="mt-3"
                          value={value}
                          onChange={onChange}
                        >
                          {options.map((item) => (
                            <Select.Option value={item} key={item}>
                              {item}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white">
              <div className="bg-[#E8F3FF] w-full p-3 flex">
                {headers.map((item, index) => (
                  <h1 className="w-[25%] text-center font-semibold" key={index}>
                    {item}
                  </h1>
                ))}
              </div>
              {workerField?.map((cat, catIndex) => (
                <div
                  className="w-fulll p-3 flex gap-4 bg-white border-b-2 border-b-[#E5E5E5] relative"
                  key={cat.fieldID}
                >
                  {!watch(
                    `payroll_worker_allowance_extra_datas[${catIndex}].payroll_worker_allowance_id`
                  ) && (
                    <div className="absolute right-2">
                      {/* <FaTimes
                        onClick={() => workerRemove(catIndex)}
                        className="cursor-pointer"
                      /> */}
                    </div>
                  )}
                  {inputMapper.map((item, index) => (
                    <div className="w-[25%] flex flex-col px-4" key={index}>
                      {item.map((child, childIndex) => (
                        <span className="mt-4" key={childIndex}>
                          <label>{child.label}</label>
                          <Controller
                            control={control}
                            rules={{
                              required: `Please enter ${child.label}`,
                            }}
                            name={`payroll_worker_allowance_extra_datas[${catIndex}].${child.name}`}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <Fragment>
                                <Input
                                  className="px-2 mt-2"
                                  onChange={({ target: { value } }) =>
                                    onChange(value)
                                  }
                                  value={value}
                                />
                                <InputError message={error?.message || ""} />
                              </Fragment>
                            )}
                          />
                        </span>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
              {/* <Button
                className="mr-8 ml-auto flex justify-center items-center py-1 px-2 border border-[#111111] h-[34px] my-5  "
                onClick={() => {
                  let cat = { ...defaultCat };
                  workerAppend(cat);
                }}
              >
                Add Allowance Tier
                <div className="bg-orange-500 p-1 rounded-md text-white ml-3">
                  <FaPlus />
                </div>
              </Button> */}
            </div>

            <button
              className="ml-auto my-8 mr-2 bg-[#0F4C7D] text-white px-8 py-3 rounded-lg"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <ButtonSpinner className="mx-8" />
              ) : (
                "Save Changes"
              )}
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

export default EditWorkerAllowance;
