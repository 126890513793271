import { Collapse } from "antd";
import React from "react";
// import { FaAngleDown } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const SettingsSidebar = () => {
  const { Panel } = Collapse;
  const data = [
    {
      id: 1,
      isActive: true,
      name: "Tasks Settings",
      child: [
        {
          id: 1,
          name: "Categories ",
          path: "/admin/settings/categories",
        },
        {
          id: 2,
          name: "Hourly Rate Tasks",
          path: "/admin/settings/hourly-rate-tasks",
        },
      ],
    },
    {
      id: 2,
      isActive: true,
      name: "Induction",
      child: [
        {
          id: 1,
          name: "Disclaimer Configuration ",
          path: "/admin/settings/induction/disclaimer",
        },
        {
          id: 2,
          name: "Companies",
          path: "/admin/settings/induction/companies",
        },
        {
          id: 3,
          name: "Emails ",
          path: "/admin/settings/induction/emails/induction",
        },
      ],
    },
    {
      id: 3,
      isActive: true,
      name: "User Account",
      child: [
        {
          id: 1,
          name: "Administrators ",
          path: "/admin/settings/users/admin",
        },
      ],
    },
    {
      id: 4,
      isActive: true,
      name: "Payroll Settings",
      child: [
        {
          id: 1,
          name: "Agreements",
          path: "/admin/settings/payroll/agreements",
        },
        {
          id: 2,
          name: "Employment Status",
          path: "/admin/settings/payroll/employment-status",
        },
        {
          id: 3,
          name: "Absences",
          path: "/admin/settings/payroll/absences/1/1",
        },
        {
          id: 4,
          name: "Payroll Categories ",
          path: "/admin/settings/payroll/categories/1/1",
        },
        {
          id: 5,
          name: "Allowance",
          path: "/admin/settings/allowances/site-allowance",
        },
        // {
        //   id: 6,
        //   name: "Entitlements",
        //   path: "/admin/settings/entitlement/",
        // },
        // {
        //     id: 1,
        //     name: "Employer Expenses",
        //     path: "",
        // },
        {
          id: 10,
          name: "Archive",
          path: "/admin/settings/archives/payroll-category",
        },

        {
          id: 4,
          isActive: true,
          name: "Expenses",
          child: [
            {
              id: 1,
              name: "Employer Expenses",
              path: "/admin/settings/employer-expenses/Superannuation",
            },
            {
              id: 2,
              name: "Employee Expenses  ",
              path: "/admin/settings/employee-expenses/ExpensesFront",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      isActive: true,
      name: "QA Settings",
      child: [
        {
          id: 1,
          name: "QA Templates",
          path: "/admin/settings/quality-assurance/",
        },
      ],
    },
    {
      id: 3,
      isActive: true,
      name: "Employee Settings",
      child: [
        {
          id: 1,
          name: "Designation",
          path: "/admin/settings/employee-designations/",
        },
      ],
    },
  ];

  // const handleSubmenu = (id, e) => {
  //   const menuItems = document.querySelectorAll(".menu-item");
  //   menuItems.forEach((item) => {
  //     item.classList.remove("active");
  //   });
  //   const menu = document.querySelector(`#menu-${id}`);

  //   // close submenu on click menu
  //   if (menu.classList.contains("active")) {
  //     menu.classList.remove("active");
  //   }
  //   // open submenu on click menu
  //   else {
  //     menu.classList.add("active");
  //   }
  // };

  return (
    <div className="sidebar min-h-screen bg-[#F6FAFF]">
      <Collapse accordion className="submenu-sidebar">
        {data?.map((item, index) => (
          <Panel header={item.name} className="py-1 px-5" key={index}>
            <ul>
              {item.child.map((child, index) => {
                return child.child?.length > 0 ? (
                  <li className="childmenu-sidebar">
                    <Collapse className="submenu-sidebar" accordion>
                      <Panel header={child.name} className="px-1">
                        <ul>
                          {child.child?.map((childItem, index) => (
                            <li className="py-1 px-5" key={index}>
                              <NavLink
                                to={childItem.path}
                                className="flex justify-start items-center text-sm"
                              >
                                {childItem.icon}
                                {childItem.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </Panel>
                    </Collapse>
                  </li>
                ) : (
                  <li className="py-1 px-5" key={index}>
                    <NavLink
                      to={child.path}
                      className="flex justify-start items-center text-sm"
                    >
                      {child.icon}
                      {child.name}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </Panel>
        ))}
      </Collapse>
      {/* <ul>
        {data?.map((item, index) => (
          <li
            className={`py-3 px-8 menu-item`}
            id={`menu-${item.id}`}
            onClick={() => handleSubmenu(item.id)}
            key={index}
          >
            {item.child ? (
              <>
                <div className={`flex justify-between items-center`}>
                  <button className="flex justify-start items-center">
                    {item.name}
                  </button>
                  <FaAngleDown className="angle" />
                </div>
                <ul className={`mt-3 hidden`}>
                  {item.child.map((item, index) => (
                    <li className="py-1 px-5" key={index}>
                      <NavLink
                        to={item.path}
                        className="flex justify-start items-center text-sm"
                      >
                        {item.icon}
                        {item.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <NavLink
                to={item.path}
                className="flex justify-start items-center"
              >
                {item.icon}
                {item.name}
              </NavLink>
            )}
          </li>
        ))}
      </ul> */}
    </div>
  );
};

export default SettingsSidebar;
