import { Modal, Select } from "antd";
import React, { Fragment, memo, useCallback, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ButtonSpinner } from "../../../../../components/spinners";
import { PayRollService } from "../../../../../config/axiosUrl";
import { toast } from "react-toastify";

const WorkerAllowanceAssignModal = ({ open, onCancel, data, allowance }) => {
  const selectedWorkers = useMemo(() => {
    if (allowance) {
      return (
        allowance?.payroll_worker_allowance_worker_data?.map(
          (item) => item.worker_id
        ) || []
      );
    }
    return [];
  }, [allowance]);

  const { handleSubmit, control, setValue, reset } = useForm();

  const [worksites, setWorksites] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const getWorkSiteByWorker = useCallback((workerId) => {
    PayRollService.post("api/v1/setting/payroll/get-worksites-by-workerId", {
      workerId,
    }).then((res) => {
      setWorksites(res?.data?.data?.data || []);
    });
  }, []);

  const submit = useCallback(
    async (data) => {
      await new Promise((res) => {
        setSubmitting(true);
        res(true);
      });

      PayRollService.post(
        "api/v1/setting/payroll/create-worker-allowance-worker-data",
        { ...data, payroll_worker_allowance_id: allowance.id }
      )
        .then((res) => {
          setSubmitting(false);

          if (res?.data?.status) {
            onCancel("", res?.data?.data?.data || null);
            reset();
            toast.success(res?.data?.message);
          } else {
            toast.error(res?.data?.status);
          }
        })
        .catch((err) => {
          setSubmitting(false);
          toast.error("Failed to assign worker");
        });
    },
    [allowance]
  );

  return (
    <Modal
      open={open}
      footer={[]}
      onCancel={() => {
        onCancel();
        reset();
      }}
    >
      <h1 className="font-semibold text-xl">Available Workers</h1>
      <form onSubmit={handleSubmit(submit)}>
        <div className="flex flex-col gap-5 my-3">
          <span className="flex flex-col">
            <label>Select a Worker</label>
            <Controller
              name="workerId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value || undefined}
                  onSelect={(e) => {
                    onChange(e);
                    getWorkSiteByWorker(e);
                    setValue("worksiteId", "");
                  }}
                >
                  {(data || []).map((item) =>
                    selectedWorkers.includes(item.id) ? (
                      <Fragment key={item.id} />
                    ) : (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    )
                  )}
                </Select>
              )}
            />
          </span>
          <span className="flex flex-col">
            <label>Select Worksite</label>
            <Controller
              name="worksiteId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select value={value} onChange={onChange}>
                  {worksites.map((item) => (
                    <Select.Option
                      value={item.worksites.id}
                      key={item.worksites.id}
                    >
                      {item.worksites.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </span>

          <button
            className="mx-auto bg-[#0F4C7D] text-white px-8 py-2 rounded-lg"
            type="submit"
            disabled={submitting}
          >
            {submitting ? <ButtonSpinner className="mx-8" /> : "Save"}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default memo(WorkerAllowanceAssignModal);
