import React from "react";
import { PayRollService } from "../../../config/axiosUrl";
import { ApiAuthorizaion } from "../../../auth/appAuth";
import PayrollCategories from "../../../pages/Admin/Settings/Payroll/Categories/Categories";

const CreateEditPayroll = async (payroll) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      isSave: payroll?.isSave,
      id: payroll?.id,
      name: payroll?.name,
      employement_status_id: payroll?.employement_status_id,
      hourly_rate_name: payroll?.hourly_rate_name,
      hourly_rate: payroll?.hourly_rate,
      overtime_rate_name: payroll?.overtime_rate_name,
      overtime_rate: payroll?.overtime_rate,
      double_time_rate_name: payroll?.double_time_rate_name,
      double_time_rate: payroll?.double_time_rate,
      agreement_id: payroll?.agreement_id,
      is_cost_work_as_all_inclusive: payroll?.is_cost_work_as_all_inclusive,
    };

    let data = await PayRollService?.post(
      "/api/v1/payroll/setting/edit-create-payroll-categories?dev=3",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetPayrollCatagory = async (payroll) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      search: payroll?.search,
      worksite_id: payroll?.worksite_id,
    };

    let data = await PayRollService?.get(
      "/api/v1/payroll/setting/get-payroll-category-data",
      { headers: authorization?.headers, params: params }
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};
const GetPayrollCatagoryId = async (empId, worker_id) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    if (empId?.target === undefined) {
      if (empId.length > 0 && typeof empId === "object") {
        empId = empId.join(",");
      }
    } else {
      empId = "";
    }

    console.log(empId, empId?.target, typeof empId);

    let data = await PayRollService?.get(
      `/api/v1/payroll/setting/get-payroll-category-data?worker_id=${
        worker_id ? worker_id : ""
      }&employment_status_id=${empId ? empId : ""}`,
      { headers: authorization?.headers }
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetPayrollCategoryById = (payroll) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: payroll?.id,
    };

    let data = PayRollService?.post(
      "/payroll/setting/get-payroll-category-data-by-id",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const DeletePayrollCategory = async (payroll) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: payroll?.id,
    };

    let data = await PayRollService?.post(
      "/api/v1/payroll/setting/delete-payroll-category-data?dev=4",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error?.error;
  }
};

const AddPayrollCategoryAbsence = async (payroll) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: payroll?.id,
      isSave: payroll?.isSave,
      available_absences: payroll?.available_absences,
    };

    let data = await PayRollService?.post(
      "/api/v1/payroll/setting/create-edit-payroll-category-available-absences-data",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const DeletePayrollCategoryAbsence = async (payroll) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: payroll?.id,
    };

    let data = await PayRollService?.post(
      "/api/v1/payroll/setting/delete-payroll-category-available-absences-data?dev=4",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

export {
  CreateEditPayroll,
  GetPayrollCatagory,
  GetPayrollCategoryById,
  DeletePayrollCategory,
  AddPayrollCategoryAbsence,
  DeletePayrollCategoryAbsence,
  GetPayrollCatagoryId,
};
