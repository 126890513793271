import React from "react";
import moment from "moment";

const OverflowTable = ({ overview }) => {
  return (
    <table className="w-full">
      <thead className="bg-[#E8F3FF] text-center">
        <tr className="text-left">
          <th className=" px-4 py-3 ">
            <div className="flex items-center justify-center">Date</div>
          </th>
          <th className=" px-4 py-3 ">
            <div className="flex items-center justify-center">Shift Time</div>
          </th>
          <th className=" px-4 py-3 ">
            <div className="flex items-center justify-center">Worker</div>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {overview?.data?.workers?.map((item, index) => (
          <tr key={index} className="border-b border-[#F0F0F0]">
            <td className="px-4 py-3">
              <div className="flex items-center justify-center">{moment(item.created_at).format('ddd DD MMM,YY')}</div>
            </td>
            <td className="px-4 py-3">
              <div className="flex items-center justify-center">
                {item?.spent.toFixed(0)} Hours
              </div>
            </td>
            <td className="px-4 py-3">
              <div className="flex items-center justify-center">
                {item?.users[0]?.name}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default OverflowTable;
