import { Tabs } from "antd";
import ProductivityOrdersTable from "../../../../../../shared/DataTable/ProductivityOrders";
import ProductivityOrders from "../../../../../Admin/Worksite/WorksiteDetails/Tabpans/ProductivityOrders";
const onChange = (key) => {
  console.log(key);
};
const items = [
  {
    key: "1",
    label: "Total",
    children: <ProductivityOrders />,
  },
  {
    key: "2",
    label: "Approved",
    children: <ProductivityOrders />,
  },
  {
    key: "3",
    label: "Active",
    children: <ProductivityOrders />,
  },
  {
    key: "4",
    label: "Remaining",
    children: <ProductivityOrders />,
  },
];
const Productivity = ({area}) => (
  <div className="">
    <ProductivityOrders isTab={true} isarea={true} area={area}/>
    {/* <Tabs
      className="details-tab"
      defaultActiveKey="1"
      items={items}
      onChange={onChange}
    /> */}
  </div>
);
export default Productivity;
