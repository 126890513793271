import React from 'react'

const IssueIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.954" height="22.76" viewBox="0 0 25.954 22.76" className='mr-2'>
      <g id="Group_74914" data-name="Group 74914" transform="translate(10541.283 7055.25)">
        <g id="Group_74914-2" data-name="Group 74914" transform="translate(-10843.997 -7668.995)">
          <text id="_" data-name="!" transform="translate(313.786 630.962)" fill="#f91c1c" fontSize="14" fontFamily="Lexend-Regular, Lexend"><tspan x="0" y="0">!</tspan></text>
          <g id="Page-1" transform="translate(303.997 614.995)">
            <g id="Artboard" transform="translate(0 0)">
              <g id="triangle" transform="translate(0 0)">
                <path id="Shape" d="M10.331,1.975.852,17.8a2.238,2.238,0,0,0,1.914,3.357H21.724A2.238,2.238,0,0,0,23.638,17.8L14.159,1.975a2.238,2.238,0,0,0-3.828,0Z" transform="translate(-0.552 -0.897)" fill="none" stroke="#f91c1c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" fillRule="evenodd" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
export default IssueIcon
