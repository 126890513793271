import React, { createContext, useEffect, useState } from "react";
import { RouterProvider, Navigate } from "react-router-dom";
import router from "../utils/routes/Main.routes";
import { useContext } from "react";
import PaginationHelper from "../components/PaginationAPI/PaginationHelper";

export const AppContext = createContext();

const pagination = new PaginationHelper();

export function usePaginationMemory() {
  return pagination;
}

const Main = () => {
  const [data, setData] = useState();

  return (
    <AppContext.Provider value={[data, setData]}>
      <RouterProvider router={router} />
    </AppContext.Provider>
  );
};

export default Main;
