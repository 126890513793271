import React, { useState, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import EditProfileModal from "../Modal/EditProfileModal";
import { UserService } from "../../config/axiosUrl";
import { NoSearchTableSvg } from "../../assets/svg/tableDefaul";
import { GetWorkersById, GetWorkerstab } from "../../helper/personnel/workers";
import { SearchData } from "../../helper/global/global";
import { TextShimmer } from "../../components/shimmer/shimmer";
import { NewTablePagination } from "../../components/pagination/newPagination";
import { NoTableOption } from "../../components/noOption/noOption";
import { Fileurl } from "../../config/url";
import DefaultCree8Img from "../../assets/images/Default/defaulCree8.png";
import { ButtonSpinner } from "../../components/spinners";
import HeaderSearch from "../../layouts/HeaderSearch";
import LogOutDetails from "../../layouts/LogOutDetails";
import { Authuser } from "../../auth/appAuth";

const SiteManagerWorkersTable = ({ hasStatus }) => {
  const initialvalues = {
    type: "ALL",
    data: [],
    selectedData: {},
    extra: {},
    pagination: {
      service: UserService,
      api: `/api/v1/personal/workers/get-lists?type=ALL`,
      route: "/admin/personnel/workers",
      search: "",
      cursor: "",
      precursor: "",
    },
    isTabLoading: false,
    isLoading: false,
    isRowLoading: false,
    rowIndex: "",
    isShimmer: true,
  };
  const userInitialvalue = {
    id: "",
    name: "",
    email: "",
    employee_id: "",
    mobile: "",
    role: "",
    verified: "",
    last_login: "",
    status: "",
  };

  const [workers, setWorkers] = useState(initialvalues);
  const [searchValue, setSearchValue] = useState("");
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [editId, setEditId] = useState("");
  const [user, setUser] = useState(userInitialvalue);
  const [workerType, setWorkerType] = useState("ALL");

  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };
  const img = {
    display: "block",
    width: 60,
    height: 60,
    borderRadius: "10px",
    margin: "auto",
  };
  const handleWorkers = {
    getWorker: async (value) => {
      setWorkers({
        ...workers,
        pagination: {
          ...workers?.pagination,
        },
      });

      let data = await GetWorkerstab({ ...workers, type: workerType });
      if (data?.status) {
        setWorkers({
          ...workers,
          pagination: {
            ...workers?.pagination,
          },
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          isShimmer: false,
          isTabLoading: false,
        });
      }
    },
    search: async (e) => {
      setSearchValue(e?.target?.value);
      const params = {
        value: e?.target?.value,
        api: "/api/v1/personal/workers/get-lists?type=ALL",
        service: UserService,
      };
      let data = await SearchData(params);
      if (data?.status) {
        setWorkers({
          ...workers,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          pagination: {
            ...workers?.pagination,
            search: e?.target?.value,
          },
        });
      }
    },
    edit: async (item, index) => {
      setIsEditLoading(true);
      setEditId(item?.id);
      const params = {
        id: item?.id,
      };
      let data = await GetWorkersById(params);
      setVisibleEdit(true);
      if (data?.status) {
        setWorkers({
          ...workers,
          ...data?.data?.data,
          selectedData: { ...item, index: index },
        });
      }
      setIsEditLoading(false);
      setEditId("");
    },
  };
  const handleChange = (e) => {
    setWorkerType(e.target.value);
  };
  useEffect(() => {
    let data = Authuser();
    setUser({
      ...data,
    });
    (async () => {
      await handleWorkers?.getWorker();
    })();
  }, [workerType]);
  return (
    <>
      <div className="flex justify-between items-center mb-[20px] header-flex">
        <p className="text-[24px] w-full">Workers</p>
        <div className="flex justify-end items-center w-full">
          <HeaderSearch
            placeHolder={"Search by Workers Name"}
            handle={handleWorkers?.search}
          />
          <LogOutDetails user={user} />
        </div>
      </div>
      {visibleEdit && (
        <EditProfileModal
          setModalVisible={setVisibleEdit}
          worker={workers}
          setWorker={setWorkers}
          handleWorkers={handleWorkers?.getWorker}
        />
      )}
      {workers?.isShimmer ? (
        <div className="bg-white px-4 py-3">
          <TextShimmer data={{ gap: 15, line: 20 }} />
        </div>
      ) : (
        <>
          <div className="border bg-white py-1 px-3 gap-2 flex rounded justify-between items-center w-[180px] mb-3">
            <span className="text-xs">Status:</span>
            <select
              className="border-none outline-none bg-transparent p-0 ml-3"
              onChange={handleChange}
            >
              <option value="ALL">All</option>
              <option value={0}>Terminated</option>
              <option value={1}>Active</option>
            </select>
          </div>
          <table className="table-auto w-full">
            <thead className="bg-[#E8F3FF]">
              <tr>
                <th className="px-4 py-3"></th>
                <th className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    Worker Name
                  </div>
                </th>
                <th className="px-4 py-3">
                  <div className="flex items-center justify-center">Email</div>
                </th>

                <th className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    Card Id
                  </div>
                </th>
                <th className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    Status Type
                  </div>
                </th>

                <th className="px-4 py-3">
                  <div className="flex items-center justify-center">Action</div>
                </th>
              </tr>
            </thead>
            {workers?.data?.length !== 0 &&
              workers?.data?.map((item, index) => (
                <tbody className="bg-white" key={index}>
                  <tr className="border-b border-[#F0F0F0]">
                    <td>
                      <img
                        src={
                          item?.users_profile_details?.data
                            ? Fileurl + item?.users_profile_details?.data
                            : DefaultCree8Img
                        }
                        style={img}
                      />
                    </td>
                    <td className="px-4 py-3">
                      <Link
                        to={`/site-manager/workers/1/details/${item?.id}`}
                        className="flex items-center justify-center"
                      >
                        {item.name}
                      </Link>
                    </td>
                    <td className="px-4 py-3">
                      <div className="flex items-center justify-center">
                        {item.email}
                      </div>
                    </td>

                    <td className="px-4 py-3">
                      <div className="flex items-center justify-center">
                        {item.employee_id}
                      </div>
                    </td>
                    <td className="px-4 py-3">
                      <div className="flex items-center justify-center">
                        {item.status == 1 ? "Active" : "Terminated"}
                      </div>
                    </td>

                    <td className="px-4 py-3">
                      <div className="flex items-center justify-center">
                        <button
                          className="mx-2"
                          onClick={() => handleWorkers?.edit(item, index)}
                        >
                          {item?.id === editId && isEditLoading ? (
                            <ButtonSpinner />
                          ) : (
                            <FaEdit />
                          )}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>
        </>
      )}
      {workers?.data?.length <= 0 && workers?.pagination?.search && (
        <>
          <NoTableOption data={noSearchOption} />
        </>
      )}
      {workers?.data?.length > 0 && (
        <NewTablePagination
          data={workers}
          setData={setWorkers}
          isMobile={true}
        />
      )}
    </>
  );
};

export default SiteManagerWorkersTable;
