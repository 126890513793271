import { Button } from "antd";
import { async } from "q";
import React, { useEffect, useState } from "react";
import { UserService } from "../../config/axiosUrl";
import { PaginationData } from "../../helper/global/global";
import { ButtonSpinner } from "../spinners";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const TablePagination = ({ data, setData, setCursorValue }) => {
  let navigate = useNavigate();
  let { page } = useParams();
  let { cursor } = useParams();

  // Use parseInt with a default value of 1 if page is undefined or not a number
  const pageNumber = parseInt(page, 10) || 1;
  // Use parseInt with a default value of 1 if cursor is undefined or not a number
  const cursorNumber = parseInt(cursor, 10) || 1;

  const initialvalue = {
    dataPerpage: 10,
    totalData: data?.extra?.totalItems,
    showingStart: data?.pagination?.route ? (pageNumber - 1) * 10 + 1 : 1,
    showingEnd: data?.pagination?.route ? pageNumber * 10 : 0,
    previousPage: data?.pagination?.route ? pageNumber : 1,
    search: data?.pagination?.search,
    nextPage: data?.extra?.pageCounts,
    cursor: data?.extra?.cursor,
    previous_cursor: data?.extra?.previous_cursor,
    api: data?.pagination?.api,
    service: data?.pagination?.service,
    isLoadingnext: false,
    isLoadingprevious: false,
  };

  const [pagination, setPagination] = useState(initialvalue);
  console.log("the pagination is", initialvalue);
  const handlePagination = {
    nextPage: async () => {
      setPagination({
        ...pagination,
        isLoadingnext: true,
      });

      const params = {
        cursor: data?.extra?.cursor,
        api: pagination?.api,
        service: pagination?.service,
        search: data?.pagination?.search,
      };

      console.log("data is", data?.pagination?.api);
      let getData = await PaginationData(params);

      if (getData?.status) {
        setData({
          ...data,
          data: [...getData?.data?.data],
          extra: { ...getData?.extra },
        });
        setPagination({
          ...pagination,
          previousPage: pagination?.previousPage + 1,
          showingStart: pagination?.showingStart + pagination?.dataPerpage,
          showingEnd: pagination?.showingEnd + pagination?.dataPerpage,
          isLoadingnext: false,
        });
        if (data?.pagination?.route) {
          console.log(
            "nextttt 2",
            data?.extra?.cursor,
            data?.pagination?.route
          );
          navigate(
            data?.pagination?.route +
              `/${pagination?.previousPage + 1}/${data?.extra?.cursor}`
          );
        }
      } else {
        setPagination({
          ...pagination,
          isLoadingnext: false,
        });
      }
    },
    previousPage: async () => {
      setPagination({
        ...pagination,
        isLoadingprevious: true,
      });
      const params = {
        cursor: data?.extra?.previous_cursor,
        api: pagination?.api,
        service: pagination?.service,
        previous: pagination?.previousPage,
        search: data?.pagination?.search,
        type: data?.pagination?.type,
      };
      let getData = await PaginationData(params);
      if (getData?.status) {
        setData({
          ...data,
          data: [...getData?.data?.data],
          extra: { ...getData?.extra },
        });
        setPagination({
          ...pagination,
          previousPage: pagination?.previousPage - 1,
          showingStart: pagination?.showingStart - pagination?.dataPerpage,
          showingEnd: pagination?.showingEnd - getData?.data?.data?.length,
          isLoadingprevious: false,
        });
        if (data?.pagination?.route) {
          // console.log("cursor", data?.extra?.cursor);
          let pram =
            pagination?.previousPage - 1 == 1
              ? `/1/${pagination?.previousPage - 1}`
              : `/${pagination?.previousPage - 1}/${
                  data?.extra?.previous_cursor
                }`;
          navigate(data?.pagination?.route + pram);
        }
      } else {
        setPagination({
          ...pagination,
          isLoadingprevious: false,
        });
      }
    },
  };

  useEffect(() => {
    if (data?.pagination?.search && data?.pagination?.route) {
      setPagination({
        ...pagination,
        showingStart: 1,
        showingEnd: 10,
        previousPage: 1,
      });
      navigate(`${data?.pagination?.route}/1/1`);
    }
  }, [data?.extra?.totalItems]);

  return (
    <div className="flex p-2 justify-between w-full">
      <div className="text-slate-600">
        Showing : {pagination?.showingStart}-{pagination?.showingEnd} results of{" "}
        {data?.extra?.totalItems}
      </div>
      <div>
        <div>
          <Button
            className="border-0 no-color-disable"
            disabled={pagination?.previousPage <= 1 ? true : false}
            onClick={handlePagination?.previousPage}
          >
            {pagination?.isLoadingprevious ? (
              <div className="px-3">
                <ButtonSpinner />
              </div>
            ) : (
              <div className="text-base"> {"< "} Previous | &nbsp;</div>
            )}
          </Button>

          <span className="px-1">
            {pagination?.previousPage} &nbsp; out of &nbsp;{" "}
            {data?.extra?.pageCounts}
          </span>

          <Button
            className="border-0 no-color-disable"
            disabled={
              pagination?.previousPage == data?.extra?.pageCounts ? true : false
            }
            onClick={handlePagination?.nextPage}
          >
            {pagination?.isLoadingnext ? (
              <div className="px-3">
                <ButtonSpinner />
              </div>
            ) : (
              <div className="text-base"> | Next {" >"}</div>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { TablePagination };
