import { Tabs } from "antd";
import Companies from "./FilterTabContents/Companies";
import Worksite from "./FilterTabContents/WorkSite";
import Area from "./FilterTabContents/Area";

const onChange = (key) => {
  console.log(key);
};

const FilterTabs = ({ filterWorkers, filter }) => (
  <Tabs
    className=" mt-5 border border-[#E8E8E8] bg-white p-20 pb-0"
    defaultActiveKey="1"
    items={[
      {
        key: "1",
        label: `Companies`,
        children: (
          <Companies filterWorkers={filterWorkers} filter={filter.Companies} />
        ),
      },
      {
        key: "2",
        label: `Worksite`,
        children: (
          <Worksite filterWorkers={filterWorkers} filter={filter.Worksite} />
        ),
      },
      {
        key: "3",
        label: `Area`,
        children: <Area filterWorkers={filterWorkers} filter={filter.Areas} />,
      },
    ]}
    onChange={onChange}
  />
);

export default FilterTabs;
