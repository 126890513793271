import { Input, Modal } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import InputError from "../../../../../components/InputError";
import { ButtonSpinner } from "../../../../../components/spinners";

const CreateWorksiteAllowence = ({
  open,
  toggleModal,
  onSubmit,
  isSubmitting,
  handleSubmit,
  control,
}) => {
  return (
    <Modal onCancel={toggleModal} open={open} footer={[]} centered>
      <h2 className="text-xl font-bold">Create New Worker Allowance</h2>

      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          rules={{
            required: "Please enter name",
          }}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className="item mt-5">
              <label htmlFor="name">Allowance Name</label>
              <Input
                type="text"
                className={
                  error
                    ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "mt-3"
                }
                name="name"
                id="name"
                onChange={onChange}
                value={value}
              />
              <InputError message={error?.message} />
            </div>
          )}
        />

        <div className="item mt-5 text-center">
          <button
            className="btn btn-blue px-6 py-2 rounded-lg ring ring-[#0f4c7d] hover:ring-offset-1 min-w-[3rem]"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <ButtonSpinner /> : "Save Changes"}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateWorksiteAllowence;
