import Main from "./layouts/Main";
import "./assets/styles/styles.css";
import "react-toastify/dist/ReactToastify.css";
import Notify from "./components/notification/notification";
import { setAuthorization } from "./auth/appAuth";

function App() {
  setAuthorization();
  return (
    <div className="App">
      <Main />
      <Notify />
    </div>
  );
}

export default App;
