import { Button, DatePicker, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
import WorksiteCards from "./WorksiteCards";
import { Link, useParams } from "react-router-dom";
import {
  ChangeWorksiteStatus,
  GetWorkSite,
} from "../../../helper/worksite/worksite";
import { TextShimmer } from "../../../components/shimmer/shimmer";
import { toast } from "react-toastify";
import { TableDefaultPage } from "../../../components/default/defaultPage";
import {
  NoSearchTableSvg,
  TableDefaultSvg,
} from "../../../assets/svg/tableDefaul";
import { NoTableOption } from "../../../components/noOption/noOption";

const Worksite = () => {
  let { id } = useParams();

  const initialValues = {
    data: [],
    isStatusLoading: false,
    extra: {},
    type: "all",
    date: null,
    search: "",
    isLoadingId: "",
    isLoading: false,
    isFetchLoader: false,
    isShimmer: true,
    date: "",
  };

  const [worksite, setWorksite] = useState(initialValues);

  const options = [
    {
      value: "all",
      label: "All Worksites",
    },
    {
      value: "1",
      label: "Active",
    },
    {
      value: "0",
      label: "Archived",
    },
  ];

  const handleWorksite = {
    changeStatus: async (checked, id) => {
      console.log(checked, id);
      setWorksite({
        ...worksite,
        isStatusLoading: true,
        isLoadingId: id,
      });
      const params = {
        id: id,
      };
      let data = await ChangeWorksiteStatus(params);
      if (data?.status) {
        let getData = await GetWorkSite(worksite);
        if (getData?.status) {
          setWorksite({
            ...worksite,
            data: [...getData?.data?.data],
            isFetchLoader: true,
            extra: getData?.extra,
            isLoadingId: "",
          });
          toast?.success(data?.message);
        }
      } else {
        toast?.error(data?.message);
      }
    },
    fetch: async (e) => {
      setWorksite({
        ...worksite,
        isFetchLoader: true,
      });
      if (
        Math.floor(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) -
          e.currentTarget.clientHeight -
          1 <
        2
      ) {
        console.log("fetch");
        const params = {
          cursor: worksite?.extra?.cursor,
          search: worksite?.search,
          type: worksite?.type,
          date: worksite?.date,
        };

        if (worksite?.extra?.totalItems != worksite?.data?.length) {
          let data = await GetWorkSite(params);
          if (data?.status) {
            let alldata = [...worksite?.data];

            if (data?.data?.data?.length > 0) {
              data?.data?.data?.forEach((site, index) => {
                alldata?.push({ ...site });
              });

              setWorksite({
                ...worksite,
                data: alldata,
                isFetchLoader: true,
                extra: data?.extra,
              });
            }
          } else {
            setWorksite({
              ...worksite,
              isFetchLoader: false,
            });
          }
        } else {
          setWorksite({
            ...worksite,
            isFetchLoader: false,
          });
        }
      }
    },
    date: async (date, dateString) => {
      // console.log(date?.$d, dateString);

      let data = await handleWorksite?.allWorksite({
        ...worksite,
        date: dateString,
      });
    },
    type: async (e) => {
      let data = await handleWorksite?.allWorksite({ ...worksite, type: e });
    },
    search: async (e) => {
      let data = await handleWorksite?.allWorksite({
        ...worksite,
        search: e?.target?.value,
      });
    },
    allWorksite: async (worksiteData) => {
      let data = await GetWorkSite(worksiteData);
      if (data?.status) {
        setWorksite({
          ...worksite,
          data: [...data?.data?.data],
          extra: data?.extra,
          search: worksiteData?.search,
          date: worksiteData?.date,
          type: worksiteData?.type,
          isShimmer: false,
          isFetchLoader: false,
          cursor: "",
        });
        return data;
      } else {
        setWorksite({
          ...worksite,
          isShimmer: false,
        });
        return data;
      }
    },
  };

  let deafaulPage = {
    disclaimer: "No Worksite Found",
    description: "Create Your Worksite",
    buttonText: "Create New",
    svg: <TableDefaultSvg />,
    redirect: "/admin/worksite/create",
    data: worksite,
    setData: setWorksite,
    handle: handleWorksite,
  };

  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };

  useEffect(() => {
    (async () => {
      let data = await handleWorksite?.allWorksite();
    })();
  }, []);

  return (
    <div className="py-14 px-14 " id="test">
      {worksite?.isShimmer ? (
        <div className="bg-white p-4 rounded-lg">
          <TextShimmer data={{ gap: 15, line: 19 }} />
        </div>
      ) : (
        <>
          {worksite?.data?.length <= 0 &&
          !worksite?.search &&
          !worksite?.date ? (
            <>
              <div className="h-[75vh]">
                <TableDefaultPage data={deafaulPage} />
              </div>
            </>
          ) : (
            <>
              <div>
                <div className="top flex justify-between items-center pb-3">
                  <div className="flex">
                    <div className="">
                      <Select
                        defaultValue="All Worksites"
                        className="select-height-42"
                        onChange={handleWorksite?.type}
                        // value={worksite?.type}
                        options={options}
                      />
                    </div>
                    <div className="">
                      {/* <div>
                        <div className="pl-2">
                          <DatePicker
                            className="h-11"
                            onChange={handleWorksite?.date}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="right flex justify-end items-center">
                    <div className=" w-60">
                      <DatePicker
                        className="h-11 mr-3 "
                        onChange={handleWorksite?.date}
                      />
                    </div>
                    <div className="border flex items-center p-1 rounded-md w-full">
                      <FaSearch className="ml-2" />{" "}
                      <input
                        placeholder="Search by Worksite, Job Number "
                        className="h-8 bg-transparent text-sm w-full px-2"
                        // value={worksite?.search}
                        onChange={handleWorksite?.search}
                      />
                    </div>
                    <Button className="flex justify-center items-center  border border-[#111111] h-[40px] p-0 ml-3">
                      <Link
                        to={"/admin/worksite/create"}
                        className="flex justify-between items-center h-full w-full px-4"
                      >
                        Create New{" "}
                        <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                          <FaPlus />
                        </div>
                      </Link>
                    </Button>
                  </div>
                </div>

                {worksite?.date && (
                  <div className="data-card pt-3  my-5">
                    <p>
                      <span className="text-gray-500">Showing Data of : </span>
                      {worksite?.date}
                    </p>
                  </div>
                )}

                {(worksite?.data <= 0 && worksite?.search) ||
                (worksite?.data?.length <= 0 && worksite?.date) ? (
                  <div className="pt-4">
                    <NoTableOption data={noSearchOption} />
                  </div>
                ) : (
                  <div className="pt-4">
                    <WorksiteCards
                      data={worksite}
                      setData={setWorksite}
                      handle={handleWorksite}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Worksite;
