import React from "react";
import { PayRollService } from "../../../config/axiosUrl";
import { ApiAuthorizaion } from "../../../auth/appAuth";

const CreateEditAbsences = async (absences) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      name: absences?.name,
      absence_accrued_name: absences?.absence_accrued_name,
      absence_taken_name: absences?.absence_taken_name,
      default_duration: absences?.default_duration,
      accrual_method: absences?.accrual_method,
      required_weekday_hours: absences?.required_weekday_hours,
      required_weekend_hours: absences?.required_weekend_hours,
      daily_accrual: absences?.daily_accrual,
      summary_column: absences?.summary_column,
      export_taken: absences?.export_taken,
      export_accrued: absences?.export_accrued,
      track_as_entitlement: absences?.track_as_entitlement,
      exclude_from_shift_cost: absences?.exclude_from_shift_cost,
      accrue_when_absent: absences?.accrue_when_absent,
      export_without_job_number: absences?.export_without_job_number,
      isSave: absences?.isSave,
      id: absences?.id,
      type: absences?.type,
    };

    let data = await PayRollService?.post(
      "/api/v1/setting/payroll/edit-create-absences?dev=4",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetAbsences = async (absences) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      cursor: absences?.cursor,
    };

    let data = await PayRollService?.get(
      "/api/v1/setting/payroll/get-absences-data",
      {
        headers: authorization?.headers,
        params: params,
      }
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const DeleteAbsences = async (absences) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: absences?.id,
    };

    let data = await PayRollService?.post(
      "/api/v1//setting/payroll/delete-absences-data?dev=5",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetAbsencesById = async (absences) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: absences?.id,
    };

    let data = await PayRollService?.post(
      "/api/v1/payroll/setting/get-absences-data-id?dev=4",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

export { CreateEditAbsences, GetAbsences, DeleteAbsences, GetAbsencesById };
