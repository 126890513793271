import React, { useEffect, useState } from "react";
import { BarChart, Bar, ResponsiveContainer, XAxis, Tooltip } from "recharts";

const WeeklyBar = ({ timesheet }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // You can perform any data processing or manipulation here
    setChartData(timesheet?.worksite_timesheet_data);
  }, [timesheet]);

  const data = chartData?.map((item) => ({
    name: item.dayName.slice(0, 3),
    Val: item.spentSumDaywise,
  }));
  // console.log("the da retrived data", data);
  return (
    <ResponsiveContainer className={"weekBar"} width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 15,
        }}
      >
        <XAxis
          dataKey="name"
          textAnchor="end"
          claeToFit="true"
          verticalAnchor="start"
          interval={0}
          angle="-90"
          stroke="#969292"
        />
        <Tooltip />
        <Bar dataKey="Val" barSize={16} fill="#121111" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default WeeklyBar;
