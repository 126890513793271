import React, {
  useEffect,
  useState,
  useContext,
  Fragment,
  useCallback,
} from "react";
import { GetEmployeeStatus } from "../../../../../helper/setup/payroll/employeentstatus";
import {
  AddPayrollCategoryAbsence,
  DeletePayrollCategory,
  DeletePayrollCategoryAbsence,
  GetPayrollCatagory,
} from "../../../../../helper/setup/payroll/payrollcatagory";
import PayrollCategoriesTable from "../../../../../shared/DataTable/PayrollCategoriesTable";
import { toast } from "react-toastify";
import { CreateAdminContext } from "../../../../../layouts/SettingsLayout";
import { GetAbsences } from "../../../../../helper/setup/payroll/absences";
import { PayRollService } from "../../../../../config/axiosUrl";
import { SearchData } from "../../../../../helper/global/global";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import { NoTableOption } from "../../../../../components/noOption/noOption";
import { TablePagination } from "../../../../../components/pagination/pagination";
import {
  NoSearchTableSvg,
  TableDefaultSvg,
} from "../../../../../assets/svg/tableDefaul";
import { FaSearch } from "react-icons/fa";
import { GetPage } from "../../../../../helper/global/global";
import { useParams } from "react-router-dom";
import { TableDefaultPage } from "../../../../../components/default/defaultPage";
import Confirmation from "../../../../../components/Confirmation";

const PayrollCategories = () => {
  const [createAdmincontext, setcreateAdminContext] =
    useContext(CreateAdminContext);
  let { page } = useParams();
  let { cursor } = useParams();

  const initialvalues = {
    data: [],
    extra: {},
    selectedData: {},
    absences: [],
    isLoading: false,
    isAbsencesShimmer: true,
    isShimmer: true,
    pagination: {
      api: "/api/v1/payroll/setting/get-payroll-category-data",
      service: PayRollService,
      route: "/admin/settings/payroll/categories",
      search: "",
    },
  };
  const [onArchive, setArchive] = useState({
    visible: false,
    data: {},
    isSubmitting: false,
    reget: false,
  });

  const [payrollCatagory, setPayrollCatagory] = useState(initialvalues);
  const [CreatePayrollCategory, setCreatePayrollCategory] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [addNewAbsence, setAddNewAbsence] = React.useState(false);

  let deafaulPage = {
    disclaimer: "No Payroll Category Found",
    description: "Create Your First Payroll Category",
    buttonText: "Create New",
    svg: <TableDefaultSvg />,
    redirect: "",
  };

  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };

  const handlePayroll = {
    get: async (e) => {
      const params = {
        cursor: cursor != 1 ? cursor : "",
        api: "/api/v1/payroll/setting/get-payroll-category-data",
        service: PayRollService,
      };
      let data = await GetPage(params);
      return data;
    },
    search: async (e) => {
      const params = {
        value: e?.target?.value,
        api: "/api/v1/payroll/setting/get-payroll-category-data",
        service: PayRollService,
      };
      let data = await SearchData(params);
      if (data?.status) {
        setPayrollCatagory({
          ...payrollCatagory,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          pagination: {
            ...payrollCatagory?.pagination,
            search: e?.target?.value,
          },
        });
      }
    },
    delete: async (type) => {
      setPayrollCatagory({
        ...payrollCatagory,
        isLoading: true,
      });
      const params = {
        id: payrollCatagory?.selectedData?.id,
      };
      let data;
      if (type) {
        data = await DeletePayrollCategory(params);
      } else {
        data = await DeletePayrollCategoryAbsence(params);
      }

      if (data?.status) {
        setcreateAdminContext(true);
        setPayrollCatagory({
          ...payrollCatagory,
          selectedData: {},
          isLoading: false,
        });
        setVisibleDelete(false);
        toast?.success(data?.message);
      } else {
        toast?.error(data?.message);
        setPayrollCatagory({
          ...payrollCatagory,
          isLoading: false,
        });
      }
    },
    getAbsences: async (e) => {
      const params = {
        value: e?.target?.value,
        service: PayRollService,
        api: "/api/v1/setting/payroll/get-absences-data",
      };

      let data = await SearchData(params);
      if (data?.status) {
        setPayrollCatagory({
          ...payrollCatagory,
          absences: [...data?.data?.data],
        });
      }
    },
    check: (e, item) => {
      if (e?.target?.checked) {
        let allAvailableAbsences = [
          ...payrollCatagory?.selectedData?.available_absences,
        ];
        allAvailableAbsences?.push(item?.id);

        setPayrollCatagory({
          ...payrollCatagory,
          selectedData: {
            ...payrollCatagory?.selectedData,
            available_absences: allAvailableAbsences,
          },
        });
      } else {
        let allAvailableAbsences = [
          ...payrollCatagory?.selectedData?.available_absences,
        ];

        let removeAbsence = allAvailableAbsences?.indexOf(item?.id);
        allAvailableAbsences?.splice(parseInt(removeAbsence), 1);

        setPayrollCatagory({
          ...payrollCatagory,
          selectedData: {
            ...payrollCatagory?.selectedData,
            available_absences: allAvailableAbsences,
          },
        });
      }
    },
    addAbsence: async () => {
      setPayrollCatagory({
        ...payrollCatagory,
        isLoading: true,
      });
      let data = await AddPayrollCategoryAbsence(payrollCatagory?.selectedData);
      if (data?.status) {
        setcreateAdminContext(true);
        setPayrollCatagory({
          ...payrollCatagory,
          selectedData: {},
          isLoading: false,
        });
        setAddNewAbsence(false);
        toast?.success(data?.message);
      } else {
        setPayrollCatagory({
          ...payrollCatagory,
          isLoading: false,
        });
        toast?.error(data?.message);
      }
    },
  };

  useEffect(() => {
    (async () => {
      let data = await handlePayroll?.get();
      if (data?.status) {
        setPayrollCatagory({
          ...payrollCatagory,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          isShimmer: false,
        });
        if (setcreateAdminContext) {
          setcreateAdminContext(false);
        }
      }
    })();
  }, [createAdmincontext, onArchive.reget]);

  const archivePayrollCategory = useCallback(async () => {
    await new Promise((resolve) => {
      setArchive((prev) => ({
        ...prev,
        isSubmitting: true,
      }));
      resolve(true);
    });
    PayRollService.post(
      "/api/v1/payroll/setting/archive-payroll-category-by-id",
      { id: onArchive.data?.id || "" }
    )
      .then((res) => {
        if (res?.data?.status) {
          setArchive((prev) => ({
            ...prev,
            isSubmitting: false,
            visible: false,
            data: {},
            reget: !prev.reget,
          }));
          toast.success(res?.data?.message);
        } else {
          setArchive((prev) => ({
            ...prev,
            isSubmitting: false,
          }));
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        setArchive((prev) => ({
          ...prev,
          isSubmitting: false,
        }));
        toast.error("Failed to Archive Payroll Category");
      });
  }, [onArchive]);

  const onArchiveClick = useCallback((data) => {
    setArchive((prev) => ({
      ...prev,
      visible: !!data,
      data: data || {},
    }));
  }, []);

  return (
    <div className="p-6">
      <Confirmation
        open={onArchive.visible}
        title={`Are you sure want to archive this payroll cateogry`}
        details={onArchive.data?.name}
        onConfirm={archivePayrollCategory}
        isSubmitting={onArchive.isSubmitting}
        onCancel={() => onArchiveClick()}
        buttonText="Archive"
      />
      {payrollCatagory?.isShimmer ? (
        <>
          <div className="bg-white px-4 py-3">
            <TextShimmer data={{ gap: 15, line: 20 }} />
          </div>
        </>
      ) : (
        <>
          <div>
            {payrollCatagory?.data?.length <= 0 &&
            !payrollCatagory?.pagination?.search ? (
              <TableDefaultPage data={deafaulPage} />
            ) : (
              <Fragment>
                <div className="bg-white py-4 px-4">
                  <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/4">
                    <FaSearch className="ml-2" />{" "}
                    <input
                      type="text"
                      placeholder="Search by Payroll Category"
                      className="h-[31px] bg-transparent text-sm w-full px-2"
                      key="1"
                      name="search"
                      onChange={handlePayroll?.search}
                    />
                  </div>
                </div>

                <PayrollCategoriesTable
                  data={payrollCatagory}
                  setData={setPayrollCatagory}
                  handle={handlePayroll}
                  CreatePayrollCategory={CreatePayrollCategory}
                  setCreatePayrollCategory={setCreatePayrollCategory}
                  visibleDelete={visibleDelete}
                  setVisibleDelete={setVisibleDelete}
                  addNewAbsence={addNewAbsence}
                  setAddNewAbsence={setAddNewAbsence}
                  onArchiveClick={onArchiveClick}
                />

                <div>
                  {payrollCatagory?.data?.length <= 0 &&
                  payrollCatagory?.pagination?.search ? (
                    <>
                      <NoTableOption data={noSearchOption} />
                    </>
                  ) : (
                    <>
                      <div className="bg-white">
                        <TablePagination
                          data={payrollCatagory}
                          setData={setPayrollCatagory}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Fragment>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PayrollCategories;
