import { SettingService } from "../../../config/axiosUrl";
import { ApiAuthorizaion } from "../../../auth/appAuth";

const CreateEditTerminationEmail = async (email) => {
  try {
    // const authorization = {
    //   headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     "Content-type": "Application/json",
    //     Authorization: ApiAuthorizaion(),
    //   },
    // };

    const params = {
      id: email?.id,
      isSave: email?.isSave,
      subject: email?.subject,
      send_to_copy: email?.send_to_copy,
      body: email?.body,
    };

    let data = await SettingService?.post(
      "/api/v1/setting/email/create-termination-email",
      params
    );
    return data?.data;
  } catch (errror) {
    return errror;
  }
};

const GetEmail = async (email) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    let data = await SettingService?.get(
      "setting/email/get-email-template?type=termination",
      {
        headers: authorization?.headers,
      }
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const DeleteEmail = async (email) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: email?.id,
    };

    let data = await SettingService?.post(
      "/api/v1/setting/email/delete-Termination-email-template",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetTermimationEmailById = async (email) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: email?.id,
    };

    let data = await SettingService?.post(
      "/api/v1/setting/email/get-termination-email-template-by-id",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

export {
  CreateEditTerminationEmail,
  GetEmail,
  DeleteEmail,
  GetTermimationEmailById,
};
