import axios from "axios";
import { APP_URL } from "./url";

const UserService = axios.create({
  baseURL: APP_URL?.User,
});

const SettingService = axios.create({
  baseURL: APP_URL?.Setting,
});

const WorkService = axios.create({
  baseURL: APP_URL?.Work,
});

const ReportService = axios.create({
  baseURL: APP_URL?.Report,
});

const PayRollService = axios.create({
  baseURL: APP_URL?.PayRoll,
});

export {
  UserService,
  SettingService,
  WorkService,
  ReportService,
  PayRollService,
};
