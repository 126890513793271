import React, { useCallback, useState } from "react";
import { FaEdit, FaSearch, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import Pagination, {
  throttler,
} from "../../../components/PaginationAPI/Pagination";
import { WorkService } from "../../../config/axiosUrl";
import { TextShimmer } from "../../../components/shimmer/shimmer";
import Confirmation from "../../../components/Confirmation";
import { toast } from "react-toastify";

const QA = () => {
  const [records, setRecords] = useState({
    loading: true,
    data: [],
  });
  const [hit, setHit] = useState(true);
  const [search, setSearch] = useState("");

  const [deleteModal, setDeleteModal] = useState({
    data: {},
    open: false,
    isDeleting: false,
  });
  const getData = useCallback((res) => {
    setRecords((prev) => ({
      loading: false,
      data: res?.data?.data?.data,
    }));
  }, []);

  const deleteQA = useCallback(async (id) => {
    await new Promise((res) => {
      setDeleteModal((prev) => ({
        ...prev,
        isDeleting: true,
      }));
      res(true);
    });
    WorkService.post("/api/v1/worksite/quality-assurance/delete", { id })
      .then((res) => {
        const message = res?.data?.message;
        if (res?.data?.status) {
          toast.success(message);
          setHit((prev) => !prev);

          toggleDelete();
        } else {
          toast.error(message);
          setDeleteModal((prev) => ({
            ...prev,
            isDeleting: false,
          }));
        }
      })
      .catch((err) => {
        toast.error("Error Deleting Item");
        setDeleteModal((prev) => ({
          ...prev,
          isDeleting: false,
        }));
      });
  }, []);

  const toggleDelete = useCallback((item) => {
    setDeleteModal((prev) => ({
      ...prev,
      data: item || {},
      open: !prev.open,
    }));
  }, []);

  return (
    <div className="flex">
      <Confirmation
        open={deleteModal.open}
        onCancel={() => toggleDelete()}
        title="Are you sure you want to delete this quality assurance template"
        onConfirm={() => deleteQA(deleteModal.data?._id)}
        details={deleteModal.data?.name}
        isSubmitting={deleteModal.isDeleting}
      />
      <div className="bg-white mx-auto my-8 w-[80%]">
        {records.loading ? (
          <TextShimmer
            data={{
              line: 20,
              gap: 10,
            }}
          />
        ) : (
          <div>
            <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/3 m-4 bg-white">
              <FaSearch className="ml-2" />
              <input
                placeholder="Search by Quality Assurance Name"
                className="h-[31px] bg-transparent text-sm w-full px-2"
                onChange={({ target: { value } }) => {
                  throttler(setSearch)(value);
                }}
              />
            </div>
            <table className="w-full">
              <thead className="bg-[#E8F3FF] text-center">
                <tr className="text-left">
                  <th className=" px-4 py-3 ">
                    <div className="flex items-center justify-start">
                      Quality Assurance Name
                    </div>
                  </th>
                  <th className=" px-4 py-3 ">
                    <div className="flex items-center justify-center">
                      Actions
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {records.data.map((item) => (
                  <tr className="border-b border-[#F0F0F0]" key={item._id}>
                    <td className="px-4 py-3">
                      <div className="flex items-center justify-start">
                        {item.name}
                      </div>
                    </td>
                    <td className="px-4 py-3">
                      <div className="flex items-center justify-center">
                        <Link
                          to={`/admin/settings/edit-qa-template/${item._id}`}
                        >
                          <button className="mx-2">
                            <FaEdit title="Edit" />
                          </button>
                        </Link>
                        <button
                          className="mx-2"
                          onClick={() => toggleDelete(item)}
                        >
                          <FaTrashAlt title="Delete" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <Pagination
          api={"/api/v1/worksite/quality-assurance/get-quality-assurance"}
          apiService={WorkService}
          getRes={getData}
          dependecy={[hit, search]}
          extraParams={{ search }}
        />
      </div>
    </div>
  );
};

export default QA;
