import React from "react";
import { Button } from "antd";
import { FaSearch, FaPlus, FaArrowRight } from "react-icons/fa";
import { Trash, Edit } from "../../../../assets/svg";
import { Fragment } from "react";
import { useState } from "react";
import ExpensesFrontModal from "./ExpensesFrontModal";
import { useMemo } from "react";
import DeleteExpenses from "./DeleteExpenses";
import { useNavigate } from "react-router-dom";
import { PayRollService } from "../../../../config/axiosUrl";
import { useEffect } from "react";
import EmployeeExpenses from "../EmployeeExpenses";
import ExpenseDeleteWorker from "./ExpenseDeleteWorker";

const ExpensesFront = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [selectedWorkerIds, setSelectedWorkerIds] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [emplist, setEmpList] = useState([]);
  console.log("the selected ", selectedWorkers);
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState({
    visible: false,
    current: {},
    isDeleting: false,
  });

  const handleCreateClick = () => {
    setModalVisible(true);
  };

  const handleEditClick = () => {
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const getEmployeeList = () => {
    // let params = {
    //   search: searchQuery,
    // };
    PayRollService.post(`/api/v1/payroll/setting/employee-list`)
      .then((res) => {
        setEmpList(res?.data?.data?.data);
      })
      .catch((error) => {
        // Handle errors if needed
        console.error(error);
      });
  };
  useEffect(() => {
    getEmployeeList();
  }, []);
  const handleDeleteClick = (itemId, itemname) => {
    console.log("id", itemId);

    setDeleteModal({
      visible: true,
      current: itemId,
      isDeleting: false,
    });
  };

  const handleDeleteConfirm = () => {
    const params = {
      id: deleteModal.current._id,

      // Add other parameters needed for deletion
    };
    console.log("theheeh", params);
    PayRollService.post(
      `api/v1/payroll/setting/delete-assigned-expense-employee`,
      params
    )
      .then((res) => {
        // Handle success, update the component state
        setEmpList((prevData) =>
          prevData.filter((item) => item._id !== deleteModal.current._id)
        );
        getEmployeeList();
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      })
      .finally(() => {
        // Close the delete modal
        setDeleteModal({
          visible: false,
          current: {},
          isDeleting: false,
        });
      });
  };

  const handleDeleteCancel = () => {
    // Close the delete modal without deleting
    setDeleteModal({
      visible: false,
      current: {},
      isDeleting: false,
    });
  };
  return (
    <>
      <div className="flex justify-end  bg-white p-5">
        {/* <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/3 my-2 bg-white">
          <FaSearch className="ml-2" />
          <input
            placeholder="Search by worker name"
            value={searchQuery}
            onChange={handleSearch}
            className="h-[31px] bg-transparent text-sm w-full px-2"
          />
        </div> */}
        <Button
          onClick={handleCreateClick}
          className="flex justify-center items-center py-3 border border-[#111111] h-[42px]"
        >
          Add Worker
          <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
            <FaPlus />
          </div>
        </Button>
      </div>
      <table className="w-full">
        <thead className="bg-[#E8F3FF] text-center ">
          <tr className="text-left">
            <th className=" px-4 py-3 w-30 ">
              <div className="flex items-center justify-center ">Name</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">
                Payroll Category
              </div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Actions</div>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {emplist?.map((item, index) => (
            <Fragment key={item.id}>
              <tr className="border-b-2 border-[#F0F0F0]">
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    {item?.users?.name}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    {item?.payroll_category?.name}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex gap-4 items-center justify-center ">
                    <button
                      className="text-black bg-transparent border-none"
                      onClick={() => handleDeleteClick(item)}
                    >
                      <Trash />
                    </button>
                    <button
                      className="text-black bg-transparent border-none"
                      onClick={() => {
                        navigate(
                          `/admin/settings/employee-expenses/Superannuation2?id=${item._id}`
                        );
                      }}
                    >
                      <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                        <FaArrowRight />
                      </div>
                    </button>
                  </div>
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>

      <ExpensesFrontModal
        visible={modalVisible}
        onCancel={handleModalCancel}
        emplist={getEmployeeList}
        selectedWorkers={selectedWorkers}
      />
      <ExpenseDeleteWorker
        visible={deleteModal.visible}
        onCancel={handleDeleteCancel}
        title="Confirm Deletion"
        itemName={deleteModal.current?.users?.name}
        onConfirm={handleDeleteConfirm}
        buttonText="Delete"
      />
    </>
  );
};

export default ExpensesFront;
