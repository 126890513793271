import { Button } from "antd";
import React, { Fragment, useState, useEffect, useCallback } from "react";
import { ButtonSpinner } from "../spinners";
import { usePaginationMemory } from "../../layouts/Main";

const paginationInit = {
  perPage: 0,
};

export const useDebounce = (
  effect = () => {},
  dependencies = [],
  delay = 0,
  callBackReturn
) => {
  const callback = useCallback(effect, dependencies);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => {
      clearTimeout(timeout);
      callBackReturn && callBackReturn();
    };
  }, [callback, delay]);
};

export function throttler(callback, delay) {
  let timeout = undefined;
  return function (...args) {
    if (timeout !== undefined) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => callback(...args), delay);
  };
}

const Pagination = ({
  extraParams,
  api,
  apiService,
  getRes,
  dependecy = [],
}) => {
  const controller = new AbortController();

  const [pagination, setPagination] = useState({
    dataPerpage: 10,
    skip: 0,
    cursor: "",
    pageCounts: "",
    previous_cursor: "",
    totalItems: 0,
    currentPage: 1,
    recordStart: 0,
    recordEnd: 0,
    firstLoad: true,
    currentCursor: "",
  });

  const [loading, setLoading] = useState({
    isLoadingnext: false,
    isLoadingprevious: false,
  });

  const PaginationHelper = usePaginationMemory();

  const apiCall = async function (type) {
    if (type) {
      await new Promise((resolve) => {
        setLoading({
          isLoadingnext: type === "next",
          isLoadingprevious: type === "prev",
        });
        resolve(true);
      });
    }

    const {
      cursor = "",
      previous_cursor = "",
      currentPage = 1,
      currentCursor = "",
    } = pagination;

    const currCursor =
      type === "next"
        ? cursor
        : type === "prev" && currentPage !== 2
        ? previous_cursor
        : currentCursor;

    let localAPI = `${api}?cursor=${currCursor}${
      type === "prev" ? `&previous=${currentPage}` : ""
    }`;

    if (extraParams) {
      let keys = Object.keys(extraParams);
      if (keys.length) {
        for (let k of keys) {
          localAPI = `${localAPI}&${k}=${extraParams[k] || ""}`;
        }
      }
    }

    apiService
      .get(localAPI, { signal: controller.signal })
      .then((res) => {
        if (res?.data?.status) {
          if (pagination.firstLoad) {
            let totalItems = res?.data?.extra?.totalItems;
            setPagination((prev) => ({
              ...prev,
              ...res?.data?.extra,
              firstLoad: false,
              recordStart: 1,
              recordEnd: totalItems < 10 ? totalItems : 10,
              currentCursor: currCursor,
            }));
          } else {
            setPagination((prev) => {
              let recordEnd = prev.recordEnd;
              if (type === "next") {
                recordEnd += 10;
              }
              if (type === "prev") {
                recordEnd -=
                  prev.recordEnd === prev.totalItems
                    ? prev.totalItems % 10
                    : 10;
              }
              if (recordEnd > prev.totalItems) {
                recordEnd = prev.totalItems;
              }

              return {
                ...prev,
                ...res?.data?.extra,
                firstLoad: false,
                recordStart:
                  type === "next"
                    ? prev.recordStart + 10
                    : type === "prev"
                    ? prev.recordStart - 10
                    : prev.recordStart,
                recordEnd,
                currentPage:
                  type === "next"
                    ? prev.currentPage + 1
                    : type === "prev"
                    ? prev.currentPage - 1
                    : prev.currentPage,
                currentCursor: currCursor,
              };
            });
          }

          if (getRes) {
            getRes(res);
          } else {
            throw new Error("GET FN NOT PASSED");
          }

          setLoading({
            isLoadingnext: false,
            isLoadingprevious: false,
          });
        }
      })
      .catch((err) => {
        console.log(err?.message);
      });
  };

  useDebounce(
    () => {
      apiCall();
    },
    [...dependecy],
    300,
    () => controller.abort("New Request might be initialized")
  );

  return (
    <div className="flex p-2 justify-between w-full">
      {pagination.totalItems > 0 && (
        <Fragment>
          <div className="text-slate-600">
            Showing : {pagination?.recordStart}-{pagination?.recordEnd} results
            of {pagination?.totalItems}
          </div>
          <div>
            <div>
              <Button
                className="border-0 no-color-disable"
                disabled={
                  loading?.isLoadingprevious || pagination.currentPage <= 1
                }
                onClick={() => apiCall("prev")}
              >
                {loading?.isLoadingprevious ? (
                  <div className="px-3">
                    <ButtonSpinner />
                  </div>
                ) : (
                  <div className="text-base"> {"< "} Previous | &nbsp;</div>
                )}
              </Button>

              <span className="px-1">
                {pagination?.currentPage} &nbsp; out of &nbsp;
                {pagination.pageCounts}
              </span>

              <Button
                className="border-0 no-color-disable"
                disabled={
                  loading?.isLoadingnext ||
                  pagination.currentPage === pagination.pageCounts
                }
                onClick={() => apiCall("next")}
              >
                {loading?.isLoadingnext ? (
                  <div className="px-3">
                    <ButtonSpinner />
                  </div>
                ) : (
                  <div className="text-base"> | Next {" >"}</div>
                )}
              </Button>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Pagination;
