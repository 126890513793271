import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { CameraSvg } from "../../assets/svg/camerasvg";
import { ImageCancelSvg } from "../../assets/svg/imagecancelsvg";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  objectFit: "contain",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "0px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 130,
  height: 130,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  width: "30vw",
  height: "20vh",
  position: "relative",
  objectFit: "contain",
};

const img = {
  display: "block",
  width: "100%",
  borderRadius: "10px",
};

const cancelsvg = {
  position: "absolute",
  right: 4,
  top: 4,
};

const ImageUploder = ({ props, files, setFiles }) => {
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      //   "image/*": [],
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
      "image/webp": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const removeImgPreview = () => {
    setFiles([]);
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
        <div style={cancelsvg} onClick={removeImgPreview}>
          <ImageCancelSvg />
        </div>
      </div>
    </div>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) =>
    errors.map((e) => <div key={e.code}>{e.message}</div>)
  );

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <div>
      {thumbs?.length > 0 ? (
        <>
          {/*     <div>
            <div style={thumbsContainer}>{thumbs}</div>
          </div> */}
          <div>{thumbs}</div>
        </>
      ) : (
        <>
          <div className="flex">
            <div className="border-2 border-slate-200 rounded-xl cursor-pointer">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <div className="px-3 py-6 justify-center text-center">
                  <div className="flex justify-center ">
                    <div>
                      <CameraSvg />
                    </div>
                  </div>
                  <div className="text-slate-200 text-xs py-1">
                    Upload your profile
                  </div>
                  <div className="text-xs">Click to Upload</div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-xs pt-2 text-red-600">{fileRejectionItems}</div>
        </>
      )}
    </div>
  );
};

export { ImageUploder };
