import { Button, Checkbox, Dropdown, Select, Input, DatePicker } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../../../../assets/styles/tableStyle.css";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { PayRollService } from "../../../../../config/axiosUrl";
import { GetWorkSite } from "../../../../../helper/worksite/worksite";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import { dummy } from "./dummy";
import SummaryCollapsible from "./SummaryCollapsible";

const { RangePicker } = DatePicker;

const DateFormat = "yyyy-MM-DD";

const getRange = () => {
  const nearestSat = moment().isoWeekday(6);
  const nearestFri = moment().isoWeekday(5);

  if (nearestFri.isBefore(nearestSat)) {
    return {
      end: nearestFri.format(DateFormat),
      start: nearestSat.subtract(1, "week").format(DateFormat),
    };
  } else {
    return {
      end: nearestFri.format(DateFormat),
      start: nearestSat.format(DateFormat),
    };
  }
};

const Summary = () => {
  const tableRef = useRef();
  const navigate = useNavigate();

  const [summary, setSummary] = useState({
    data: [],
    loading: true,
    employmentStatus: [],
    isFirstLoad: true,
    dateLimit: "",
  });
  const [active, setActive] = useState({
    data: [],
    loading: true,
    employmentStatus: [],
    isFirstLoad: true,
  });
  const [terminated, setTerminated] = useState({
    data: [],
    loading: true,
    employmentStatus: [],
    isFirstLoad: true,
  });

  const [selectEMP, setSelectedEMP] = useState([]);

  const [filter, setFilter] = useState({
    worksites: [],
    payment: [],
    allWorksiteLoaded: false,
    selectedWorksite: "",
  });

  const get10Range = (startDate, endDate) => {
    let range = [],
      start = moment(startDate),
      end = moment(endDate);

    while (range.length < 10) {
      start = start.subtract(1, "week");
      end = end.subtract(1, "week");
      if (
        !summary.dateLimit ||
        moment(summary.dateLimit).isBefore(start) ||
        moment(summary.dateLimit).isBefore(end)
      ) {
        range.push({
          label: `From ${start.format(DateFormat)} to ${end.format(
            DateFormat
          )}`,
          value: `${start.format(DateFormat)}/${end.format(DateFormat)}`,
        });
      } else {
        break;
      }
    }
    return range;
  };

  const [dateRange, setDateRange] = useState(() => {
    let defDate = { start: "", end: "", range: [] };

    defDate = getRange();
    defDate.range = [defDate.start];
    defDate.rangeOptions = [
      {
        label: `From ${defDate.start} to ${defDate.end}`,
        value: `${defDate.start}/${defDate.end}`,
      },
      ...get10Range(defDate.start, defDate.end),
    ];

    return defDate;
  });
  useEffect(() => {
    let curr = moment(dateRange.start);
    const newRange = [dateRange.start];

    while (moment(dateRange.end).isAfter(curr)) {
      curr = curr.add(1, "day");
      newRange.push(curr.format(DateFormat));
    }

    setDateRange((prev) => ({
      ...prev,
      range: newRange,
    }));
  }, [dateRange.start, dateRange.end]);

  // console.log("the date ", dateRange);
  const toggleEMP = useCallback((id) => {
    setSelectedEMP((prev) => {
      if (prev.includes(id)) {
        prev.splice(prev.indexOf(id), 1);
      } else {
        prev.push(id);
      }

      return [...prev];
    });
  }, []);

  const getSummary = useCallback(
    async (worksite_id = "", employment_status_id = []) => {
      await new Promise((res) => {
        setSummary((prev) => ({
          ...prev,
          loading: true,
        }));
        res(true);
      });

      let params = {
        worksite_id,
        from: dateRange.start || "",
        to: dateRange.end || "",
        employment_status_id,
      };

      // setSummary((prev) => ({
      //   ...prev,
      //   data: dummy?.data?.data || [],
      //   loading: false,
      //   employmentStatus: dummy?.data?.employment_status,
      //   isFirstLoad: false,
      // }));

      PayRollService.post(
        "/api/v1/setting/payroll/get-summary-data?dev=3",
        params
      )
        .then((res) => {
          if (res.status) {
            setSummary((prev) => ({
              ...prev,
              data: res?.data?.data?.data || [],
              loading: false,
              employmentStatus: res?.data?.data?.employment_status,
              isFirstLoad: false,
              dateLimit: res?.data?.data?.worksite_created_at,
            }));
          }
        })
        .catch((err) => {
          setSummary((prev) => ({
            ...prev,
            loading: false,
          }));
        });
    },
    [summary.isFirstLoad, dateRange]
  );

  const getActive = useCallback(
    async (worksite_id = "", employment_status_id = []) => {
      await new Promise((res) => {
        setActive((prev) => ({
          ...prev,
          loading: true,
        }));
        res(true);
      });

      let params = {
        worksite_id,
        from: dateRange.start || "",
        to: dateRange.end || "",
        employment_status_id,
        user_status: 1,
      };

      PayRollService.post("api/v1/setting/payroll/export-summary-data", params)
        .then((res) => {
          if (res.status) {
            setActive((prev) => ({
              ...prev,
              data: res?.data?.data?.data || [],
              loading: false,
              employmentStatus: res?.data?.data?.employment_status,
              isFirstLoad: false,
            }));
          }
        })
        .catch((err) => {
          setActive((prev) => ({
            ...prev,
            loading: false,
          }));
        });
    },
    [active.isFirstLoad, dateRange]
  );

  const getTerminated = useCallback(
    async (worksite_id = "", employment_status_id = []) => {
      await new Promise((res) => {
        setTerminated((prev) => ({
          ...prev,
          loading: true,
        }));
        res(true);
      });

      let params = {
        worksite_id,
        from: dateRange.start || "",
        to: dateRange.end || "",
        employment_status_id,
        user_status: 3,
      };

      PayRollService.post("api/v1/setting/payroll/export-summary-data", params)
        .then((res) => {
          if (res.status) {
            setTerminated((prev) => ({
              ...prev,
              data: res?.data?.data?.data || [],
              loading: false,
              employmentStatus: res?.data?.data?.employment_status,
              isFirstLoad: false,
            }));
          }
        })
        .catch((err) => {
          setTerminated((prev) => ({
            ...prev,
            loading: false,
          }));
        });
    },
    [terminated.isFirstLoad, dateRange]
  );

  const getPrevDateRange = function (e) {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      setDateRange((prev) => {
        const lastGenRange =
          prev.rangeOptions[prev.rangeOptions.length - 1].value.split("/");

        if (lastGenRange.length > 1) {
          prev.rangeOptions.push(
            ...get10Range(lastGenRange[0], lastGenRange[1])
          );
        }
        return { ...prev };
      });
    }
  };

  const onDateChange = function (value) {
    const dates = value.split("/");

    setDateRange((prev) => ({
      ...prev,
      start: dates[0],
      end: dates[1],
    }));
  };

  function horizontalScroller(e) {
    if (e.deltaY > 0) {
      tableRef.current.scrollLeft += 100;
      e.preventDefault();
    } else {
      tableRef.current.scrollLeft -= 100;
      e.preventDefault();
    }
  }

  const getWorksites = useCallback(
    async (search = "") => {
      const worksite = await GetWorkSite({
        search,
        take: 20,
        cursor: filter.worksites[filter.worksites.length - 1]?.id || "",
      });

      setFilter((prev) => {
        const allWork = prev.worksites.concat(worksite.data.data);
        const ids = [];
        const filtered = [];
        allWork.forEach((item) => {
          if (!ids.includes(item.id)) {
            ids.push(item.id);
            filtered.push(item);
          }
        });

        return {
          ...prev,
          worksites: filtered,
          allWorksiteLoaded: worksite.extra.totalItems <= filtered.length,
        };
      });
    },
    [filter.worksites]
  );

  const onScrollEnd = async (e) => {
    if (
      Math.round(e.target.scrollHeight - e.target.scrollTop) ===
        e.target.clientHeight &&
      !filter.allWorksiteLoaded
    ) {
      // console.log("HIT");
      getWorksites();
    }
  };

  useEffect(() => {
    getSummary(filter.selectedWorksite || "", selectEMP || []);
  }, [filter.selectedWorksite, selectEMP, dateRange.start, dateRange.end]);
  useEffect(() => {
    getActive(filter.selectedWorksite || "", selectEMP || []);
  }, [filter.selectedWorksite, selectEMP, dateRange.start, dateRange.end]);

  useEffect(() => {
    getTerminated(filter.selectedWorksite || "", selectEMP || []);
  }, [filter.selectedWorksite, selectEMP, dateRange.start, dateRange.end]);

  useEffect(() => {
    getWorksites();
  }, []);

  return (
    <div className="m-5 flex flex-col gap-7">
      <div className="bg-white p-8">
        <span className="flex justify-between">
          <h2 style={{ fontSize: "26px" }}>Summary Details</h2>
          <Dropdown
            dropdownRender={() => (
              <div className="bg-white p-2">
                {summary.employmentStatus.map((item, index) => (
                  <span
                    key={index}
                    className={`flex gap-2 py-1 ${"border-b border-b-[#E9E9E9]"} `}
                  >
                    <Checkbox
                      onChange={() => toggleEMP(item._id)}
                      checked={selectEMP.includes(item._id)}
                    />
                    {item.name}
                  </span>
                ))}
              </div>
            )}
          >
            <Button>Employment Filter</Button>
          </Dropdown>
        </span>
        <div className="flex gap-20 mt-16 mb-8">
          <span className="flex-1 flex flex-col">
            <label>Worksite</label>
            <Select
              onPopupScroll={onScrollEnd}
              onChange={(selectedWorksite) =>
                setFilter((prev) => ({ ...prev, selectedWorksite }))
              }
            >
              {filter.worksites.map((item) => (
                <Select.Option value={item.id} key={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </span>
          <span className="flex-1 flex flex-col hide-second-month">
            <label>Week</label>

            <Select
              onPopupScroll={getPrevDateRange}
              onChange={onDateChange}
              defaultValue={`${dateRange.start}/${dateRange.end}`}
            >
              {dateRange.rangeOptions.map((item) => (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
            {/* </div> */}
          </span>
        </div>
      </div>

      {summary.loading ? (
        <div className="bg-white">
          <TextShimmer
            data={{
              line: 10,
              gap: 15,
            }}
          />
        </div>
      ) : (
        summary.data.map((item, index) => (
          <div className="bg-white" key={index}>
            <SummaryCollapsible
              dateRange={dateRange}
              summary={item}
              actives={active}
              terminateds={terminated}
            />
          </div>
        ))
      )}
    </div>
  );
};

export default Summary;

//  <table className="text-center my-10 font-sm w-full">
//    <thead className="timesheet-detailed-table-head-container">
//      <tr className="timesheet-detailed-table-main-head">
//        <th>
//          <div className=" font-semibold">Name</div>
//        </th>
//        <th>
//          <div className=" font-semibold text-center">Rate</div>
//        </th>
//        <th>
//          <div className=" font-semibold">Job</div>
//        </th>

//        <th colSpan={5}>Totals</th>

//        <th colSpan={2}>Accruals</th>
//      </tr>
//      <tr className="timesheet-detailed-table-secondary">
//        <th></th>
//        <th></th>
//        <th></th>

//        <th>
//          <p className="pl-2 font-semibold">GT</p>
//        </th>
//        <th>N</th>
//        <th>OT</th>
//        <th>DT</th>
//        <th>
//          <p className="pr-2 font-semibold">TOD</p>
//        </th>

//        <th>
//          <div className=" font-semibold">Al</div>
//        </th>
//        <th>
//          <div className=" font-semibold">Sick</div>
//        </th>
//      </tr>
//    </thead>
//    <tbody className="timesheet-detailed-table-body">
//      {item.worksites?.map((child) => {
//        return child?.worker_details?.map((item) =>
//          item?._id ? (
//            <tr className="border-b border-b-[#E9E9E9] ">
//              <td className="">{item.user.name}</td>
//              <td>
//                {
//                  item
//                    ?.payroll_category_allowance_payroll_category_datas?.[0]
//                    ?.payroll_category_allowance?.[0]?.name
//                }
//              </td>
//              <td>{child?.job_no}</td>

//              {/* GT */}
//              <td>
//                <p className="pl-2">
//                  {getTotals(item?.totals?.gross_time)}
//                </p>
//              </td>
//              {/* N */}
//              <td>{getTotals(item?.totals?.normal_time)}</td>
//              {/* OT */}
//              <td>{getTotals(item?.totals?.over_time)}</td>
//              {/* DT */}
//              <td>{getTotals(item?.totals?.double_time)}</td>
//              {/* TOD */}
//              <td>
//                <p className="pr-2">
//                  {getTotals(item?.totals?.ordinary_days)}
//                </p>
//              </td>

//              {/* AI */}
//              <td>-1.6</td>
//              {/* Sick */}
//              <td>-1.6</td>
//            </tr>
//          ) : (
//            <Fragment />
//          )
//        );
//      })}
//    </tbody>
//  </table>;
