import { Input } from "antd";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { ArrowLeft, CircleFilledPlusBlue, Cross } from "../../../assets/svg";
import { useNavigate, useParams } from "react-router-dom";
import { WorkService } from "../../../config/axiosUrl";
import { ButtonSpinner } from "../../../components/spinners";
import InputError from "../../../components/InputError";
import { toast } from "react-toastify";

const CreateQA = () => {
  const formInit = useMemo(
    () => ({
      id: "",
      name: "",
      quality_assurance_details: [{ id: "", name: "" }],
    }),
    []
  );
  const navigate = useNavigate();

  const [formState, setFromState] = useState({
    loading: true,
    data: { ...formInit },
  });

  const [isSubmitting, setSubmitting] = useState(false);

  const { id = "" } = useParams();

  const { handleSubmit, control } = useForm({
    defaultValues: { ...formInit },
    values: formState.data,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "quality_assurance_details",
    keyName: "formKey",
  });

  const getById = useCallback(() => {
    WorkService.get(
      `/api/v1/worksite/quality-assurance/get-quality-assurance-by-id?id=${id}`
    ).then((res) => {
      const data = res?.data?.data?.data?.[0];

      let makeData = {
        id: data?._id,
        name: data?.name,
        quality_assurance_details:
          data?.quality_assurance_details.map((item) => ({
            id: item._id,
            name: item.name,
          })) || [],
      };
      setFromState((prev) => ({
        ...prev,
        loading: false,
        data: makeData,
      }));
    });
  }, [id]);

  const onSubmit = useCallback(
    async (data) => {
      setSubmitting(true);
      await new Promise((res) => {
        res(true);
      });
      WorkService.post(
        "/api/v1/worksite/quality-assurance/save-edit-quality-assurance?dev=3",
        { ...data, isSave: id ? 0 : 1 }
      )
        .then((res) => {
          if (res?.data?.status) {
            setSubmitting(false);
            toast.success(res?.data?.message);
            navigate(-1);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.error("Error creating template");
          setSubmitting(false);
        });
    },
    [id, navigate]
  );

  useEffect(() => {
    if (id) {
      getById();
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex">
        <div className="mx-auto my-8 w-70 flex flex-col gap-8">
          <div className="bg-white p-8">
            <span className="flex items-center mb-5 gap-3">
              <ArrowLeft
                onClick={() => navigate(-1)}
                className="cursor-pointer"
              />
              <h1 className="text-xl font-semibold">Quality Assurance</h1>
            </span>
            <label>Template name</label>
            <Controller
              name="name"
              rules={{
                required: "Please enter template name",
              }}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Fragment>
                  <Input className="mt-5" onChange={onChange} value={value} />
                  <InputError message={error?.message} />
                </Fragment>
              )}
            />
          </div>
          {fields.map((item, index) => (
            <div className="bg-white p-8 relative" key={item.formKey}>
              <div
                className="absolute top-5 right-5 cursor-pointer user-select-none"
                onClick={() => remove(index)}
              >
                <Cross />
              </div>
              <label>Quality Assurance Details</label>
              <Controller
                name={`quality_assurance_details[${index}].name`}
                control={control}
                rules={{
                  required: "Please Enter Quality Assurance Details",
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Fragment>
                    <Input className="mt-5" onChange={onChange} value={value} />
                    <InputError message={error?.message} />
                  </Fragment>
                )}
              />
            </div>
          ))}
          <div className="flex gap-5 justify-center">
            <button
              className="bg-[#0F4C7D] min-h-[40px] px-5 rounded-lg"
              onClick={(e) => {
                e.preventDefault();
                append({ id: "", name: "" });
              }}
            >
              <CircleFilledPlusBlue />
            </button>
            <button
              className="text-white rounded-lg px-5 bg-[#0F4C7D]"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? <ButtonSpinner /> : id ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateQA;
