import React, { useState, useEffect } from 'react'
import { Button, Form, Modal, Select } from 'antd'
import { ButtonSpinner } from '../../components/spinners'
import { ChangeWorkerStatusTransfer, GetWorkSite, GetWorkersType } from '../../helper/worksite/worksite'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { GetPayrollCatagory } from '../../helper/setup/payroll/payrollcatagory'

const TransferModal = ({ isOpen, onClose, worksite, setWorksite, selectedItem }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [worksiteList, setWorksiteList] = useState()
  const [payrollCategories,setPayrollCategories] = useState()
  const [selectedWorksite, setSelectedWorksite] = useState()
  const id = useParams()
  const saveChanges = async () => {
    setIsLoading(true)
    setWorksite({
      ...worksite,
      is_mass_transfer: 1
    })
    const response = await ChangeWorkerStatusTransfer(selectedItem, worksite, selectedWorksite)
    if (response?.status) {
      toast.success(response?.message?response?.message:"Transfer Successfully")
      onClose()
      const param = { type: 'Rostered', id: id?.id }
      const res = await GetWorkersType(param)
      if (res?.status) {
        setWorksite({
          ...worksite,
          data: res?.data?.data,
        })
      }
    }
  }
  const handleChange = (value) => {
    setSelectedWorksite(value)
  };
  const handleChangePayroll=()=>{

  }
  const fetchWorksiteList = async () => {
    let data = await GetWorkSite();
    if (data?.status) {
      setWorksiteList({
        ...worksite,
        data: [...data?.data?.data],
      })
    }
  }
  const fetchPayrollCategories = async ()=>{
    let data = await GetPayrollCatagory();
    if(data?.status){
      setPayrollCategories({
        ...payrollCategories,
        data:[...data?.data?.data]
      })
    }
  }
  useEffect(() => {
    (async () => {
      fetchWorksiteList()
      fetchPayrollCategories()
    })();
  }, []);
  return (
    <Modal title="Transfer Shift" className="clockin-modal" open={isOpen} onCancel={onClose} footer={[
      <Button key="save" onClick={saveChanges} className="btn btn-blue bg-blue-disable" disabled={!selectedWorksite}>
        {isLoading ? <ButtonSpinner /> : 'Save Changes'}
      </Button>]}>
      <p className='text-[#A1A1A1]'>Transferring a shift will move the shift to another worksite, removing it from current one</p>

      <Form layout='vertical' className='mt-4'>
        <Form.Item label="Select A Worksite">
          <Select
            placeholder="Select Worksite"
            onChange={handleChange}>
            {worksiteList?.data?.length > 0 && worksiteList?.data?.map((item) => (
              <Select.Option value={item?.id} key={item?.id}>
                {item?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Select Payroll Category">
          <Select
            placeholder="Select Payroll Category"
            onChange={handleChangePayroll}>
            {payrollCategories?.data?.length > 0 && payrollCategories?.data?.map((item) => (
              <Select.Option value={item?.id} key={item?.id}>
                {item?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default TransferModal