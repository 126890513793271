import React from "react";

const EditSvg = () => {
  return (
    <>
      <svg
        id="_314eec5d0c2110d02c2df6c410efc994"
        data-name="314eec5d0c2110d02c2df6c410efc994"
        xmlns="http://www.w3.org/2000/svg"
        width="14.047"
        height="14.034"
        viewBox="0 0 14.047 14.034"
      >
        <path
          id="Path_1138"
          data-name="Path 1138"
          d="M2.053,14.9h8.8A2.059,2.059,0,0,0,12.9,12.844V8.423a.587.587,0,0,0-1.173,0v4.421a.884.884,0,0,1-.88.887h-8.8a.884.884,0,0,1-.88-.887V4.061a.884.884,0,0,1,.88-.887h4.4A.587.587,0,1,0,6.452,2h-4.4A2.059,2.059,0,0,0,0,4.061v8.783A2.059,2.059,0,0,0,2.053,14.9Z"
          transform="translate(0 -0.87)"
          fill="#111"
        />
        <path
          id="Path_1139"
          data-name="Path 1139"
          d="M9.119,6.217l-.463,2.12a.586.586,0,0,0,.159.54.609.609,0,0,0,.54.158l2.113-.464a.586.586,0,0,0,.29-.159L17.1,3.065a1.76,1.76,0,0,0,0-2.49,1.8,1.8,0,0,0-2.49,0L9.28,5.928A.586.586,0,0,0,9.119,6.217Zm6.329-4.81a.6.6,0,0,1,.83,0,.592.592,0,0,1,0,.83l-.415.415-.83-.83ZM10.227,6.635,14.2,2.652l.824.826L11.046,7.462,9.99,7.692Z"
          transform="translate(-3.573 -0.075)"
          fill="#111"
        />
      </svg>
    </>
  );
};

export { EditSvg };
