import { Button } from "antd";
import React from "react";
import { FaTimes } from "react-icons/fa";
import { ButtonSpinner } from "../../components/spinners";

const DeleteInspection = ({
  setVisibleEdit,
  inspection,
  setInspection,
  handle,
  formref,
  setVisibleDelete,
}) => {
  const closeModal = () => {
    setVisibleDelete(false);
  };
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="text-right">
          <button className="close-modal" onClick={closeModal}>
            <FaTimes />
          </button>
        </div>

        <div className="modal-body mt-3">
          <h2 className="text-xl font-bold">
            Are you sure want to delete this Inspection?
          </h2>

          <form className="mt-4">
            <div className="item">
              <label htmlFor="val">
                Inspection Name : {inspection?.selectedData?.inspection}
              </label>
            </div>
            <div className="item mt-5 text-center">
              <Button
                className="btn btn-blue w-1/5 bg-blue-disable"
                onClick={handle?.delete}
                disabled={inspection?.selectedData?.isLoading}
              >
                {inspection?.selectedData?.isLoading ? (
                  <ButtonSpinner />
                ) : (
                  "Delete"
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DeleteInspection;
