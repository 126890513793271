import React from "react";
import { WorkService } from "../../../config/axiosUrl";
import { ApiAuthorizaion } from "../../../auth/appAuth";

const GetTasks = async (tasks) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      worksite_id: tasks?.worksite_id,
      search: tasks?.search,
      type: tasks?.type,
      status: tasks?.status,
      logger: tasks?.logger,
      cursor: tasks?.cursor,
      skip: tasks?.skip,
      area_ids: tasks?.area_ids,
      hrte_id: tasks?.hrte_id,
    };

    let data = await WorkService?.get("/api/v1/worksite/task/get-all-task", {
      headers: authorization?.headers,
      params: params,
    });
    return data?.data;
  } catch (error) {
    return error;
  }
};

const AddTaskLogCause = async (task) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      task_id: task?.task_id,
      log: task?.log,
      type: task?.type,
      isSave: task?.isSave,
      id: task?.id,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/task/create-log-cause",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

export { GetTasks, AddTaskLogCause };
