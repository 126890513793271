import { Button, Dropdown } from "antd";
import React, { useState } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
import FilterTabs from "./FilterTabs";
// import AddNewWorkerModal from "../Modal/AddNewWorkerModal";

const TopSearchBar = ({ data, setData, handle, filterWorkers, filter }) => {
  const items = [
    {
      key: "3",
      label: <FilterTabs />,
    },
  ];

  return (
    <>
      <div className="bg-white p-5 flex justify-between items-center">
        <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/4">
          <FaSearch className="ml-2 " />{" "}
          <input
            placeholder="Search by Worker Name, Email, Card ID"
            className="h-[31px] bg-transparent text-sm w-full px-2"
            onChange={handle?.search}
          />
          {/* <input
            placeholder="Search by Worker Name, Email, Card ID"
            className="h-[31px] bg-transparent text-sm w-full px-2"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handle?.search(e);
              }
            }}
          /> */}
        </div>

        <div className="actions flex justify-end items-center">
          <Dropdown
            placement="bottomRight"
            className="bg-white hover:bg-white"
            dropdownRender={() => (
              <FilterTabs filterWorkers={filterWorkers} filter={filter} />
            )}
          >
            <Button className="flex justify-center items-center py-3 mr-3 border border-[#111111] h-[42px]">
              <FaPlus className="mr-2" /> Add Filters
            </Button>
          </Dropdown>

          <Button
            className="flex justify-center items-center py-3 border border-[#111111] h-[42px]"
            onClick={handle?.add}
          >
            Add New{" "}
            <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
              <FaPlus />
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default TopSearchBar;
