import React, { useCallback, useEffect, useState } from "react";
import { PayRollService } from "../../../../../config/axiosUrl";
import { GetPage } from "../../../../../helper/global/global";
import {
  DeleteAbsences,
  GetAbsences,
} from "../../../../../helper/setup/payroll/absences";
import AbsencesTable from "../../../../../shared/DataTable/AbsencesTable";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import { FaSearch } from "react-icons/fa";
import { TableDefaultPage } from "../../../../../components/default/defaultPage";
import {
  TableDefaultSvg,
  NoSearchTableSvg,
} from "../../../../../assets/svg/tableDefaul";
import { NoTableOption } from "../../../../../components/noOption/noOption";
import { TablePagination } from "../../../../../components/pagination/pagination";
import { SearchData } from "../../../../../helper/global/global";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Confirmation from "../../../../../components/Confirmation";

const Absences = () => {
  let { page } = useParams();
  let { cursor } = useParams();

  const initialValues = {
    data: [],
    extra: {},
    selectedData: {},
    isLoading: false,
    isShimmer: true,
    pagination: {
      api: "/api/v1/setting/payroll/get-absences-data",
      service: PayRollService,
      route: "/admin/settings/payroll/absences",
      search: "",
    },
  };
  const [onArchive, setArchive] = useState({
    visible: false,
    data: {},
    isSubmitting: false,
    reget: false,
  });

  const [absences, setAbsences] = useState(initialValues);
  const [visibleDelete, setVisibleDelete] = useState(false);

  const handleAbsences = {
    get: async (e) => {
      console.log(page);
      const params = {
        cursor: cursor != 1 ? cursor : "",
        api: "/api/v1/setting/payroll/get-absences-data",
        service: PayRollService,
      };
      let data = await GetPage(params);
      return data;
    },
    search: async (e) => {
      const params = {
        value: e?.target?.value,
        api: "/api/v1/setting/payroll/get-absences-data",
        service: PayRollService,
      };
      let data = await SearchData(params);
      if (data?.status) {
        setAbsences({
          ...absences,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          pagination: {
            ...absences?.pagination,
            search: e?.target?.value,
          },
        });
      }
    },
    delete: async () => {
      setAbsences({
        ...absences,
        isLoading: true,
      });
      let data = await DeleteAbsences(absences?.selectedData);
      if (data?.status) {
        const params = {
          cursor: absences?.extra?.previousPage,
          api: "/api/v1/setting/payroll/get-absences-data",
          service: PayRollService,
        };
        let getData = await handleAbsences?.get();
        if (data?.message) {
          console.log(data);
          toast.success(data?.message);
          setAbsences({
            ...absences,
            data: [...getData?.data?.data],
            extra: { ...getData?.extra },
            isLoading: false,
          });
          setVisibleDelete(false);
        } else {
          toast.error(data?.message);
          setAbsences({
            ...absences,
            isLoading: false,
          });
        }
      }
    },
  };

  let deafaulPage = {
    disclaimer: "No Absences Found",
    description: "Create Your First Absences",
    buttonText: "Create New",
    svg: <TableDefaultSvg />,
    redirect: "/admin/settings/payroll/absences/create",
  };

  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };

  useEffect(() => {
    (async () => {
      let data = await handleAbsences?.get();
      console.log(data);
      if (data?.status) {
        setAbsences({
          ...absences,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          isShimmer: false,
        });
      }
    })();
  }, [onArchive.reget]);

  const archiveAbsences = useCallback(async () => {
    await new Promise((resolve) => {
      setArchive((prev) => ({
        ...prev,
        isSubmitting: true,
      }));
      resolve(true);
    });
    PayRollService.post("api/v1/payroll/setting/archive-absences-by-id", {
      id: onArchive.data?.id || "",
    })
      .then((res) => {
        if (res?.data?.status) {
          setArchive((prev) => ({
            ...prev,
            isSubmitting: false,
            visible: false,
            data: {},
            reget: !prev.reget,
          }));
          toast.success(res?.data?.message);
        } else {
          setArchive((prev) => ({
            ...prev,
            isSubmitting: false,
          }));
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        setArchive((prev) => ({
          ...prev,
          isSubmitting: false,
        }));
        toast.error("Failed to Archive Payroll Category");
      });
  }, [onArchive]);

  const onArchiveClick = useCallback((data) => {
    setArchive((prev) => ({
      ...prev,
      visible: !!data,
      data: data || {},
    }));
  }, []);

  return (
    <div className="p-6">
      <Confirmation
        open={onArchive.visible}
        title={`Are you sure want to archive this Absence`}
        details={onArchive.data?.name}
        onConfirm={archiveAbsences}
        isSubmitting={onArchive.isSubmitting}
        onCancel={() => onArchiveClick()}
        buttonText="Archive"
      />
      {absences?.isShimmer ? (
        <div className="bg-white  px-4 py-3">
          <TextShimmer data={{ gap: 15, line: 20 }} />
        </div>
      ) : (
        <>
          <div>
            {absences?.data?.length <= 0 && !absences?.pagination?.search ? (
              <>
                <TableDefaultPage
                  data={deafaulPage}
                  //
                />
              </>
            ) : (
              <>
                <div className="bg-white py-4 px-4">
                  <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/4">
                    <FaSearch className="ml-2" />{" "}
                    <input
                      type="text"
                      placeholder="Search by Absences Name"
                      className="h-[31px] bg-transparent text-sm w-full px-2"
                      key="1"
                      name="search"
                      onChange={handleAbsences?.search}
                    />
                  </div>
                </div>

                <AbsencesTable
                  data={absences}
                  setData={setAbsences}
                  handle={handleAbsences}
                  visibleDelete={visibleDelete}
                  setVisibleDelete={setVisibleDelete}
                  onArchive={onArchiveClick}
                />

                <div>
                  {absences?.data?.length <= 0 &&
                  absences?.pagination?.search ? (
                    <>
                      <NoTableOption data={noSearchOption} />
                    </>
                  ) : (
                    <>
                      <div className="bg-white">
                        <TablePagination
                          data={absences}
                          setData={setAbsences}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Absences;
