import React, { useState, useEffect } from "react";
import { FaTimes, FaTrashAlt, FaEdit } from "react-icons/fa";
import { ApiAuthorizaion } from "../../../../../auth/appAuth";
import { toast } from "react-toastify";
import { UserService } from "../../../../../config/axiosUrl";
import { TableDefaultPage } from "../../../../../components/default/defaultPage";
import { TableDefaultSvg } from "../../../../../assets/svg/tableDefaul";
import { Button } from "antd/es/radio";
import { ButtonSpinner } from "../../../../../components/spinners";
import { useParams } from "react-router-dom";
const PayrollCategoriesWorkers = ({ worker, handle, notes }) => {
  const { logID } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [visibleWorkerModal, setVisibleWorkerModal] = useState(false);

  let deafaulPage = {
    disclaimer: "No Payroll Categories",
    description: "Payroll Categories not selected",
    buttonText: "",
    svg: <TableDefaultSvg />,
    redirect: "",
  };
  const closeModal = () => {
    setVisibleWorkerModal(false);
  };

  const deleteCategories = async (catId) => {
    setIsLoading(true);
    setDeleteId(catId);
    try {
      const authorization = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: ApiAuthorizaion(),
        },
      };

      const params = {
        id: catId,
      };
      console.log("the id here is ", catId);
      let data = await UserService?.post(
        "/api/v1/personal/workers/remove-worker-payroll-category",
        params,
        authorization
      );
      if (data?.data?.status) {
        await handle?.getWorker();
        toast.success(data?.data?.message);
        setDeleteId("");
        setIsLoading(false);
        setVisibleWorkerModal(false);
      }
    } catch (error) {
      return error;
    }
  };
  return (
    <>
      <>
        <table className="w-full">
          <thead className="bg-[#E8F3FF] text-center">
            <tr className="text-left">
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-start">Company</div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-start">Worksite</div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-start">
                  Payroll Categories
                </div>
              </th>
              {!logID && (
                <th className=" px-4 py-3 ">
                  <div className="flex items-center justify-center">Action</div>
                </th>
              )}
            </tr>
          </thead>
          <tbody className="bg-white">
            {worker?.workers_payroll_category?.map((item, index) => (
              <tr key={index} className="border-b border-[#F0F0F0]">
                <td className="px-4 py-3">
                  <div className="flex items-center justify-start">
                    {item?.worksites?.companies?.name}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-start">
                    {item?.worksites?.name}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-start">
                    {item?.payroll_category.name}
                  </div>
                </td>
                {!logID && (
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center">
                      <button
                        className="mx-2"
                        onClick={() => {
                          setVisibleWorkerModal(true);
                          setDeleteId(item?.id);
                          setDeleteName(item?.payroll_category?.name);
                        }}
                      >
                        <FaTrashAlt />
                      </button>
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {visibleWorkerModal && (
          <div className="modal">
            <div className="modal-content">
              <div className="text-right">
                <button className="close-modal" onClick={closeModal}>
                  <FaTimes />
                </button>
              </div>
              <h2 className="text-xl font-bold">
                Are you sure want to delete this Categories?{" "}
              </h2>
              <div className="item mt-8">
                <table>
                  <tbody>
                    <tr>
                      <td className="font-bold">Name :</td>
                      <td className="pl-5">{deleteName}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="item mt-8 text-center">
                <Button
                  onClick={() => deleteCategories(deleteId)}
                  className="btn btn-blue w-1/5 bg-blue-disable"
                >
                  {isLoading ? <ButtonSpinner /> : "Delete"}
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default PayrollCategoriesWorkers;
