import { Button, Input, Select, Tooltip } from "antd";

import React, { useCallback, useEffect, useState } from "react";
import { FaPlus, FaQuestionCircle, FaTrash } from "react-icons/fa";
import { ImageCancelSvg } from "../../assets/svg/imagecancelsvg";
import { ImageUploder } from "../../components/FileDropzone/imageUpload";
import { ButtonSpinner } from "../../components/spinners";
import { Fileurl } from "../../config/url";
import MultySelect from "../../components/multySelect/multyselect";
import DeleteModal from "../../components/Modal/deleteModal";
import { DeleteWorkSiteImage } from "../../helper/worksite/worksite";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PopupSelector from "../../pages/Admin/Payroll/Allowences/AllowenceItems/PopupSelector";
import { PayRollService, WorkService } from "../../config/axiosUrl";

const cancelsvg = {
  position: "absolute",
  right: 4,
  top: 4,
};

const img = {
  display: "block",
  width: "100%",
  borderRadius: "10px",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "0px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 130,
  height: 130,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  width: "30vw",
  height: "20vh",
  position: "relative",
  objectFit: "contain",
};

const WorksiteForm = ({
  data,
  setData,
  handle,
  errors,
  files,
  setFiles,
  multySelect,
  setMultySelect,
}) => {
  console.log("the data us ", data);
  const { id } = useParams();

  const [selector, setSelector] = useState({
    data: [],
    loading: false,
    visible: false,
    allLoaded: false,
    submitting: true,
  });

  const removeImgPreview = async () => {
    setData({
      ...data,
      isModalLoading: true,
    });

    const params = {
      id: id,
    };
    let getdata = await DeleteWorkSiteImage(params);
    let allData = { ...data };

    if (getdata?.status) {
      allData.picture = "";

      setData({
        ...allData,
        isModal: false,
        modal_selected_data: {},
        isModalLoading: false,
      });

      toast?.success(getdata?.message);
    } else {
      setData({
        ...allData,
        isModalLoading: false,
      });

      toast?.error(getdata?.message);
    }
  };

  const toggleSelector = () => {
    setSelector((prev) => ({
      ...prev,
      visible: !prev.visible,
    }));
  };

  const getWorksites = (search = "", cursor = "") => {
    PayRollService.get(
      `/api/v1/payroll/setting/get-payroll-category-data?search=${search}&cursor=${cursor}`
    ).then((res) => {
      setSelector((prev) => {
        const ids = [],
          data = search ? res?.data?.data?.data : [],
          allItems = prev.data.concat(res?.data?.data?.data);

        if (!search) {
          allItems.forEach((item) => {
            if (!ids.includes(item.id)) {
              ids.push(item.id);
              data.push(item);
            }
          });
        }

        return {
          ...prev,
          data,
          allLoaded: res?.data?.extra?.totalItems <= data.length,
        };
      });
    });
  };

  const deleteWorksitePayroll = useCallback((id) => {
    return new Promise(async (resolve, reject) => {
      await WorkService.post(
        "/api/v1/worksite/delete-worksite-payroll-category",
        { id }
      )
        .then((res) => {
          console.log(res);
          resolve(true);
        })
        .catch((err) => {
          reject(true);
        });
    });
  }, []);

  const onSelectorSubmit = async (selected) => {
    await new Promise((res) => {
      setSelector((prev) => ({
        ...prev,
        submitting: true,
      }));
      res(true);
    });

    const _selected = [...selected];
    let dbDeletedIds = [];

    data.selected_payroll_category.forEach((item) => {
      if (item.parent && !_selected.includes(item.id)) {
        dbDeletedIds.push(item.parent);
      }
    });

    if (dbDeletedIds.length) {
      Promise.all(dbDeletedIds.map((id) => deleteWorksitePayroll(id))).then(
        (res) => {
          console.log(res);
        }
      );
    }

    const filtered = data.selected_payroll_category.filter(
      (item) => !dbDeletedIds.includes(item.parent)
    );

    const fullObj = [];
    selector.data.forEach(({ id, name }) => {
      if (_selected.includes(id)) {
        // Check if the category is already added
        const isCategoryAdded = data.selected_payroll_category.some(
          (item) => item.id === id
        );

        if (!isCategoryAdded) {
          fullObj.push({
            id,
            name,
            parent: "",
          });
        }
      }
    });

    setData((prev) => ({
      ...prev,
      selected_payroll_category: fullObj.concat(filtered),
    }));

    setMultySelect((prev) => ({
      ...prev,
      selectedOption: selected,
    }));

    setSelector((prev) => ({
      ...prev,
      visible: false,
      submitting: false,
    }));
  };

  useEffect(() => {
    getWorksites();
  }, []);

  const formatCurrency = (value) => {
    // If the input is already a number, use it directly
    const numericValue =
      typeof value === "number"
        ? value
        : typeof value === "string"
        ? parseFloat(value.replace(/[^0-9.-]+/g, ""))
        : NaN;

    // Check if numericValue is a valid number
    const isValidNumber = !isNaN(numericValue) && isFinite(numericValue);

    if (!isValidNumber) {
      return value;
    }

    const formattedValue = new Intl.NumberFormat("en-AU", {
      style: "currency",
      currency: "AUD",
    }).format(numericValue);

    return formattedValue;
  };

  return (
    <form className="">
      <PopupSelector
        visible={selector.visible}
        data={selector.data}
        selected={multySelect.selectedOption}
        allLoaded={selector.allLoaded}
        onHide={toggleSelector}
        onSearch={getWorksites}
        onScrollEnd={(search) => {
          getWorksites(search, selector.data[selector.data.length - 1].id);
        }}
        onSubmit={onSelectorSubmit}
        // onCheck={onCheck}
        submitting={selector.submitting}
        getDataOnSubmit
      />
      <div className="lg:w-2/3 w-full mx-auto bg-white p-5 shadow-sm">
        <p className="fs-18 font-bold">Worksite</p>
        <div className="mt-5">
          {data?.id ? (
            <>
              <div>
                {data?.picture ? (
                  <div style={thumb}>
                    <div style={thumbInner}>
                      <img src={Fileurl + data?.picture} style={img} alt="im" />
                      <div
                        style={cancelsvg}
                        onClick={() => {
                          handle?.openImgModal(id);
                        }}
                      >
                        <ImageCancelSvg />
                      </div>
                    </div>
                  </div>
                ) : (
                  <ImageUploder files={files} setFiles={setFiles} />
                )}
              </div>
            </>
          ) : (
            <>
              <div className="">
                <ImageUploder files={files} setFiles={setFiles} />
              </div>
            </>
          )}
        </div>
        <div className="mt-5">
          <label className="block font-normal">Company</label>
          <Select
            showSearch
            placeholder="Select Category"
            optionFilterProp="children"
            className="w-full mt-4"
            name="company_id"
            ref={errors?.current["company_id"]}
            status={data?.errors?.company_id ? "error" : ""}
            filterOption={false}
            onFocus={handle?.getCompany}
            options={data?.companyOptions}
            value={data?.company_id}
            onChange={(e) => handle?.select(e, "company_id")}
            onSearch={handle?.getCompany}
          />
          {data?.errors?.company_id && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.company_id}
            </p>
          )}
        </div>
        <div className="mt-5">
          <label htmlFor="job-no" className="block font-normal">
            Job Number
          </label>
          <Input
            type="text"
            id="job-no"
            className={
              data?.errors?.job_no
                ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "w-full mt-4"
            }
            ref={errors?.current["job_no"]}
            name="job_no"
            value={data?.job_no}
            onChange={handle?.get}
          />
          {data?.errors?.job_no && (
            <p className="pt-2 text-red-600 text-sm">{data?.errors?.job_no}</p>
          )}
        </div>
        <div className="mt-5">
          <label htmlFor="name" className="block font-normal">
            Worksite Name
          </label>
          <Input
            type="text"
            id="name"
            className={
              data?.errors?.name
                ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "w-full mt-4"
            }
            ref={errors?.current["name"]}
            name="name"
            value={data?.name}
            onChange={handle?.get}
          />
          {data?.errors?.name && (
            <p className="pt-2 text-red-600 text-sm">{data?.errors?.name}</p>
          )}
        </div>
        <div className="mt-5">
          <label htmlFor="address" className="block font-normal">
            Address
          </label>
          <Input
            type="text"
            id="address"
            className={
              data?.errors?.address
                ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "w-full mt-4"
            }
            ref={errors?.current["address"]}
            name="address"
            value={data?.address}
            onChange={handle?.get}
          />
          {data?.errors?.address && (
            <p className="pt-2 text-red-600 text-sm">{data?.errors?.address}</p>
          )}
        </div>
        <div className="mt-5">
          <label className="block font-normal">Builder</label>
          <Input
            type="text"
            id="builder"
            className={
              data?.errors?.builder
                ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "w-full mt-4"
            }
            ref={errors?.current["builder"]}
            name="builder"
            value={data?.builder}
            onChange={handle?.get}
          />
          {data?.errors?.builder && (
            <p className="pt-2 text-red-600 text-sm">{data?.errors?.builder}</p>
          )}
        </div>
        <div className="mt-5">
          <label htmlFor="builder-contact-email" className="block font-normal">
            Builder Contact Email
          </label>
          <Input
            type="text"
            id="builder-contact-email"
            className={
              data?.errors?.builder_contact_email
                ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "w-full mt-4"
            }
            ref={errors?.current["builder_contact_email"]}
            name="builder_contact_email"
            value={data?.builder_contact_email}
            onChange={handle?.get}
          />
          {data?.errors?.builder_contact_email && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.builder_contact_email}
            </p>
          )}
        </div>
        <div className="mt-5">
          <label htmlFor="project_cost" className="block font-normal">
            Total Project Value
          </label>
          <Input
            type="text"
            id="project_cost"
            className={
              data?.errors?.project_cost
                ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "w-full mt-4"
            }
            ref={errors?.current["project_cost"]}
            name="project_cost"
            value={formatCurrency(data?.project_cost ?? "")}
            onChange={handle?.get}
          />
          {data?.errors?.project_cost && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.project_cost}
            </p>
          )}
        </div>
      </div>

      <div className="lg:w-2/3 mt-5 w-full mx-auto bg-white p-5 shadow-sm">
        <p className="fs-18 font-bold">Timeline</p>

        <div className="mt-5 flex justify-between items-center gap-4">
          <div className="lg:w-1/2 w-full">
            <label htmlFor="start-date" className="block font-normal">
              Start Date
            </label>
            <Input
              type="date"
              id="start-date"
              className={
                data?.errors?.start_date
                  ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "w-full mt-4"
              }
              ref={errors?.current["start_date"]}
              name="start_date"
              value={data?.start_date}
              onChange={handle?.get}
            />
            {data?.errors?.start_date && (
              <p className="pt-2 text-red-600 text-sm">
                {data?.errors?.start_date}
              </p>
            )}
          </div>
          <div className="lg:w-1/2 w-full">
            <label htmlFor="finish-date" className="block font-normal">
              Finish Date
            </label>
            <Input
              type="date"
              id="finish-date"
              className={
                data?.errors?.finish_date
                  ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "w-full mt-4"
              }
              ref={errors?.current["finish_date"]}
              name="finish_date"
              value={data?.finish_date}
              onChange={handle?.get}
            />
            {data?.errors?.finish_date && (
              <p className="pt-2 text-red-600 text-sm">
                {data?.errors?.finish_date}
              </p>
            )}
          </div>
        </div>
        <div className="mt-5">
          <label htmlFor="standard-start-time" className="block font-normal">
            Standard Start Time
          </label>
          <Input
            type="time"
            id="standard-start-time"
            className={
              data?.errors?.standard_start_time
                ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "w-full mt-4"
            }
            // ref={errors?.current["standard_start_time"]}
            name="standard_start_time"
            value={data?.standard_start_time}
            onChange={handle?.get}
          />
          {data?.errors?.standard_start_time && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.standard_start_time}
            </p>
          )}
        </div>
        <div className="mt-5">
          <label className="block font-normal">Standard End Time</label>
          <div className="flex justify-between items-center gap-3">
            <div className="lg:w-1/3 w-full">
              <label
                htmlFor="standard-end-weekend-time"
                className="block font-normal text-[12px] mt-3"
              >
                Week Day
              </label>
              <Input
                type="time"
                id="standard-end-weekend-time"
                className={
                  data?.errors?.standard_end_weekend_time
                    ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "w-full mt-4"
                }
                ref={errors?.current["standard_end_weekend_time"]}
                name="standard_end_weekend_time"
                value={data?.standard_end_weekend_time}
                onChange={handle?.get}
              />
              {data?.errors?.standard_end_weekend_time && (
                <p className="pt-2 text-red-600 text-sm">
                  {data?.errors?.standard_end_weekend_time}
                </p>
              )}
            </div>
            <div className="lg:w-1/3 w-full">
              <label
                htmlFor="standard-end-friday-time"
                className="block font-normal text-[12px] mt-3"
              >
                Friday
              </label>
              <Input
                type="time"
                id="standard-end-friday-time"
                className={
                  data?.errors?.standard_end_friday_time
                    ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "w-full mt-4"
                }
                ref={errors?.current["standard_end_friday_time"]}
                name="standard_end_friday_time"
                value={data?.standard_end_friday_time}
                onChange={handle?.get}
              />
              {data?.errors?.standard_end_friday_time && (
                <p className="pt-2 text-red-600 text-sm">
                  {data?.errors?.standard_end_friday_time}
                </p>
              )}
            </div>
            <div className="lg:w-1/3 w-full">
              <label
                htmlFor="standard-end-weekday-time"
                className="block font-normal text-[12px] mt-3"
              >
                Weekend
              </label>
              <Input
                type="time"
                id="standard-end-weekday-time"
                className={
                  data?.errors?.standard_end_weekday_time
                    ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "w-full mt-4"
                }
                ref={errors?.current["standard_end_weekday_time"]}
                name="standard_end_weekday_time"
                value={data?.standard_end_weekday_time}
                onChange={handle?.get}
              />
              {data?.errors?.standard_end_weekday_time && (
                <p className="pt-2 text-red-600 text-sm">
                  {data?.errors?.standard_end_weekday_time}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="lg:w-2/3 mt-5 w-full mx-auto bg-white p-5 shadow-sm">
        <p className="fs-18 font-bold">Payroll </p>

        <div className="mt-5">
          <label className="block font-normal">Allowed Task Deviation</label>
          <div className="flex justify-between items-center gap-3">
            <div className="lg:w-1/2 w-full">
              <label
                htmlFor="upper-control"
                className="block font-normal text-[12px] mt-3"
              >
                Upper Control
              </label>
              <Input
                type="text"
                id="upper-control"
                placeholder="1"
                className={
                  data?.errors?.upper_control
                    ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "w-full mt-4"
                }
                ref={errors?.current["upper_control"]}
                name="upper_control"
                value={data?.upper_control}
                onChange={handle?.get}
              />
              {data?.errors?.upper_control && (
                <p className="pt-2 text-red-600 text-sm">
                  {data?.errors?.upper_control}
                </p>
              )}
            </div>
            <div className="lg:w-1/2 w-full">
              <label
                htmlFor="lower-control"
                className="block font-normal text-[12px] mt-3"
              >
                Lower Control
              </label>
              <Input
                type="text"
                id="lower-control"
                placeholder="0"
                className={
                  data?.errors?.lower_control
                    ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "w-full mt-4"
                }
                ref={errors?.current["lower_control"]}
                name="lower_control"
                value={data?.lower_control}
                onChange={handle?.get}
              />
              {data?.errors?.lower_control && (
                <p className="pt-2 text-red-600 text-sm">
                  {data?.errors?.lower_control}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <label
              htmlFor="productivity-payroll-category"
              className="block font-normal"
            >
              Productivity Payroll Category
            </label>

            {/* icon with tooltip */}
            <Tooltip title="Something Here">
              <FaQuestionCircle className="text-[12px]" />
            </Tooltip>
          </div>
          <Input
            type="text"
            id="productivity-payroll-category"
            className={
              data?.errors?.productivity_payroll_category
                ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "w-full mt-4"
            }
            ref={errors?.current["productivity_payroll_category"]}
            name="productivity_payroll_category"
            value={data?.productivity_payroll_category}
            onChange={handle?.get}
          />
          {data?.errors?.productivity_payroll_category && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.productivity_payroll_category}
            </p>
          )}
        </div>
        <div className="mt-5">
          <div className="flex justify-between items-center">
            <label
              htmlFor="productivity-surplus-rate"
              className="block font-normal"
            >
              Productivity Surplus Rate
            </label>
            <Tooltip title="Something Here">
              <FaQuestionCircle className="text-[12px]" />
            </Tooltip>
          </div>
          <Input
            type="text"
            id="productivity-surplus-rate"
            className={
              data?.errors?.productivity_surplus_rate
                ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "w-full mt-4"
            }
            ref={errors?.current["productivity_surplus_rate"]}
            name="productivity_surplus_rate"
            value={data?.productivity_surplus_rate}
            onChange={handle?.get}
          />
          {data?.errors?.productivity_surplus_rate && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.productivity_surplus_rate}
            </p>
          )}
        </div>
      </div>

      <div className="lg:w-2/3 mt-5 w-full mx-auto bg-white shadow-sm">
        <div className="flex justify-between p-5">
          <div className="fs-18 font-bold">
            <div>
              <div className="pt-2">Payroll Categories</div>
            </div>
          </div>
          <div>
            <Button
              onClick={toggleSelector}
              className="flex justify-center items-center py-2 border border-[#111111] h-[42px]"
            >
              Add
              <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                <FaPlus />
              </div>
            </Button>
          </div>
        </div>

        {data?.selected_payroll_category?.length > 0 && (
          <>
            <div className="profile py-4 border-b-2 border-[#E9E9E9]" />

            <div className="p-5">
              {data?.selected_payroll_category?.map((payroll, index) => {
                return (
                  <>
                    <div className="py-4 flex justify-between">
                      <div>{payroll?.name}</div>
                      <div className="cursor-pointer">
                        <FaTrash
                          onClick={() =>
                            handle?.openModal({
                              id: payroll?.parent,
                              name: payroll?.name,
                              index: index,
                              payroll_id: payroll?.id,
                            })
                          }
                        />
                      </div>
                    </div>
                    {data?.selected_payroll_category?.length != index + 1 && (
                      <div className="profile border-b-2 border-[#E9E9E9]" />
                    )}
                  </>
                );
              })}
            </div>
          </>
        )}
      </div>

      <div className="lg:w-2/3 mt-5 w-full mx-auto bg-transparent py-5">
        <div className="text-right">
          <Button
            onClick={handle?.submit}
            className="btn-blue text-white h-[40px] ml-auto flex justify-center items-center w-1/4 bg-blue-disable"
            disabled={data?.isLoading}
          >
            {data?.isLoading ? <ButtonSpinner /> : "Save Changes"}
          </Button>
        </div>
      </div>

      {data?.isModal && (
        <DeleteModal
          setData={setData}
          data={data}
          action={
            data?.modal_selected_data?.name
              ? handle?.modalAction
              : removeImgPreview
          }
          close={handle?.closeModal}
        />
      )}
    </form>
  );
};

export default WorksiteForm;
