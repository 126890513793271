import React, { useState } from "react";
import { Modal, Checkbox, Button } from "antd";

const SuperannuationPayroll = ({ visible, onCancel, payroll, onSave }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (optionId) => {
    // Toggle the selection status based on whether the option is already selected or not
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(optionId)) {
        return prevSelectedOptions.filter((item) => item !== optionId);
      } else {
        return [...prevSelectedOptions, optionId];
      }
    });
  };

  return (
    <div>
      <Modal
        title="Add Payroll Allowance"
        visible={visible}
        onCancel={onCancel}
        footer={null}
      >
        <div>
          <div>
            {payroll?.map((option, index) => (
              <div
                key={index}
                className="flex justify-between p-1 border-b border-b-[#F0F0F0]"
              >
                {option.name}
                <Checkbox
                  checked={selectedOptions.includes(option)}
                  onChange={() => handleOptionChange(option)}
                  className="ml-3"
                ></Checkbox>
              </div>
            ))}
          </div>
          <div className="item mt-5 text-center">
            <Button
              className="btn btn-blue"
              onClick={() => onSave(selectedOptions)}
            >
              Add
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SuperannuationPayroll;
