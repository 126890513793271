import React from 'react'

const WorkingWorkerDefaultImg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="71.731" height="94.146" viewBox="0 0 71.731 94.146">
      <g id="Group_74729" data-name="Group 74729" transform="translate(-754 -526.816)">
        <g id="Group_74723" data-name="Group 74723">
          <path id="Subtraction_2" data-name="Subtraction 2" d="M3050.731,8805.146H2979v-8.966a22.416,22.416,0,0,1,22.416-22.416h26.9a22.416,22.416,0,0,1,22.416,22.416Zm-35.865-40.349a26.9,26.9,0,1,1,26.9-26.9A26.9,26.9,0,0,1,3014.865,8764.8Z" transform="translate(-2225 -8184.184)" fill="#efefef" />
          <rect id="Rectangle_2004" data-name="Rectangle 2004" width="8" height="22" rx="4" transform="translate(784 538)" fill="#fff" />
          <rect id="Rectangle_2005" data-name="Rectangle 2005" width="7" height="19" rx="3.5" transform="matrix(-0.259, 0.966, -0.966, -0.259, 804.164, 557.656)" fill="#fff" />
        </g>
      </g>
    </svg>
  )
}
export default WorkingWorkerDefaultImg