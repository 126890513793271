import Lexend from "../../../assets/fonts/lexend.ttf";
import RobotoLight from "../../../assets/fonts/RobotoLight.ttf";
import Roboto from "../../../assets/fonts/RobotoRegular.ttf";
import RobotoBold from "../../../assets/fonts/RobotoBold.ttf";
import RobotoBlack from "../../../assets/fonts/RobotoBlack.ttf";
import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: Roboto,
    },
    {
      src: RobotoLight,
      fontWeight: "light",
    },
    {
      src: RobotoBold,
      fontWeight: "bold",
    },
    {
      src: RobotoBlack,
      fontWeight: "heavy",
    },
  ],
});

const data = [
  {
    label:
      "Ensure provision of noggings for all wall & ceiling mounted items are installed in the correct position / height (TV, fans, sanitary hardware, joinery, dryers etc) and that they are fit for purpose",
    status: "Pending",
  },
  {
    label:
      "Ensure provision of noggings for all wall & ceiling mounted items are installed in the correct position / height (TV, fans, sanitary hardware, joinery, dryers etc) and that they are fit for purpose",
    status: "Pending",
  },
  {
    label:
      "Ensure provision of noggings for all wall & ceiling mounted items are installed in the correct position / height (TV, fans, sanitary hardware, joinery, dryers etc) and that they are fit for purpose",
    status: "Pending",
  },
  {
    label:
      "Ensure provision of noggings for all wall & ceiling mounted items are installed in the correct position / height (TV, fans, sanitary hardware, joinery, dryers etc) and that they are fit for purpose",
    status: "Pending",
  },
  {
    label:
      "Ensure provision of noggings for all wall & ceiling mounted items are installed in the correct position / height (TV, fans, sanitary hardware, joinery, dryers etc) and that they are fit for purpose",
    status: "Pending",
  },
];

const styles = StyleSheet.create({
  page: {
    paddingTop: "20px",
    paddingBottom: "80px",
    fontFamily: "Roboto",
  },
  container: {
    width: "100%",
    minHeight: "80px",
    padding: "20px",
    paddingTop: 0,
  },
  header: {
    position: "relative",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "20px",
    paddingBottom: "20px",
  },
  headerImage: {
    position: "absolute",
    height: "60px",
    width: "20%",
    left: 0,
    objectFit: "contain",
  },
  headerTitle: {
    fontSize: "20px",

    fontWeight: "heavy",
  },
  headerSubtitle: {
    fontSize: "14px",
    fontWeight: "light",
  },
  introContainer: {
    margin: "auto",
    width: "80%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "4px 0",
  },
  sectionTitle: {
    fontSize: "10px",
    color: "#f4a460",
    fontWeight: 700,
  },
  sectionValue: {
    fontSize: "10px",
  },
  table: {
    padding: 10,
    border: "1px solid black",
    margin: 10,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
  },
  tableHeader: {
    border: "1px solid black",
    backgroundColor: "rgb(242, 197, 159)",
    minHeight: 30,
    fontSize: 14,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "20%",
  },
  tableItems: {
    border: "1px solid black",
    minHeight: 30,
    fontSize: 10,
    padding: 10,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "20%",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: 10,
    objectFit: "contain",
  },
  inspectionImages: {
    width: "40%",
    height: "120px",
  },
  footerMask: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    marginTop: 10,
    width: "100%",
    height: "100px",
  },
  footerContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    backgroundColor: "rgb(242, 197, 159)",
    height: "60px",
  },
  footerHolder: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "80%",
    margin: "auto",
  },
  footerImage: {
    width: "25%",
    height: "100%",
  },
  footerCree8Text: {
    fontSize: "12px",
    fontWeight: "bold",
  },
});

export { styles, data };
