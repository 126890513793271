import React, { PureComponent } from "react";
import { PieChart, Pie, ResponsiveContainer } from "recharts";

const NewPieChart = ({ data, setData }) => {
  let graphData = [
    {
      name: "Active",
      value: data?.data?.task_summer?.active,
      fill: "#00A2FE",
    },
    {
      name: "Completed",
      value: data?.data?.task_summer?.completed,
      fill: "#50CD89",
    },
    {
      name: "Overdue",
      value: data?.data?.task_summer?.overdue,
      fill: "#F1416C",
    },
    {
      name: "Yet to Start",
      value: data?.data?.task_summer?.yet_to_start,
      fill: "#EEF0F5",
    },
  ];

  return (
    <div className="lg:flex justify-between items-center px-5">
      <div className="lg:w-2/3">
        <ResponsiveContainer className={"pieC"} width="100%" height="100%">
          <PieChart width={400} height={400}>
            <Pie
              data={graphData}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={70}
              outerRadius={100}
              fill="#82ca9d"
            />
          </PieChart>
        </ResponsiveContainer>
      </div>

      <div className="lg:w-1/2">
        <table className="w-full">
          <tbody>
            <tr>
              <td className="flex justify-start items-center">
                <div className="w-3 h-3 rounded-full bg-[#00A2FE] mr-3"></div>
                <p className="text-[#A1A1A1]">Active</p>
              </td>
              <td className="text-right">{data?.data?.task_summer?.active}</td>
            </tr>
            <tr>
              <td className="flex justify-start items-center">
                <div className="w-3 h-3 rounded-full bg-[#50CD89] mr-3"></div>
                <p className="text-[#A1A1A1]">Completed</p>
              </td>
              <td className="text-right">
                {data?.data?.task_summer?.completed}
              </td>
            </tr>
            <tr>
              <td className="flex justify-start items-center">
                <div className="w-3 h-3 rounded-full bg-[#F1416C] mr-3"></div>
                <p className="text-[#A1A1A1]">Overdue</p>
              </td>
              <td className="text-right">{data?.data?.task_summer?.overdue}</td>
            </tr>
            <tr>
              <td className="flex justify-start items-center">
                <div className="w-3 h-3 rounded-full bg-[#EEF0F5] mr-3"></div>
                <p className="text-[#A1A1A1]">Yet to Start</p>
              </td>
              <td className="text-right">
                {data?.data?.task_summer?.yet_to_start}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NewPieChart;
