import { Button, Input } from "antd";
import React from "react";
import { FaTimes } from "react-icons/fa";
import { ButtonSpinner } from "../../components/spinners";
import TextArea from "antd/es/input/TextArea";

const EditInspectionWorksite = ({
  setVisibleEdit,
  inspection,
  setInspection,
  handle,
  formref,
}) => {
  const closeModal = () => {
    setVisibleEdit(false);
  };
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="text-right">
          <button className="close-modal" onClick={closeModal}>
            <FaTimes />
          </button>
        </div>

        <div className="modal-body mt-3">
          <h2 className="text-xl font-bold">
            {inspection?.selectedData?.id ? "Editing New Inspection" : "Creating New Inspection"} 
          </h2>

          <form className="mt-4">
            <div className="item">
              <label htmlFor="inspection">Inspection Name</label>
              <Input
                type="text"
                className={
                  inspection?.selectedData?.errors?.inspection
                    ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "mt-3"
                }
                ref={formref?.current?.["inspection"]}
                name="inspection"
                id="inspection"
                value={inspection?.selectedData?.inspection}
                onChange={handle?.getForm}
                autoComplete="off"
              />
              {inspection?.selectedData?.errors?.inspection && (
                <div>
                  <p className="pt-2 text-red-600 text-sm">
                    {inspection?.selectedData?.errors?.inspection}
                  </p>
                </div>
              )}
            </div>
            <div className="item mt-5">
              <label htmlFor="description">Description </label>
              <TextArea rows={5}
                type="text"
                className={
                  inspection?.selectedData?.errors?.description
                    ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "mt-3"
                }
                ref={formref?.current?.["description"]}
                name="description"
                id="description"
                value={inspection?.selectedData?.description}
                onChange={handle?.getForm}
                autoComplete="off"
              />
              {inspection?.selectedData?.errors?.description && (
                <div>
                  <p className="pt-2 text-red-600 text-sm">
                    {inspection?.selectedData?.errors?.description}
                  </p>
                </div>
              )}
            </div>
            <div className="item mt-5 text-center">
              <Button
                className="btn btn-blue bg-blue-disable"
                onClick={handle?.submit}
                disabled={inspection?.selectedData?.isLoading}
              >
                {inspection?.selectedData?.isLoading ? (
                  <ButtonSpinner />
                ) : (
                    inspection?.selectedData?.id?"Save Changes":"Create"
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditInspectionWorksite;
