import React from "react";
import EmailHeader from "./EmailHeader";
import EmailRoutes from "./EmailRoutes";

const Email = () => {
  return (
    <div className="p-5">
      <EmailHeader />
      <EmailRoutes />
    </div>
  );
};

export default Email;
