import React from "react";
import { Fileurl } from "../../../config/url";
import DefaultImage from "../../../assets/images/Default/defaulCree8.png";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../utils/routes/useWindowDimensions";

const Timelines = ({ data }) => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const isChartValid = (chart) => {
    return (
      chart.remaining > 0 ||
      chart.worked > 0 ||
      chart.reserved > 0 ||
      chart.deficiet > 0 ||
      chart.lost > 0
    );
  };

  const getWidth = (value) => {
    if (value > 1 && value < 8) {
      return "8%";
    }

    return `${value}%`;
  };
  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 cursor-pointer">
      {data.map((item, index) => (
        <div
          className="bg-white dash-card rounded-lg"
          key={index}
          onClick={() =>
            navigate(`/admin/worksite/details/overviews/${item.id}`)
          }
        >
          <div
            className="stat d-flex"
            style={{
              overflowY: width <= 767 ? "scroll" : "visible",
              maxHeight: width <= 767 ? "50vh" : "auto",
            }}
          >
            {item.chart.map((chart, index) =>
              isChartValid(chart) ? (
                <div className="chart flex items-center" key={index}>
                  {chart.remaining > 0 && (
                    <div
                      style={{
                        width: getWidth(chart.remaining),
                      }}
                      className={`chart__item flex justify-center p-2 bg-[#40C057] text-white text-center`}
                    >
                      {chart.remaining}
                    </div>
                  )}
                  {chart.worked > 0 && (
                    <div
                      style={{ width: getWidth(chart.worked) }}
                      className={`chart__item p-2 bg-[#FFD43B] text-white text-center`}
                    >
                      {chart.worked}
                    </div>
                  )}
                  {chart.reserved > 0 && (
                    <div
                      style={{ width: getWidth(chart.reserved) }}
                      className={`chart__item p-2 bg-[#748FFC] text-white text-center`}
                    >
                      {chart.reserved}
                    </div>
                  )}
                  {chart.deficiet > 0 && (
                    <div
                      style={{ width: getWidth(chart.deficiet) }}
                      className={`chart__item p-2 bg-[#FF922B] text-white text-center`}
                    >
                      {chart.deficiet}
                    </div>
                  )}
                  {chart.lost > 0 && (
                    <div
                      style={{ width: getWidth(chart.lost) }}
                      className={`chart__item p-2 bg-[#F03E3E] text-white text-center`}
                    >
                      {chart.lost}
                    </div>
                  )}
                </div>
              ) : (
                <div className="chart w-100 bg-[#D5E9FE] items-center justify-center text-center p-1">
                  No data available for Displaying Stats
                </div>
              )
            )}
          </div>

          <div className="chart-details flex mt-3 text-sm justify-center items-center">
            <div className="item mx-3 flex justify-center items-center text-sm">
              <div className="w-4 h-4 bg-[#748FFC] mr-2"></div>
              <p className="text-gray-500">Remaining</p>
            </div>

            <div className="item mx-3 flex justify-center items-center text-sm">
              <div className="w-4 h-4 bg-[#FFD43B] mr-2"></div>
              <p className="text-gray-500">Worked</p>
            </div>

            <div className="item mx-3 flex justify-center items-center text-sm">
              <div className="w-4 h-4 bg-[#40C057] mr-2"></div>
              <p className="text-gray-500">Reserved</p>
            </div>

            <div className="item mx-3 flex justify-center items-center text-sm">
              <div className="w-4 h-4 bg-[#FF922B] mr-2"></div>
              <p className="text-gray-500">Deficit</p>
            </div>

            <div className="item mx-3 flex justify-center items-center text-sm">
              <div className="w-4 h-4 bg-[#F03E3E] mr-2"></div>
              <p className="text-gray-500">Lost</p>
            </div>
          </div>

          <div className="job-deails flex justify-start items-center my-4 px-5">
            {item.jobDetails.map((job, index) => (
              <div className="job flex items-center" key={index}>
                <div
                  className="job__img flex flex-1"
                  style={{
                    maxWidth: "40%",
                    height: "150px",
                  }}
                >
                  <img
                    src={Fileurl + job.img}
                    alt=""
                    onError={(e) => {
                      e.target.src = DefaultImage;
                      e.onerror = "";
                    }}
                  />
                </div>
                <div className="job__details flex-1 pl-4">
                  <p>
                    <span className="text-[#A1A1A1]">Job Number :</span>
                    &nbsp;
                    <span>{job.jobNumber}</span>
                  </p>

                  <p className="text-2xl mb-3">{job.jobName}</p>
                  <p>
                    <span className="text-[#A1A1A1]">Site Manager:</span>
                    <span>{job.siteManager}</span>
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="px-5">
            <table className="w-full ">
              {item.jobDetails.map((job, index) => (
                <tbody key={index}>
                  <tr>
                    <td className="border w-full p-2">
                      <span className="text-[#A1A1A1]">Areas</span>
                    </td>
                    <td className="border w-full p-2 text-right">
                      <span>{job.areas}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="border w-full p-2">
                      <span className="text-[#A1A1A1]">Workers</span>
                    </td>
                    <td className="border w-full p-2 text-right">
                      <span>{job.workers}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="border w-full p-2">
                      <span className="text-[#A1A1A1]">
                        Total Project Value
                      </span>
                    </td>
                    <td className="border w-full p-2 text-right">
                      <span>
                        $
                        {job.projectCost
                          ? Number(job.projectCost).toLocaleString("en-AU")
                          : "N/A"}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="border w-full p-2">
                      <span className="text-[#A1A1A1]">Total Spent</span>
                    </td>
                    <td className="border w-full p-2 text-right">
                      <span>${job.totalSpent}</span>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>

          <div className="productivity p-2 text-center my-3">
            Productivity Order
          </div>

          <table className="w-full">
            {item.productivity.map((productivity, index) => (
              <tbody key={index}>
                <tr>
                  <td className="border-none p-2 w-1/4 text-center">
                    <span className="text-[#A1A1A1]">Total</span>
                  </td>
                  <td className="border-none w-1/4 p-2 text-center">
                    <span className="text-[#A1A1A1]">Approved</span>
                  </td>
                  <td className="border-none w-1/4 p-2 text-center">
                    <span className="text-[#A1A1A1]">Active</span>
                  </td>
                  <td className="border-none w-1/4 p-2 text-center">
                    <span className="text-[#A1A1A1]">Remaining</span>
                  </td>
                </tr>
                <tr>
                  <td className="border-none w-1/4 p-2 text-center">
                    <span>{productivity.total}</span>
                  </td>
                  <td className="border-none w-1/4 p-2 text-center">
                    <span>{productivity.approved}</span>
                  </td>
                  <td className="border-none w-1/4 p-2 text-center">
                    <span>{productivity.active}</span>
                  </td>
                  <td className="border-none w-1/4 p-2 text-center">
                    <span>{productivity.remaining}</span>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      ))}
    </div>
  );
};

export default Timelines;
