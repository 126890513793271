import React from 'react'
import Login from '../pages/Site-manager/Login'

const SiteManagerLayout = () => {
    return (
        <div>
            <Login/>
        </div>
    )
}

export default SiteManagerLayout