import { Button, Input, Select } from "antd";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trash } from "../../../../../assets/svg";
import { FaPlus } from "react-icons/fa";
import { PayRollService } from "../../../../../config/axiosUrl";
import { throttler } from "../../../../../components/PaginationAPI/Pagination";
import InputError from "../../../../../components/InputError";
import PopupSelector from "./PopupSelector";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ButtonSpinner } from "../../../../../components/spinners";
import Confirmation from "../../../../../components/Confirmation";

const INPUT = ({ label, name, control, suffix, rules, type = "text" }) => (
  <Controller
    control={control}
    rules={rules}
    name={name}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <span className="flex flex-col">
        <label className="mt-3">{label}</label>
        <Input
          type={type}
          className="my-3 py-2"
          value={value}
          onChange={onChange}
          suffix={suffix}
          onWheel={(e) => e.target.blur()}
        />
        <InputError message={error?.message} />
      </span>
    )}
  />
);

const EditPayrollCategory = () => {
  const { id = "" } = useParams();

  const navigate = useNavigate();
  const formInit = useMemo(
    () => ({
      id: "",
      summary: "",
      name: "",
      display_in_summary_as: "",
      hourly_rate: "",
      export_as: "",
      duration_reaches: "",
      applys_on: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
      absence_id: [],
    }),

    []
  );

  const options = useMemo(() => ["Rate", "Hour", "Days"], []);
  const days = useMemo(
    () => [
      {
        label: "MON",
        key: "monday",
        id: "09500261-8945-4315-a129-18c73fe74978",
      },
      {
        label: "TUE",
        key: "tuesday",
        id: "1a02032e-d2dc-4f7a-8172-b17206685b6e",
      },
      {
        label: "WED",
        key: "wednesday",
        id: "03dfed20-5f8b-4abf-9b41-1074f69d9725",
      },
      {
        label: "THR",
        key: "thursday",
        id: "2716a797-caa0-444a-8915-a840ba36a5a8",
      },
      {
        label: "FRI",
        key: "friday",
        id: "44125b9c-5075-4bc3-a589-2b193cfb9f05",
      },
      {
        label: "SAT",
        key: "saturday",
        id: "f39ebd61-372b-4081-b7b6-6aa0d1ffcbae",
      },
      {
        label: "SUN",
        key: "sunday",
        id: "1c1b64be-77ea-49de-8c35-cc363a68c5f8",
      },
    ],
    []
  );

  const [workerAllowance, setWorkerAllowance] = useState({
    ...formInit,
  });
  const [absences, setAbsences] = useState({
    data: [],
    loading: true,
    visible: false,
    selected: [],
    assigned: [],
    isSubmitting: false,
    reget: false,
    allLoaded: false,
  });

  const [payroll, setPayroll] = useState({
    submitting: false,
  });
  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: formInit,
    values: { ...workerAllowance },
  });

  // Page Functions

  const toggleSelector = useCallback(() => {
    setAbsences((prev) => ({ ...prev, visible: !prev.visible }));
  }, []);
  const onCheck = useCallback((item) => {
    setAbsences((prev) => {
      let selected = prev.selected || [];

      let index = selected.findIndex((child) => child.id === item.id);

      if (index > -1) {
        selected.splice(index, 1);
      } else {
        selected.push(item);
      }

      return { ...prev, selected };
    });
  }, []);
  const selectorSubmit = useCallback(() => {
    const ids = absences.selected.map((item) => item.id);
    setValue("absenceIds", ids);
    setAbsences((prev) => ({
      ...prev,
      assigned: [...prev.selected],
      visible: false,
    }));
  }, [absences.selected, setValue]);

  const deleteAssignedAbsence = useCallback(
    (item) => {
      let ids = watch("absenceIds");
      ids.splice(ids.indexOf(item.id), 1);
      setValue("absenceIds", ids);

      setAbsences((prev) => {
        let selected = prev.selected;
        let index = selected.findIndex((data) => data?.id === item.id);

        if (index > -1) {
          selected.splice(index, 1);
        }

        return { ...prev, selected, assigned: [...selected] };
      });
    },
    [setValue, watch]
  );

  const apiFunctions = {
    get: useMemo(
      () => (res) => {
        if (id) {
          PayRollService.post(
            "api/v1/setting/payroll/get-payroll-category-allowance-by-id",
            { payroll_category_allowance_id: id }
          ).then((res) => {
            let {
              absence_id,
              applys_on,
              payroll_category_allowance_absence,
              id,
              ...data
            } = res?.data?.data?.data;
            const assigned = payroll_category_allowance_absence.map((item) => ({
              id: item.absence_id,
              name: item.name,
            }));
            setAbsences((prev) => ({
              ...prev,
              assigned,
              selected: assigned,
            }));
            setWorkerAllowance((prev) => ({
              ...prev,
              ...data,
              id,
              applys_on: applys_on[0],
              absenceIds: payroll_category_allowance_absence.map(
                (item) => item.absence_id
              ),
            }));
          });
        }
      },
      []
    ),
  };

  // API FUNCTIONS
  const getAbsence = useCallback((search = "", cursor = "") => {
    PayRollService.get(
      `/api/v1/setting/payroll/get-absences-data?search=${search}&cursor=${cursor}`
    ).then((res) => {
      setAbsences((prev) => {
        const filtered = search ? res?.data?.data?.data : [];
        if (!search) {
          const data = prev.data.concat(res?.data?.data?.data);
          const ids = [];

          data.forEach((item) => {
            if (!ids.includes(item.id)) {
              ids.push(item.id);
              filtered.push(item);
            }
          });
        }
        return {
          ...prev,
          data: filtered,
          loading: false,
          allLoaded: res?.data?.extra?.totalItems <= filtered.length,
        };
      });
    });
  }, []);

  const onSubmit = useCallback(async (data) => {
    await new Promise((res) => {
      setPayroll((prev) => ({
        ...prev,
        submitting: true,
      }));
      res(true);
    });
    PayRollService.post(
      "api/v1/setting/payroll/add-edit-payroll-category-allowances",
      {
        isSave: data?.id ? 0 : 1,
        ...data,
        hourly_rate: parseFloat(data?.hourly_rate),
        duration_reaches: parseInt(data?.duration_reaches),
        applys_on: [data.applys_on],
      }
    )
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message);
          navigate(-1);
        } else {
          toast.error(res?.data?.message);
        }
        setPayroll((prev) => ({
          ...prev,
          submitting: false,
        }));
      })
      .catch((err) => {
        setPayroll((prev) => ({
          ...prev,
          submitting: false,
        }));
        toast.error("Failed to save data");
      });
  }, []);

  useEffect(() => {
    getAbsence();
    apiFunctions.get();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-center my-8">
        <Confirmation
          open={absences.visible}
          title={`Are you sure want to delete this absences`}
          buttonText="Delete"
        />

        <PopupSelector
          title="Absences"
          visible={absences.visible}
          onHide={toggleSelector}
          loading={absences.loading}
          isSubmitting={false}
          data={absences.data}
          onSearch={getAbsence}
          buttonText="Assign"
          onSubmit={selectorSubmit}
          onCheck={onCheck}
          selected={absences.selected.map((item) => item.id)}
          getFullItem
          onScrollEnd={(search) => {
            getAbsence(absences.data[absences.data.length - 1].id);
          }}
          allLoaded={absences.allLoaded}
        />

        <div className="flex flex-col w-[60%]">
          <div className="bg-white  p-5">
            <h1 className="text-lg font-semibold">Editing Payroll Category</h1>
            <INPUT
              control={control}
              name="hourly_rate"
              rules={{
                required: "Please enter hourly rate",
              }}
              label="Hourly Rate"
              type="number"
            />
            <INPUT
              control={control}
              name="duration_reaches"
              rules={{
                required: "Field required please enter a value",
              }}
              label="This Payroll Category Applys when Shift Duration Reaches"
              type="number"
              suffix={<p className="text-[#757575] ">Hours</p>}
            />
            <div className="flex gap-8">
              <span className="flex-1">
                <INPUT
                  control={control}
                  name="summary"
                  label="Summary"
                  rules={{
                    required: "Please enter summary",
                  }}
                />
              </span>
              <span className="flex-1">
                <INPUT
                  control={control}
                  name="name"
                  label="Hourly Rate Name"
                  rules={{
                    required: "Please enter hourly rate name",
                  }}
                />
              </span>
            </div>
            <div className="flex  gap-8 mt-3">
              <div className="flex-1">
                <label>Export As</label>
                <Controller
                  name="export_as"
                  rules={{
                    required: "Please select a value",
                  }}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Fragment>
                      <div className="border border-[#E5E5E5] rounded-lg mt-3">
                        <Select
                          className="w-full py-1"
                          bordered={false}
                          value={value}
                          onChange={onChange}
                        >
                          {options.map((item) => (
                            <Select.Option value={item} key={item}>
                              {item}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      <InputError message={error?.message} />
                    </Fragment>
                  )}
                />
              </div>
              <div className="flex-1">
                <label>Display In Sumary As</label>
                <Controller
                  name="display_in_summary_as"
                  control={control}
                  rules={{
                    required: "Please select a value",
                  }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Fragment>
                      <div className="flex border border-[#E5E5E5] rounded-lg mt-3">
                        <Select
                          className="flex-1 min-w-[100px] py-1 "
                          bordered={false}
                          value={value}
                          onChange={onChange}
                        >
                          {options.map((item) => (
                            <Select.Option value={item} key={item}>
                              {item}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      <InputError message={error?.message} />
                    </Fragment>
                  )}
                />
              </div>
            </div>
            <div className="mt-5">
              <label>Applys on</label>

              <div className="my-5 flex justify-around flex-nowrap gap-5">
                {days.map((item) => {
                  let currVal = watch(`applys_on[${item.key}]`);
                  return (
                    <span
                      key={item.id}
                      className={`flex-1 text-center max-w-[65px] py-5 rounded-lg cursor-pointer ${
                        currVal
                          ? "bg-[#0F4C7D] text-white"
                          : "border border-[#707070]"
                      }`}
                      onClick={() => {
                        setValue(`applys_on[${item.key}]`, !currVal);
                      }}
                    >
                      {item.label}
                    </span>
                  );
                })}
              </div>
            </div>

            <h1 className="text-md">
              This Allowances is Still paid on following absent type Shifts
            </h1>
          </div>

          <hr className="border-b-[#E5E5E5] border-b-2" />

          <div className="bg-white p-5 flex flex-col items-center">
            {absences.assigned.map((item) => (
              <span
                className="border-b-2 border-b-[#E5E5E5] flex justify-between py-2 min-w-[90%] px-4"
                key={item.id}
              >
                <p>{item.name}</p>
                <Trash
                  className="cursor-pointer"
                  onClick={() => deleteAssignedAbsence(item)}
                />
              </span>
            ))}
            <div className=" w-full flex justify-between items-center">
              <p>Unapplied Absences</p>
              <Button
                className=" ml-auto flex justify-center items-center pl-1 px-2 border border-[#111111] h-[34px] my-5 "
                onClick={toggleSelector}
              >
                Assign Absences
                <div className="bg-orange-500 p-1 rounded-md text-white ml-3">
                  <FaPlus />
                </div>
              </Button>
            </div>
          </div>

          <button
            className="ml-auto my-8 mr-2 bg-[#0F4C7D] text-white px-8 py-3 rounded-lg"
            type="submit"
          >
            {payroll.submitting ? (
              <ButtonSpinner className="mx-8" />
            ) : (
              "Save Changes"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditPayrollCategory;
