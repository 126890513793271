import React from "react";
import DetailsTab from "./detailsTab";

const WorksiteTimehseets = () => {
  return (
    <div>
      <DetailsTab />
    </div>
  );
};

export default WorksiteTimehseets;
