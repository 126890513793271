import React, { Fragment, PureComponent, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function CustomToolTip({ active, payload, label }) {
  return (
    <div className="bg-[#F2F2F2] rounded-sm p-4 drop-shadow-lg shadow-lg">
      {payload.map((chartItem) => (
        <div className="flex items-center gap-1">
          {console.log(chartItem, "chartItem")}

          <span
            className={`w-3 h-3`}
            style={{
              backgroundColor: chartItem.stroke,
            }}
          />
          <p className="font-lexend text-sm">
            {chartItem?.name}:{chartItem?.value}
          </p>
        </div>
      ))}
    </div>
  );
}

/*class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          fontSize={14}
          textAnchor="middle"
          fill="#666"
          fontFamily='"Lexend", sans-serif'
          transform="rotate(0)"
        >
          {payload.value}
        </text>
        <text
          x={0}
          y={0}
          dy={32}
          fontSize={14}
          textAnchor="middle"
          fill="#666"
          fontFamily='"Lexend", sans-serif'
          transform="rotate(0)"
        >
          2023
        </text>
      </g>
    );
  }
}*/

const MultiLineChart = ({ data }) => {
  /* const graphData = [];
  data?.graphData?.map((graph, index) => {
    graphData?.push({
      name: graph?.name,
      daysWorked: graph?.daysWorked,
      workers: graph?.workers,
      taskCount: graph?.taskCount,
      cost: graph?.cost,
    });
  });
  graphData?.push({
    name: "Sep 2023",
    daysWorked: 100,
    workers: 200,
    taskCount: 300,
    cost: 400,
  }); */

  return (
    <Fragment>
      <ResponsiveContainer className={"multiline"} width="100%" height="100%">
        <LineChart width={500} height={340} data={data?.graphData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            className="font-lexend"
            height={60}
            padding={{ left: 30, right: 30, bottom: 30 }}
          />
          <YAxis />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="daysWorked"
            name="Days Worked"
            strokeWidth={2}
            stroke="#3366CC"
          />
          <Line
            type="monotone"
            dataKey="workers"
            name="Workers"
            strokeWidth={2}
            stroke="#DC3912"
          />
          <Line
            type="monotone"
            dataKey="taskCount"
            name="Task Count"
            strokeWidth={2}
            stroke="#FF9900"
          />
          <Line
            type="monotone"
            dataKey="cost"
            name="Cost"
            strokeWidth={2}
            stroke="#109618"
          />
        </LineChart>
      </ResponsiveContainer>
    </Fragment>
  );
};

export default MultiLineChart;
