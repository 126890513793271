import { Button } from "antd";
import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import {
  CreateEditHourlyRate,
  DeleteHourlyRateTask,
  GetAreaById,
} from "../../helper/worksite/worksiteDetails.js/areas";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import DeleteModal from "../../components/Modal/deleteModal";
import { useEffect } from "react";
const HourlyRateTaskTable = ({ data, setData, handle }) => {
  const [focusedIndex, setFocusedIndex] = useState(null);
  const { id } = useParams();
  const { area } = useParams();

  const initialModal = {
    isModal: false,
    modal_selected_data: {},
    modalTitle: "",
    isModalLoading: false,
  };
  const [modal, setModal] = useState(initialModal);

  useEffect(() => {
    const handleEnterKeyPress = (e) => {
      if (e.key === "Enter" && focusedIndex !== null) {
        const inputElement = document.activeElement;
        inputElement.blur();
      }
    };

    document.addEventListener("keydown", handleEnterKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, [focusedIndex]);

  const handleHourlRate = {
    getNetHour: (e, index) => {
      if (e?.target?.value >= 0) {
        let allData = [...data?.hourly_rate_tasks_estimations];
        allData[index].net_alloc_hours = e?.target?.value;

        setData({
          ...data,
          data: allData,
        });
      }
    },
    getNetPercentage: (e, index) => {
      if (e?.target?.value >= 0) {
        let allData = [...data?.hourly_rate_tasks_estimations];
        allData[index].net_alloc_per = e?.target?.value;

        setData({
          ...data,
          data: allData,
        });
      }
    },
    calculateHour: async (e, index, task_id) => {
      let allData = [...data?.hourly_rate_tasks_estimations];

      let net_hour;
      if (isNaN(parseFloat(e?.target?.value))) {
        net_hour = 0;
      } else {
        net_hour = parseFloat(e?.target?.value);
      }

      let net_percentage;
      if (isNaN(parseFloat(allData[index].net_alloc_per))) {
        net_percentage = 0;
      } else {
        net_percentage = parseFloat(allData[index].net_alloc_per);
      }

      if (net_hour > 0) {
        /*   allData[index].remaining = net_hour - net_hour * (net_percentage / 100);
        allData[index].reserved = net_hour * (net_percentage / 100);
        allData[index].hourly = net_hour - net_hour * (net_percentage / 100);
        allData[index].total = net_hour - net_hour * (net_percentage / 100); */

        const params = {
          id: task_id,
          /*  hourly: net_hour - net_hour * (net_percentage / 100),
          total: net_hour - net_hour * (net_percentage / 100),
          remaining: net_hour - net_hour * (net_percentage / 100),
          reserved: net_hour * (net_percentage / 100), */
          net_alloc_hours: net_hour,
          net_alloc_per: 0,
        };

        setData({
          ...data,
          animate_pulse: true,
        });

        let getData = await CreateEditHourlyRate(params);
        if (getData?.status) {
          const areaParams = {
            id: area,
          };

          // let areaGet = await GetAreaById(areaParams);
          allData[index].remaining = getData?.data?.data?.remaining;
          allData[index].reserved = getData?.data?.data?.reserved;
          allData[index].hourly = getData?.data?.data?.hourly;
          allData[index].total = getData?.data?.data?.total;

          // if (areaGet?.status) {
          setData({
            ...data,
            data: allData,
            animate_pulse: false,
          });
          // }
        } else {
          toast?.error(data?.message);
        }
      } else {
        allData[index].remaining = 0;
        allData[index].reserved = 0;
        allData[index].net_alloc_per = 0;
        allData[index].hourly = 0;
        allData[index].total = 0;

        setData({
          ...data,
          data: allData,
        });
      }
    },
    calculatePercentage: async (e, index, task_id) => {
      let allData = [...data?.hourly_rate_tasks_estimations];

      let net_hour;
      if (isNaN(parseFloat(allData[index].net_alloc_hours))) {
        net_hour = 0;
      } else {
        net_hour = parseFloat(allData[index].net_alloc_hours);
      }

      let net_percentage;
      if (isNaN(parseFloat(e?.target?.value))) {
        net_percentage = 0;
      } else {
        net_percentage = parseFloat(e?.target?.value);
      }

      if (net_hour > 0) {
        /* allData[index].remaining = net_hour - net_hour * (net_percentage / 100);
        allData[index].reserved = net_hour * (net_percentage / 100);
        allData[index].hourly = net_hour - net_hour * (net_percentage / 100);
        allData[index].total = net_hour - net_hour * (net_percentage / 100); */

        const params = {
          id: task_id,
          /*   hourly: net_hour - net_hour * (net_percentage / 100),
          total: net_hour - net_hour * (net_percentage / 100),
          remaining: net_hour - net_hour * (net_percentage / 100),
          reserved: net_hour * (net_percentage / 100),*/
          net_alloc_hours: 0,
          net_alloc_per: net_percentage,
        };

        setData({
          ...data,
          animate_pulse: true,
        });

        let getData = await CreateEditHourlyRate(params);
        if (getData?.status) {
          const areaParams = {
            id: area,
          };

          allData[index].remaining = getData?.data?.data?.remaining;
          allData[index].reserved = getData?.data?.data?.reserved;
          allData[index].hourly = getData?.data?.data?.hourly;
          allData[index].total = getData?.data?.data?.total;
          // allData[index].net_alloc_per = net_percentage;
          // let areaGet = await GetAreaById(areaParams);
          // if (areaGet?.status) {
          setData({
            ...data,
            data: allData,
            animate_pulse: false,
          });
          // }
        } else {
          toast?.error(data?.message);
        }
      } else {
        allData[index].remaining = 0;
        allData[index].reserved = 0;
        allData[index].net_alloc_per = 0;
        allData[index].hourly = 0;
        allData[index].total = 0;

        setData({
          ...data,
          data: allData,
        });
      }
    },
    openModal: async (data) => {
      setModal({
        ...modal,
        isModal: true,
        modal_selected_data: data,
        modalTitle: "Are You Sure You Wish To Delete This Hourly Rate Task ?",
      });
    },
    closeModal: async () => {
      setModal({
        ...modal,
        isModal: false,
      });
    },
    deleteTask: async () => {
      setModal({
        ...modal,
        isModalLoading: true,
      });

      const params = {
        id: modal?.modal_selected_data?.id,
      };

      let getData = await DeleteHourlyRateTask(params);

      if (getData?.status) {
        let getAreaById = await handle?.getAreaById();
        if (getAreaById) {
          toast?.success(data?.message);
          setModal(initialModal);
        }
      } else {
        toast?.error(data?.message);
        setModal({
          ...modal,
          isModalLoading: false,
        });
      }
    },
  };

  return (
    <>
      <table className="w-full zoom-80">
        <thead className="bg-[#E8F3FF] text-center">
          <tr className="text-left">
            <th className=" px-4 py-3 break-all">
              <div className="flex items-center justify-center"></div>
            </th>
            <th className=" px-4 py-3 break-all">
              <div className="flex items-center justify-center">Task</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Hourly</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">PO</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Total</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Remaining</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">$</div>
            </th>
            <th className=" pr-4 py-3 ">
              <div className="flex ">Net</div>
            </th>
            <th className=" px-4 py-3 " colSpan={2}>
              <div className="flex items-center justify-center">Reserved</div>
            </th>
            <th className=" px-4 py-3 " colSpan={2}>
              <div className="flex items-center justify-center">Actions</div>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {data?.hourly_rate_tasks_estimations?.map((item, index) => {
            return (
              <>
                <tr key={index} className="border-b border-[#F0F0F0]">
                  <td className="px-4 py-3 ">
                    <div className="flex items-center justify-end text-xs text-gray-400 mt-1">
                      {item?.hourly_rate_task?.abbreviation}
                    </div>
                  </td>
                  <td className="px-6 py-3  ">
                    <div className="flex items-center justify-start text-lg">
                      {item?.hourly_rate_task?.name}
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center">
                      0&nbsp;&nbsp;
                      <span className="text-slate-500">
                        {" "}
                        {item?.hourly?.toFixed(2)}
                      </span>
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center">
                      0&nbsp;&nbsp;
                      <span className="text-slate-500">
                        {" "}
                        {item?.po?.toFixed(2)}
                      </span>
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div
                      className="flex items-center justify-center"
                      title={`Spent Value: ${item?.spent?.toFixed(2) || 0}`}
                    >
                      {item?.spent?.toFixed(2)}
                      <span
                        className="text-slate-500"
                        title={`Remaining Value: ${item?.total || 0}`}
                      >
                        {"/"}
                        {item?.total?.toFixed(2)}
                      </span>
                    </div>
                  </td>
                  <td className="px-4 py-3 break-all">
                    <div
                      className="flex items-center justify-center"
                      title="Remaining value minus spent"
                    >
                      {(
                        item?.remaining?.toFixed(2) - item?.spent?.toFixed(2)
                      ).toFixed(2)}
                      &nbsp;hrs
                    </div>
                  </td>
                  <td className="px-4 py-3 break-all">
                    <div className="flex items-center justify-center">0</div>
                  </td>
                  <td className="py-3  pr-3">
                    <div className="flex items-center justify-center text-sm border">
                      <input
                        type="number"
                        value={item?.net_alloc_hours}
                        className="w-24 p-1"
                        disabled={data?.animate_pulse}
                        onChange={(e) => handleHourlRate?.getNetHour(e, index)}
                        onWheel={(e) => e.target.blur()}
                        onBlur={(e) =>
                          handleHourlRate?.calculateHour(e, index, item?.id)
                        }
                        onFocus={() => setFocusedIndex(index)}
                      />
                    </div>
                  </td>
                  <td className="pl-4 py-3 ">
                    <div className="flex items-center justify-center text-sm border">
                      <input
                        type="number"
                        value={item?.net_alloc_per}
                        disabled={data?.animate_pulse}
                        className="w-24 pt-1 pb-1"
                        onChange={(e) =>
                          handleHourlRate?.getNetPercentage(e, index)
                        }
                        onWheel={(e) => e.target.blur()}
                        onBlur={(e) =>
                          handleHourlRate?.calculatePercentage(
                            e,
                            index,
                            item?.id
                          )
                        }
                        onFocus={() => setFocusedIndex(index)}
                      />
                      %
                    </div>
                  </td>
                  <td className="px-3 py-3">
                    <div className="flex items-center justify-center">
                      {item?.reserved !== undefined
                        ? item.reserved?.toFixed(1)
                        : ""}
                      &nbsp;hrs
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center">
                      {!data?.allProdOrdTaskIds?.includes?.(item?.id) && (
                        <Button
                          className="text-[#0F4C7D] border-none pr-0"
                          onClick={() =>
                            handleHourlRate?.openModal({
                              id: item?.id,
                              name: item?.hourly_rate_task?.name,
                            })
                          }
                          disabled={data?.animate_pulse}
                        >
                          <FaTrash />
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>

      {modal?.isModal && (
        <DeleteModal
          setData={setModal}
          data={modal}
          action={handleHourlRate?.deleteTask}
          close={handleHourlRate?.closeModal}
        />
      )}
    </>
  );
};

export default HourlyRateTaskTable;
