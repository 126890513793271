import React, { Fragment, useRef } from "react";
import TopBar from "../../../../../shared/TopBar/TopBar";
import { Button, Select } from "antd";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Receipt } from "../../../../../assets/svg";
import "./../../../../../assets/styles/tableStyle.css";
import moment from "moment";
import { AiOutlinePlusCircle } from "react-icons/ai";

const TimesheetDetailedView = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const containerRef = useRef(null);

  let sevenLenArr = Array.from(Array(7).keys());

  let tenArr = Array.from(Array(10).keys());

  function horizontalScroller(e) {
    // if (
    //   containerRef.current.clientHeight > containerRef.current.scrollTop &&
    //   (e.deltaX < 0 || e.deltaX < 0)
    // ) {
    //   containerRef.current.scrollTop += 100;
    //   return;
    // }
    // if (tableRef.current.scrollLeft === 0 && e.deltaY < 0) {
    //   containerRef.current.scrollTop -= 100;
    //   return;
    // }
    if (e.deltaY > 0) {
      tableRef.current.scrollLeft += 100;
      e.preventDefault();
    } else {
      tableRef.current.scrollLeft -= 100;
      e.preventDefault();
    }
  }

  return (
    <div
      className="min-h-screen flex flex-col content-holder"
      ref={containerRef}
    >
      <TopBar />
      <div className="bg-white">
        <div className="px-8">
          <span
            className="inline-flex items-center gap-1 py-5 select-none cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <FaArrowLeft />
            <p className="font-[#0F4C7D]">Return to Summary</p>
          </span>
          <div className="flex gap-32 items-center py-3">
            <div className="flex flex-col flex-1">
              <label>Worksite</label>
              <Select />
            </div>
            <div className="flex flex-col flex-1">
              <label>Week</label>
              <Select />
            </div>
            <div className="flex flex-col flex-1">
              <label>Employment Filter</label>
              <Select />
            </div>
          </div>
          <span className="font-lexend">#3553</span>
          <div className="flex justify-between">
            <span className="font-lexend">Management Ink Pty Ltd</span>
            <container className="flex gap-3">
              <Button className="flex items-center gap-1">
                <Receipt />
                Print
              </Button>
              <Button>Export Terminated</Button>
              <Button>Export Active</Button>
            </container>
          </div>
        </div>
        <div
          className="timesheet-detailed-table-container"
          ref={tableRef}
          onWheel={horizontalScroller}
        >
          <table className="timesheet-detailed-table ">
            <thead className="timesheet-detailed-table-head-container">
              <tr className="timesheet-detailed-table-main-head">
                <th>
                  <div className="w-[5vw] font-semibold">Name</div>
                </th>
                <th>
                  <div className="w-[8vw] font-semibold text-center">Rate</div>
                </th>
                <th>
                  <div className="w-[5vw] font-semibold">Job</div>
                </th>

                {sevenLenArr.map((_, index) => (
                  <th colSpan={3}>
                    {moment().add(index, "days").format("DD MMM ddd")}
                  </th>
                ))}

                <th colSpan={5}>Totals</th>

                <th colSpan={11}>Allowances</th>
                <th colSpan={1}>Accruals</th>
              </tr>
              <tr className="timesheet-detailed-table-secondary">
                <th></th>
                <th></th>
                <th></th>

                {sevenLenArr.map((item) => (
                  <Fragment>
                    <th>
                      <p className="pl-2 font-semibold">N</p>
                    </th>
                    <th>OT</th>
                    <th>
                      <p className="pr-2 font-semibold">DT</p>
                    </th>
                  </Fragment>
                ))}

                <th>
                  <p className="pl-2 font-semibold">GT</p>
                </th>
                <th>N</th>
                <th>OT</th>
                <th>DT</th>
                <th>
                  <p className="pr-2 font-semibold">TOD</p>
                </th>

                <th>
                  <p className="pl-3 font-semibold">APA</p>
                </th>
                <th>FT</th>
                <th>Laser</th>
                <th>Multi</th>
                <th>PT</th>
                <th>Site</th>
                <th>xBERT</th>
                <th>xBEWT</th>
                <th>xCIPQ</th>
                <th>xSCo</th>
                <th>
                  <p className="pr-2 font-semibold">xSuper</p>
                </th>

                <th>
                  <div className="w-[10vw] font-semibold">
                    RDO(Accr & Taken)
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="timesheet-detailed-table-body">
              {tenArr.map((item) => (
                <tr className="border border-b-[#E9E9E9] border-2">
                  <td className="">Adamson</td>
                  <td>CW3 - Casual (AI)</td>
                  <td>8888</td>
                  {sevenLenArr.map((item) => (
                    <Fragment>
                      <td>
                        {/* N */}
                        <p className="pl-2">8</p>
                      </td>
                      {/* OT */}
                      <td>1</td>
                      <td>
                        {/* DT */}
                        {false ? (
                          <p className="pr-2"></p>
                        ) : (
                          <div className="flex justify-center items-center cursor-pointer">
                            <AiOutlinePlusCircle />
                          </div>
                        )}
                      </td>
                    </Fragment>
                  ))}

                  {/* GT */}
                  <td>
                    <p className="pl-2">44</p>
                  </td>
                  {/* N */}
                  <td>40</td>
                  {/* OT */}
                  <td>4</td>
                  {/* DT */}
                  <td></td>
                  {/* TOD */}
                  <td>
                    <p className="pr-2">5</p>
                  </td>
                  {/* APA */}
                  <td>
                    <p className="pl-3"></p>
                  </td>
                  {/* FT */}
                  <td>5</td>
                  {/* Laser */}
                  <td></td>
                  {/* Multi */}
                  <td>1.64</td>
                  {/* PT */}
                  <td></td>
                  {/* Site */}
                  <td></td>
                  {/* xBERT */}
                  <td>45</td>
                  {/* xBEWT */}
                  <td>5</td>
                  {/* xCIPQ */}
                  <td>47</td>
                  {/* xSCo */}
                  <td></td>
                  {/* xSuper */}
                  <td>
                    <p className="pr-2"></p>
                  </td>
                  {/* RDO(Accr & Taken) */}
                  <td>-1.6</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TimesheetDetailedView;
