import { Button, Modal } from "antd";
import React from "react";
import { ButtonSpinner } from "./spinners";

const Confirmation = ({
  open,
  onCancel,
  title,
  onConfirm,
  detailsTitle = "Name",
  details,
  buttonText = "Delete",
  isSubmitting,
}) => {
  return (
    <Modal footer={[]} open={open} onCancel={onCancel} centered>
      {open && (
        <div className="mt-3">
          <h2 className="text-xl font-bold">{title}</h2>

          <form className="mt-4">
            <div className="item mt-8">
              <table>
                <tbody>
                  <tr>
                    <td className="font-bold">{detailsTitle}:</td>
                    <td className="pl-5">{details}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="item mt-8 text-center">
              <Button
                className="btn btn-blue bg-blue-disable w-1/5"
                disabled={isSubmitting}
                onClick={onConfirm}
              >
                {isSubmitting ? <ButtonSpinner /> : buttonText}
              </Button>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
};

export default Confirmation;
