import React from "react";
import { Button } from "antd";
import { FaSearch, FaPlus } from "react-icons/fa";
import { Trash, Edit } from "../../../../assets/svg";
import SuperannuationModal from "./SuperannuationModal";
import { Fragment } from "react";
import { useState } from "react";
import DeleteExpenses from "./DeleteExpenses";
import { useMemo } from "react";
import { PayRollService } from "../../../../config/axiosUrl";
import { useEffect } from "react";
const Superannuation = ({ ...type }) => {
  console.log("the data", type);
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [superann, setSuperann] = useState([]);
  console.log("super ann", superann);
  const [deleteModal, setDeleteModal] = useState({
    visible: false,
    current: {},
    isDeleting: false,
  });

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCreateClick = () => {
    setEditData(null);
    setId(null);
    setIsEditing(false);
    setModalVisible(true);
  };

  const handleEditClick = (editing, editId) => {
    setIsEditing(true);
    setEditData(editing); // Set edit data
    setModalVisible(true);
    setId(editId?.toString());
  };

  const handleModalCancel = () => {
    setEditData(null);
    setModalVisible(false);
  };

  const handleSave = (formData) => {
    console.log("theformdatasupp", formData);
    if (isEditing) {
      // If editing, replace the existing data
      setSuperann((prevData) =>
        prevData.map((item) =>
          item === editData ? { ...item, ...formData } : item
        )
      );
    } else {
      // If creating, add a new entry
      setSuperann((prevData) => [...prevData, formData]);
    }
    let params = {
      expense_type_id: type._id,
      name: formData.name,
      rate: formData.rate,
      percentage: formData.percentage,
      payroll_category_id: formData.payroll_category_id,
      export_as: formData.export_as,
      display_in_summary_as: formData.display_in_summary_as,
      allowance_ids: formData.allowance_ids,
      id: id || "",
    };

    console.log("the patamas, ", params);
    const superannCreate = () => {
      PayRollService.post(
        `api/v1/payroll/setting/create-edit-employer-expenses?`,
        params
      )
        .then((res) => {
          superAnnList();
          // You can add more logic based on the response if needed
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error:", error);
        });
    };

    setModalVisible(false);
    superannCreate();
  };

  const superAnnList = () => {
    let params = {
      expense_type_id: type._id,
      search: searchQuery,
    };

    PayRollService.post(
      `api/v1/payroll/setting/employer-expenses-list`,
      params
    ).then((res) => {
      setSuperann(res?.data?.data?.data);
    });
  };

  useEffect(() => {
    superAnnList();
  }, [searchQuery, type._id]);

  const handleDeleteClick = (itemId, itemname) => {
    setDeleteModal({
      visible: true,
      current: itemId,
      isDeleting: false,
    });
  };

  const handleDeleteConfirm = () => {
    const params = {
      id: deleteModal.current._id,

      // Add other parameters needed for deletion
    };
    console.log("theheeh", params);
    PayRollService.post(
      `api/v1/payroll/setting/delete-employer-expenses`,
      params
    )
      .then((res) => {
        // Handle success, update the component state
        setSuperann((prevData) =>
          prevData.filter((item) => item._id !== deleteModal.current._id)
        );
        superAnnList();
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      })
      .finally(() => {
        // Close the delete modal
        setDeleteModal({
          visible: false,
          current: {},
          isDeleting: false,
        });
      });
  };

  const handleDeleteCancel = () => {
    // Close the delete modal without deleting
    setDeleteModal({
      visible: false,
      current: {},
      isDeleting: false,
    });
  };

  return (
    <>
      <div className="flex justify-between items-center bg-white p-5">
        <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/3 my-2 bg-white">
          <FaSearch className="ml-2" />
          <input
            placeholder="Search by Superannuation Name"
            className="h-[31px] bg-transparent text-sm w-full px-2"
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
        <Button
          onClick={handleCreateClick}
          className="flex justify-center items-center py-3 border border-[#111111] h-[42px]"
        >
          Create New
          <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
            <FaPlus />
          </div>
        </Button>
      </div>
      <table className="w-full">
        <thead className="bg-[#E8F3FF] text-center ">
          <tr className="text-left">
            <th className=" px-4 py-3 w-30 ">
              <div className="flex items-center justify-center ">
                Superannuation Name
              </div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">
                Rate / Percentage
              </div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Actions</div>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {superann?.length === 0 ? (
            <tr>
              <td colSpan="3" className="text-center py-4">
                No Data Available
              </td>
            </tr>
          ) : (
            superann?.map((item, index) => (
              <Fragment key={item.id}>
                <tr className="border-b-2 border-[#F0F0F0]">
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center">
                      {item.name}
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center">
                      {item.rate !== 0 && <span>{item.rate} </span>}
                      {item.percentage !== 0 && <span>{item.percentage}</span>}
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex gap-6 items-center justify-center ">
                      <button
                        className="text-black bg-transparent border-none"
                        onClick={() => handleEditClick(item, item._id)}
                      >
                        <Edit title="Edit" />
                      </button>
                      <button
                        className="text-black bg-transparent border-none"
                        onClick={() => handleDeleteClick(item)}
                      >
                        <Trash title="Delete" />
                      </button>
                    </div>
                  </td>
                </tr>
              </Fragment>
            ))
          )}
        </tbody>
      </table>
      <SuperannuationModal
        key={isEditing ? "edit" : "create"}
        visible={modalVisible}
        onCancel={handleModalCancel}
        isEditing={isEditing}
        editData={editData}
        onSave={handleSave}
        type={type}
        id={id}
      />

      <DeleteExpenses
        visible={deleteModal.visible}
        onCancel={handleDeleteCancel}
        title="Confirm Deletion"
        itemName={deleteModal.current.name}
        onConfirm={handleDeleteConfirm}
        buttonText="Delete"
      />
    </>
  );
};

export default Superannuation;
