import React, { useCallback, useEffect, useState } from "react";
import { Button, Checkbox, Input, Modal } from "antd";
import { FaSearch } from "react-icons/fa";
import { Fragment } from "react";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import { Spinner } from "../../../../../assets/svg";
import { ButtonSpinner } from "../../../../../components/spinners";

const PopupSelector = ({
  title,
  visible,
  onHide,
  loading,
  allLoaded,
  isSubmitting,
  data,
  onSearch,
  buttonText = "Assign",
  onSubmit,
  onCheck,
  selected = [],
  getFullItem,
  searchPlaceholder = "Search Payroll Category Name",
  onScrollEnd,
  getDataOnSubmit,
}) => {
  console.log("the popup data is", data);
  const [checked, setChecked] = useState({ data: [], ids: [] });
  const [selectorState, setSelector] = useState({
    loading: false,
    search: "",
  });

  function onScroll(e) {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      onScrollEnd();
    }
  }
  const onSearchHelper = function (search) {
    if (onSearch) {
      onSearch(search);
    }
  };

  const _onCheck = (item) => {
    if (getDataOnSubmit) {
      setChecked((prev) => {
        const index = prev.ids.indexOf(item.id);
        if (index > -1) {
          prev.data.splice(index, 1);
          prev.ids.splice(index, 1);
        } else {
          prev.data.push(item);
          prev.ids.push(item.id);
        }

        return { ...prev };
      });
    } else {
      onCheck(getFullItem ? item : item.id);
    }
  };

  useEffect(() => {
    onSearchHelper(selectorState.search);
  }, [selectorState.search]);

  useEffect(() => {
    if (selectorState.loading) {
      setSelector((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  }, [data]);

  useEffect(() => {
    if (!visible) {
      setChecked((prev) => ({
        ...prev,
        data: [],
        ids: [],
      }));
    } else {
      setChecked((prev) => ({
        ...prev,
        ids: [...selected],
      }));
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      onCancel={onHide}
      footer={[]}
      centered
      className="min-w-[35vw]"
    >
      {loading ? (
        <TextShimmer data={{ gap: 15, line: 20 }} />
      ) : (
        <Fragment>
          <h1 className="font-semibold text-xl">{title}</h1>
          <Input
            className="border border-black mt-3 py-2"
            prefix={<FaSearch fill="#A1A1A1" />}
            placeholder={searchPlaceholder}
            onChange={({ target: { value } }) => {
              setSelector((prev) => ({
                ...prev,
                search: value,
              }));
            }}
          />
          <div
            className="mt-4 max-h-[300px] overflow-y-scroll"
            onScroll={onScrollEnd && !allLoaded ? onScroll : () => {}}
          >
            {data?.map((item) => (
              <div
                className="flex justify-between p-1 border-b border-b-[#F0F0F0]"
                key={item.id}
              >
                <p
                  className="font-lexend text-md"
                  title="Payroll Category Name and Hourly Rate"
                >
                  {item.name} - {item?.hourly_rate_name} ({item?.hourly_rate})
                </p>
                <Checkbox
                  onChange={({ target: { checked } }) =>
                    _onCheck(item, checked)
                  }
                  checked={
                    getDataOnSubmit
                      ? checked.ids.includes(item.id)
                      : selected.includes(item.id)
                  }
                />
              </div>
            ))}
            {!allLoaded && (
              <div className="mt-2">
                <ButtonSpinner />
              </div>
            )}
          </div>
          <div className="item mt-5 text-center">
            <Button
              className="min-w-[100px] py-1 btn btn-blue"
              onClick={() =>
                onSubmit(
                  getDataOnSubmit
                    ? getFullItem
                      ? checked.data
                      : checked.ids
                    : null
                )
              }
            >
              {isSubmitting ? <Spinner /> : buttonText}
            </Button>
          </div>
        </Fragment>
      )}
    </Modal>
  );
};

export default PopupSelector;
