import React, { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import {
  FaArrowLeft,
  FaArrowRight,
  FaEdit,
  FaInfo,
  FaInfoCircle,
  FaPlus,
  FaSearch,
  FaTrashAlt,
} from "react-icons/fa";
import EditInspection from "../Modal/EditInspection";
import DeleteInspection from "../Modal/DeleteInspection";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import {
  CreateEditInspection,
  GetAreaById,
  GetInspection,
  InspectionDelete,
  InspectionStatusChange,
} from "../../helper/worksite/worksiteDetails.js/areas";
import { Button, Select, Tooltip } from "antd";
import { toast } from "react-toastify";
import { ButtonSpinner } from "../../components/spinners";
import { Fileurl } from "../../config/url";
import { TextShimmer } from "../../components/shimmer/shimmer";
import { NoTableOption } from "../../components/noOption/noOption";
import { NoSearchTableSvg } from "../../assets/svg/tableDefaul";
import { TableDefaultPage } from "../../components/default/defaultPage";
import AreasDefaultSvg from "../../assets/svg/areasDefault";
import { CheckInduction } from "../../helper/induction/induction";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  CameraActive,
  CameraInactive,
  Written,
  WrittenBlack,
} from "../../../src/assets/svg";
import { Modal } from "antd";

const InspectionTable = ({ setVisibleInspection }) => {
  const { id, area, taskid, type } = useParams();
  let navigate = useNavigate();
  const [selectedNote, setSelectedNote] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const data = [
    {
      id: 1,
      inspection: "Carpentry with Polishing",
      status: "Completed",
      image: "Img.jpeg",
    },
    {
      id: 1,
      inspection: "Carpentry with Polishing",
      status: "Completed",
      image: "Img.jpeg",
    },
  ];

  const initialInspection = {
    data: [],
    extra: {},
    allCheck: false,
    selectedData: {
      id: "",
      inspection: "",
      description: "",
      type: "",
      isSave: "",
      errors: {
        inspection: "",
        description: "",
      },
      isLoading: false,
    },
    search: "",
    isShimmer: true,
  };

  const initialTasks = {
    data: [],
    options: [],
    selectedTask: "",
  };

  let ref = {
    inspection: "",
    description: "",
  };

  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };

  const [inspection, setInspection] = useState(initialInspection);
  const [animatePulse, setAnimatePulse] = useState(false);
  const [tasks, setTasks] = useState(initialTasks);

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  let path = useLocation();
  let formref = useRef([]);

  Reflect.ownKeys(ref)?.forEach((value, index) => {
    formref.current[value] = React?.createRef();
  });

  const visibleEditModal = () => {
    setVisibleEdit(true);
  };

  const visibleDeleteModal = () => {
    setVisibleDelete(true);
  };

  const handleInspection = {
    fetch: async () => {
      const params = {
        cursor: inspection?.extra?.cursor,
        search: inspection?.search,
        type: type,
        productivity_orders_id: type == "po" && taskid,
        hourly_rate_tasks_estimations_id: type == "hourly" && taskid,
      };

      if (inspection?.data?.length != inspection?.extra?.totalItems) {
        let data = await GetInspection(params);
        console.log(data);
        if (data?.status) {
          let allData = [...inspection?.data];
          data?.data?.data?.forEach((inspection, index) => {
            allData?.push(inspection);
          });
          setInspection({
            ...inspection,
            data: allData,
            extra: { ...data?.extra },
            search: params?.search,
          });
        }
      }
    },
    getSelectedData: (id, type) => {
      let allData = inspection?.data?.length > 0 ? [...inspection?.data] : [];

      let allIds = [];
      inspection?.data?.forEach((inspect, index) => {
        allIds?.push(inspect?.id);
      });

      let selectedData = allData?.[allIds?.indexOf(id)];

      setInspection({
        ...inspection,
        selectedData: {
          id: selectedData?.id,
          inspection: selectedData?.inspection,
          description: selectedData?.description,
          type: type,
          isSave: 0,
          errors: {
            inspection: "",
            description: "",
          },
          isLoading: false,
        },
      });
      if (type == "EDIT") {
        setVisibleEdit(true);
      } else {
        setVisibleDelete(true);
      }
    },
    back: () => {
      if (path?.pathname?.includes("/site-manager/worksite/details/task/")) {
        setVisibleInspection(false);
        navigate(`/site-manager/worksite/details/task/${id}/1`);
      } else {
        navigate(`/admin/worksite/get-inspection/${id}/${area}`);
      }
    },
    taskSearch: async (e) => {
      const params = {
        search: e,
      };

      let data = await handleInspection?.getTasks(params);
    },
    getTasks: async (taskData) => {
      let defaultOption = [{ value: "loading", label: <ButtonSpinner /> }];

      setTasks({
        ...tasks,
        data: [],
        options: defaultOption,
      });

      const params = {
        id: area,
        worksite_id: id,
        search: taskData?.search,
      };
      let data = await GetAreaById(params);

      let allData = [];
      let allOptions = [];

      data?.data?.data?.hourly_rate_tasks_estimations?.forEach(
        (task, tindex) => {
          allData?.push({
            ...task,
            type: "hourly",
            id: task?.id,
            abbreviation: task?.hourly_rate_task?.abbreviation,
            name: task?.hourly_rate_task?.name,
          });

          allOptions?.push({
            value: task?.id,
            label:
              task?.hourly_rate_task?.abbreviation +
              " " +
              task?.hourly_rate_task?.name,
          });
        }
      );

      data?.data?.data?.productivity_orders?.forEach((task, tindex) => {
        allData?.push({
          ...task,
          type: "po",
          id: task?.id,
          abbreviation: task?.code,
          name: task?.name,
        });

        allOptions?.push({
          value: task?.id,
          label: task?.code + " " + task?.name,
        });
      });

      if (data?.status) {
        setTasks({
          ...tasks,
          data: allData,
          options: allOptions,
        });
      }
    },
    selectTask: async (selId) => {
      let allTask = [];
      tasks?.data?.forEach((task, tindex) => {
        allTask?.push(task?.id);
      });

      navigate(
        `/admin/worksite/inspection/${id}/${area}/${selId}/${
          tasks?.data?.[allTask?.indexOf(selId)]?.type
        }`
      );
    },
    search: async (e) => {
      const params = {
        search: e?.target?.value,
        type: type,
        productivity_orders_id: type == "po" && taskid,
        hourly_rate_tasks_estimations_id: type == "hourly" && taskid,
      };

      let data = await handleInspection?.get(params);
    },
    get: async (taskData) => {
      setAnimatePulse(true);

      const params = {
        cursor: taskData?.cursor,
        skip: taskData?.skip,
        search: taskData?.search,
        type: type,
        productivity_orders_id: type == "po" && taskid,
        hourly_rate_tasks_estimations_id: type == "hourly" && taskid,
      };

      let data = await GetInspection(params);
      console.log(data);

      if (data?.status) {
        let option = [];
        let selectedTask = "";
        if (type == "hourly") {
          option?.push({
            value: data?.extra?.taskData?.[0]?.hourly_rate_task_id,
            label:
              data?.extra?.taskData?.[0]?.hourly_rate_task?.[0]?.abbreviation +
              " " +
              data?.extra?.taskData?.[0]?.hourly_rate_task?.[0]?.name,
          });
          selectedTask = data?.extra?.taskData?.[0]?.hourly_rate_task_id;
        } else if (!data?.extra?.taskData?.[0]?.hourly_rate_task?.[0]?.name) {
          console.log("hereee");
          option?.push({
            value: data?.extra?.taskData?._id,
            label:
              data?.extra?.taskData?.code + " " + data?.extra?.taskData?.name,
          });
          selectedTask = data?.extra?.taskData?._id;
        }

        console.log(option);

        setInspection({
          ...inspection,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          search: taskData?.search,
          selectedData: {
            ...initialInspection?.selectedData,
          },
          isShimmer: false,
        });

        setTasks({
          ...tasks,
          options: option,
          selectedTask: selectedTask,
        });

        setAnimatePulse(false);
        return data;
      }
    },
    select: (id) => {},
    selectAll: () => {},
    add: (task, index) => {
      setVisibleEdit(true);
      setInspection({
        ...inspection,
        selectedData: {
          ...inspection?.selectedData,
          id: task?.id,
          isSave: task?.id ? 0 : 1,
          inspection: task?.inspection,
          description: task?.description,
        },
      });
    },
    getForm: (e) => {
      setInspection({
        ...inspection,
        selectedData: {
          ...inspection?.selectedData,
          [e?.target?.name]: e?.target?.value,
        },
      });
    },
    submit: async () => {
      if (!inspection?.selectedData?.inspection) {
        setInspection({
          ...inspection,
          selectedData: {
            ...inspection?.selectedData,
            errors: {
              ...initialInspection?.selectedData?.errors,
              inspection: "Enter inspection",
            },
          },
        });
        formref?.current["inspection"]?.current?.focus();
      } else if (!inspection?.selectedData?.description) {
        setInspection({
          ...inspection,
          selectedData: {
            ...inspection?.selectedData,
            errors: {
              ...initialInspection?.selectedData?.errors,
              description: "Enter description",
            },
          },
        });
        formref?.current["description"]?.current?.focus();
      } else {
        setInspection({
          ...inspection,
          selectedData: {
            ...inspection?.selectedData,
            isLoading: true,
            errors: {
              ...initialInspection?.selectedData?.errors,
            },
          },
        });

        const params = {
          id: inspection?.selectedData?.id,
          worksite_id: id,
          area_id: area,
          inspection: inspection?.selectedData?.inspection,
          description: inspection?.selectedData?.description,
          productivity_orders_id: type == "po" && taskid,
          hourly_rate_tasks_estimations_id: type == "hourly" && taskid,
          isSave: inspection?.selectedData?.isSave,
        };

        let data = await CreateEditInspection(params);
        if (data?.status) {
          let getData = await handleInspection?.get();
          if (getData?.status) {
            toast?.success(data?.message);
            setVisibleEdit(false);
          }
        } else {
          setInspection({
            ...inspection,
            selectedData: {
              ...initialInspection?.selectedData,
            },
          });
          toast?.error(data?.message);
        }
      }
    },
    check: async (e, id) => {
      setAnimatePulse(true);
      let allData = inspection?.data?.length > 0 ? [...inspection?.data] : [];

      let allIds = [];
      inspection?.data?.forEach((inspect, index) => {
        allIds?.push(inspect?.id);
      });

      if (e?.target?.checked) {
        console.log(
          inspection.data[allIds?.indexOf(id)],
          inspection.data[allIds?.indexOf(id)]
        );

        allData[allIds?.indexOf(id)].status = 1;
        setInspection({
          ...inspection,
          data: allData,
        });

        const params = {
          ids: [id],
          status: 1,
        };
        let data = await InspectionStatusChange(params);
        if (data?.status) {
          setAnimatePulse(false);
        }
      } else {
        console.log(
          inspection.data[allIds?.indexOf(id)],
          "inspection.data[allIds?.indexOf(id)]"
        );

        allData[allIds?.indexOf(id)].status = 0;
        setInspection({
          ...inspection,
          data: allData,
        });

        console.log(inspection.data[allIds?.indexOf(id)]);

        const params = {
          ids: [id],
          status: 0,
        };
        let data = await InspectionStatusChange(params);
        if (data?.status) {
          setAnimatePulse(false);
        }
      }
    },
    checkAll: async (e) => {
      setAnimatePulse(true);
      let allData = inspection?.data?.length > 0 ? [...inspection?.data] : [];

      let allIds = [];
      inspection?.data?.forEach((inspect, index) => {
        allIds?.push(inspect?.id);
      });

      if (e?.target?.checked) {
        let allChecks = [];
        inspection?.data?.forEach((inspect, index) => {
          allChecks?.push({ ...inspect, status: 1 });
        });

        setInspection({
          ...inspection,
          data: allChecks,
          allCheck: true,
        });

        const params = {
          ids: allIds,
          status: 1,
        };
        let data = await InspectionStatusChange(params);
        if (data?.status) {
          setAnimatePulse(false);
        }
      } else {
        let allChecks = [];
        inspection?.data?.forEach((inspect, index) => {
          allChecks?.push({ ...inspect, status: 0 });
        });

        setInspection({
          ...inspection,
          data: allChecks,
          allCheck: false,
        });

        const params = {
          ids: allIds,
          status: 0,
        };
        let data = await InspectionStatusChange(params);
        if (data?.status) {
          setAnimatePulse(false);
        }
      }
    },
    delete: async () => {
      setInspection({
        ...inspection,
        selectedData: {
          ...inspection?.selectedData,
          isLoading: true,
          errors: {
            ...initialInspection?.selectedData?.errors,
          },
        },
      });

      const params = {
        id: inspection?.selectedData?.id,
      };

      let data = await InspectionDelete(params);
      if (data?.status) {
        let getData = await handleInspection?.get();
        if (getData?.status) {
          toast?.success(data?.message);
          setVisibleDelete(false);
        }
      } else {
        setInspection({
          ...inspection,
          selectedData: {
            ...initialInspection?.selectedData,
          },
        });
        toast?.error(data?.message);
      }
    },
  };

  useEffect(() => {
    (async () => {
      let data = await handleInspection?.get();
    })();
  }, [taskid]);

  const handleInfoClick = (note) => {
    setSelectedNote(note);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setSelectedNote(null);
    setIsModalVisible(false);
  };

  const handleImageClick = (item) => {
    setSelectedImages(item);
    setIsImageModalVisible(true);
  };

  return (
    <>
      {visibleEdit && (
        <EditInspection
          setVisibleEdit={setVisibleEdit}
          inspection={inspection}
          setInspection={setInspection}
          handle={handleInspection}
          formref={formref}
        />
      )}
      {visibleDelete && (
        <DeleteInspection
          setVisibleDelete={setVisibleDelete}
          inspection={inspection}
          setInspection={setInspection}
          handle={handleInspection}
          formref={formref}
        />
      )}
      <div className="p-6">
        <div className="bg-white">
          {inspection?.isShimmer ? (
            <TextShimmer data={{ gap: 15, line: 20 }} />
          ) : (
            <Fragment>
              {inspection?.data?.length <= 0 && !inspection?.data?.search ? (
                <div className="py-8 h-[70vh]">
                  <div className="h-full flex justify-center items-center flex-col bg-white">
                    <div className="py-2">
                      <AreasDefaultSvg />
                    </div>
                    <div className="">
                      No data available for displaying Inspection
                    </div>
                    <div className="text-gray-300 text-base py-2">
                      The inspection needs to have areas added.
                    </div>
                    <div className="pt-2">
                      <Button
                        className="btn-blue"
                        onClick={handleInspection?.add}
                      >
                        Create Inspection
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={animatePulse ? "animate-pulse" : ""}>
                  <div className="p-4 ">
                    <div className="pb-4 cursor-pointer">
                      <div className="flex" onClick={handleInspection?.back}>
                        <div className="pt-1 pr-2">
                          <FaArrowLeft />
                        </div>
                        <div>Back to tasks</div>
                      </div>
                    </div>
                    <div className="flex pb-4">
                      <div className="pt-2">
                        {type == "hourly"
                          ? inspection?.extra?.taskData?.[0]
                              ?.hourly_rate_task?.[0]?.name
                          : type == "po"
                          ? inspection?.extra?.taskData?.name
                          : ""}
                      </div>
                      <div className="pt-3 px-2">
                        <FaArrowRight />
                      </div>
                      <div>
                        <Select
                          showSearch
                          placeholder="Select Tasks"
                          optionFilterProp="children"
                          className="text-sm"
                          style={{ width: 300 }}
                          bordered={false}
                          name="task"
                          size="large"
                          value={tasks?.selectedTask}
                          filterOption={false}
                          onFocus={handleInspection?.getTasks}
                          options={tasks?.options}
                          onChange={handleInspection?.selectTask}
                          onSearch={handleInspection?.taskSearch}
                        />
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/2">
                        <FaSearch className="ml-2" />{" "}
                        <input
                          placeholder="Search inspection"
                          className="h-[31px] bg-transparent text-sm w-full px-2"
                          name="search"
                          autoComplete="off"
                          onChange={handleInspection?.search}
                        />
                      </div>
                      <div>
                        <div>
                          <Button
                            onClick={handleInspection?.add}
                            className="flex justify-center items-center py-3 border border-[#111111] h-[42px]"
                          >
                            Add New Inspection
                            <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                              <FaPlus />
                            </div>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-end text-gray-400 text-sm p-2">
                      <div className="">
                        <span className="text-black">0</span> Out of &nbsp;
                        <span className="text-black">0</span> Inspection
                        Completed
                      </div>
                    </div>
                  </div>
                  {inspection?.data?.length <= 0 && inspection?.data?.search ? (
                    <NoTableOption data={noSearchOption} />
                  ) : (
                    <Fragment>
                      <div>
                        <div id="scrollableDiv" className="h-[400]">
                          <InfiniteScroll
                            className=""
                            dataLength={inspection?.data?.length}
                            next={handleInspection?.fetch}
                            hasMore={
                              inspection?.data?.length !=
                              inspection?.extra?.totalItems
                            }
                            loader={
                              <div>
                                <ButtonSpinner />
                              </div>
                            }
                            height={400}
                            endMessage={<div></div>}
                            scrollableTarget="scrollableDiv"
                          >
                            <table className="w-full">
                              <thead className="bg-[#E8F3FF] text-center">
                                <tr className="text-left">
                                  <th className=" px-4 py-3 ">
                                    <div className="flex items-center justify-start">
                                      Inspection
                                    </div>
                                  </th>
                                  <th className=" px-4 py-3 ">
                                    <div className="flex items-center justify-center">
                                      Status
                                    </div>
                                  </th>
                                  <th className=" px-4 py-3 ">
                                    <div className="flex items-center justify-center">
                                      Image
                                    </div>
                                  </th>
                                  <th className=" px-4 py-3 ">
                                    <div className="flex items-center justify-center">
                                      Select All{" "}
                                      <input
                                        type="checkbox"
                                        className="ml-2"
                                        checked={inspection?.allCheck}
                                        onChange={handleInspection?.checkAll}
                                        disabled={animatePulse}
                                      />
                                    </div>
                                  </th>
                                  <th className=" px-4 py-3 ">
                                    <div className="flex items-center justify-center">
                                      Actions
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-white">
                                {inspection?.data?.map((item, index) => (
                                  <tr
                                    key={index}
                                    className="border-b border-[#F0F0F0]"
                                  >
                                    <td className="px-4 py-3">
                                      <div className="flex ">
                                        <div>{item?.inspection}</div>
                                        <div>
                                          <Tooltip
                                            placement="rightBottom"
                                            title={item?.description}
                                          >
                                            <FaInfoCircle className="ml-2 text-md" />
                                          </Tooltip>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-4 py-3">
                                      <div className="flex items-center justify-center">
                                        {item?.status == 1
                                          ? "Completed"
                                          : "Incompleted"}
                                      </div>
                                    </td>
                                    <td className="px-4 py-3">
                                      <div key={index}>
                                        {item.file.length ? (
                                          <div
                                            onClick={() =>
                                              handleImageClick(item.file)
                                            }
                                            style={{
                                              textDecoration: "underline",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <CameraActive className="ml-40" />
                                          </div>
                                        ) : (
                                          <div>
                                            <CameraInactive className="ml-40" />
                                          </div>
                                        )}
                                      </div>
                                    </td>

                                    <td className="px-4 py-3">
                                      <div className="flex items-center justify-center">
                                        <input
                                          type="checkbox"
                                          checked={Boolean(item?.status)}
                                          onChange={(e) =>
                                            handleInspection?.check(e, item?.id)
                                          }
                                          disabled={animatePulse}
                                        />
                                      </div>
                                    </td>
                                    <td className="px-4 py-3">
                                      <div className="flex items-center justify-center cursor-pointer">
                                        {item.note &&
                                          item.note.trim() !== "" && (
                                            <button
                                              onClick={() =>
                                                handleInfoClick(item.note)
                                              }
                                              className="bg-transparent border-none outline-none"
                                            >
                                              {item.note && (
                                                <Written
                                                  style={{
                                                    position: "relative",
                                                    top: "2px",
                                                    marginRight: "8px",
                                                  }}
                                                />
                                              )}
                                            </button>
                                          )}
                                        {(!item.note ||
                                          item.note.trim() === "") && (
                                          <WrittenBlack
                                            style={{
                                              position: "relative",
                                              top: "2px",
                                              marginRight: "8px",
                                            }}
                                          />
                                        )}
                                        <button
                                          className="mx-2"
                                          onClick={() =>
                                            handleInspection?.getSelectedData(
                                              item?.id,
                                              "EDIT"
                                            )
                                          }
                                        >
                                          <FaEdit />
                                        </button>
                                        <button
                                          className="mx-2"
                                          onClick={() =>
                                            handleInspection?.getSelectedData(
                                              item?.id,
                                              "DELETE"
                                            )
                                          }
                                        >
                                          <FaTrashAlt />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                                <Modal
                                  title="Note Information"
                                  visible={isModalVisible}
                                  onCancel={handleModalClose}
                                  footer={null}
                                  centered
                                >
                                  {selectedNote}
                                </Modal>
                                <Modal
                                  title="Image Information"
                                  visible={isImageModalVisible}
                                  onCancel={() => setIsImageModalVisible(false)}
                                  footer={null}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {selectedImages.map((image, index) => (
                                    <div key={index}>
                                      <img
                                        src={Fileurl + image}
                                        alt={`Image ${index}`}
                                        style={{
                                          border: "4px solid #000",
                                          marginBottom: "10px",
                                        }}
                                      />
                                    </div>
                                  ))}
                                </Modal>
                              </tbody>
                            </table>
                          </InfiniteScroll>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </>
  );
};

export default InspectionTable;
